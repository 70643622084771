import auth from './de-DE/auth';
import contact from './de-DE/contact';
import global from './de-DE/global';
import component from './de-DE/component';
import project from './de-DE/project';
import campaign from './de-DE/campaign';
import agent from './de-DE/agent';
import client from './de-DE/client';
import user from './de-DE/user';
import survey from './de-DE/survey';
import list from './de-DE/list';
import customer from './de-DE/customer';
import mailTemplate from './de-DE/mail_template';
import ProjectRecorder from '@/pages/contact/project/projectRecorder';
import CampaignRecordVideo from '@/pages/contact/project/campaign/campaignRecord';
import { UploadModal } from '@/components/fileExplorer/upload';
import parameter from './de-DE/parameter';
export default {
  ...auth,
  ...contact,
  ...global,
  ...component,
  ...project,
  ...campaign,
  ...agent,
  ...client,
  ...user,
  ...survey,
  ...list,
  ...customer,
  ...mailTemplate,
  ...ProjectRecorder,
  ...CampaignRecordVideo,
  ...UploadModal,
  ...parameter,
};
