import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { FileViewer } from '@digital-a-team/platform-file-viewer';
import { useParams } from '@umijs/max';
import { VBCampaign, VBCampaignAction } from '@/services/vertriebsbutler/campaign';
import { File as DBFile, Tag_File } from '@/generated/graphql';
import FileHandler from '@/utils/s3';
import 'video-react/dist/video-react.css';
import './CountDown.css';

const CampaignRecordVideo = () => {
  const [files, setFiles] = useState<Array<File>>();
  const params = useParams<{ campaign_id: string }>();
  const [contact_id, setContact_id] = useState<string>();
  const [project_id, setProjectId] = useState<string>();
  const parts = useRef<Array<{ ETag: string; PartNumber: number }>>([]);
  const keyRef = useRef<string>('');
  const uploadIdRef = useRef<string>('');
  const [fh, setFh] = useState<FileHandler>();
  const [campaign_number, setCampaign_number] = useState<string>();

  const getData = async () => {
    if (!params.campaign_id) {
      console.error('No campaign id provided');
      return;
    }

    setFh(await FileHandler.init());
    const { data } = await VBCampaign.selectFiles(params.campaign_id);
    setContact_id(data.contact_id);
    setCampaign_number(data.number || '');

    if (data && data.files && data.files.length > 0) {
      setProjectId(data.project_id);
      const fh = await FileHandler.init();
      const filesArray = await Promise.all(
        data.files
          .filter((file: DBFile) => file.s3key && file.mime?.indexOf('video') === -1)
          .sort((a: DBFile) => (a.tags && a.tags.includes(Tag_File.OfferFile) ? 1 : -1))
          .map(async (file: DBFile) => {
            const f = await fh.download(file.name || 'Datei', file.s3key as string);
            return f ? f.data : null;
          }),
      );
      const validFiles = filesArray.filter((file) => file) as unknown as Array<File>;
      setFiles(validFiles);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!files || !files.length) return <Spin />;

  const onClose = () => {
    history.back();
  };

  return (
    <FileViewer
      files={files}
      defaultFileName={'Angebotsvideo'}
      showCampaignStart={true}
      onStartMultipartUpload={async () => {
        const folder = `contact/${contact_id}/project/${project_id}/campaign/${params.campaign_id}`;
        const filename = `Angebotsvideo.webm`;
        const s3key = `${folder}/${filename}`;
        const res = await fh?.createMultipartUpload(s3key);
        if (res) {
          keyRef.current = s3key;
          uploadIdRef.current = res || '';
        }
      }}
      onUploadPart={async (part: number, body: Blob) => {
        const res = await fh?.uploadPart(keyRef.current, part, uploadIdRef.current, body);
        if (!res) throw new Error('Upload failed');
        parts.current.push(res);
      }}
      onCompleteMultipartUpload={async (
        filename: string,
        campaignStart: boolean,
        size: number,
        duration: number,
        thumbnail: Blob,
      ) => {
        if (!contact_id || !project_id) return false;

        const number = campaign_number ? campaign_number.replaceAll(/[|&;$%@"<>+,]/g, '_') : '';
        const folder = campaign_number ? `Angebot(${number})` : 'Angebot';
        const newFilename = filename.replace('.webm', '_thumb.jpg');
        const thumbnailKey = `contact/${contact_id}/project/${project_id}/${folder}/${newFilename}`;
        await fh?.upload({
          Key: thumbnailKey,
          Body: new File([thumbnail], newFilename),
          ContentType: 'image/jpeg',
          ACL: 'public-read',
        });

        await fh?.completeMultipartUpload({
          key: keyRef.current,
          uploadId: uploadIdRef.current,
          parts: parts.current.sort((a, b) => a.PartNumber - b.PartNumber),
          campaign_id: params.campaign_id,
          contact_id,
          duration,
          filename,
          folder: folder,
          project_id,
          size,
          tags: [Tag_File.OfferVideo],
          thumbnailKey,
        });

        const data = {
          campaign_id: params.campaign_id || '',
          main_state: 'video',
          sub_state: 'recorded',
          message: '',
          ext_file_id: '',
          ext_thumbnail_id: '',
          start_now: campaignStart,
          file_name: `${filename}.webm`,
          s3Key: keyRef.current,
          url: '',
        };

        const res = await VBCampaignAction.insertVideoRecorded(data);
        if (res.data) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }}
      onAbortMultipartUpload={async () => {
        parts.current = [];
        await fh?.abortMultipartUpload(keyRef.current, uploadIdRef.current);
      }}
      onClose={onClose}
    />
  );
};

export default CampaignRecordVideo;
