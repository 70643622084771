import { VBBaseClass } from './generic';
import {
  ActionDocumentSignedInput,
  ActionVideoPlaybackCompletedInput,
  ActionVideoRecordedInput,
  AppointmentCampaignInput,
  Campaign,
  CampaignInput,
  Campaign_Action,
  Campaign_Type,
  CheckRatingResult,
  InsertActionResponse,
  OfferCampaignActionInput,
  OfferCampaignActionResponse,
  OfferCampaignInput,
  StartCampaignResponse,
} from '@/generated/graphql';
import { mutate, query } from '.';
import { gql } from '@apollo/client';
import { RatingInput } from '../../generated/graphql';

const DEFAULT_FIELDS = `
  campaign_action {
    created
    main_state
    message
    sub_state
    url
    agent {
      firstname
      lastname
    }
  }
  created
  campaign_name
  campaign_type
  additional_info
  number
  id
  statement
  contact {
    id
    firstname
    lastname
    company_name
  }
  project {
    id
    name
  }
`;

export class VBCampaign {
  private static baseClass = new VBBaseClass<Campaign>('Campaign', DEFAULT_FIELDS);

  static createOne(data: Campaign) {
    return this.baseClass.createOne(data);
  }

  static updateOne(data: Campaign) {
    return this.baseClass.updateOne(data);
  }

  static deleteOne(id: string) {
    return this.baseClass.deleteOne(id);
  }

  static selectOne(id: string) {
    return this.baseClass.selectOne(id);
  }

  static async selectMany(project_id: string) {
    const qry = gql`
      query Campaigns(
        $take: Int
        $orderBy: [Campaign_actionOrderByWithRelationInput!]
        $orderBy2: [CampaignOrderByWithRelationInput!]
        $where: CampaignWhereInput
      ) {
        campaigns(orderBy: $orderBy2, where: $where) {
          campaign_action(take: $take, orderBy: $orderBy) {
            created
            main_state
            sub_state
          }
          campaign_name
          campaign_type
          created
          number
          id
        }
      }
    `;
    const res = await query<{ campaigns: Array<Campaign> }>({
      query: qry,
      variables: {
        take: 1,
        orderBy: [
          {
            created: 'desc',
          },
        ],
        orderBy2: [
          {
            created: 'desc',
          },
        ],
        where: {
          project_id: {
            equals: project_id,
          },
          AND: [
            {
              deleted: {
                equals: null,
              },
            },
          ],
        },
      },
    });
    return { ...res, data: res.data?.campaigns };
  }

  private async startAppointmentCampaign(data: AppointmentCampaignInput) {
    const qry = gql`
      mutation StartAppointmentCampaign($campaignInput: AppointmentCampaignInput!) {
        campaign: startAppointmentCampaign(campaign_input: $campaignInput) {
          id
          campaign_type
          number
          created
          campaign_action
        }
      }
    `;
    try {
      const res = await mutate<{ campaign: StartCampaignResponse }>({
        mutation: qry,
        variables: { campaignInput: data },
      });
      return { ...res, data: res.data };
    } catch (error) {
      throw new Error(`Mutation failed: ${error}`);
    }
  }

  private async startOfferCampaign(data: OfferCampaignInput) {
    const qry = gql`
      mutation StartOfferCampaign($campaignInput: OfferCampaignInput!) {
        campaign: startOfferCampaign(campaign_input: $campaignInput) {
          id
          campaign_type
          number
          created
          campaign_action
        }
      }
    `;
    try {
      const res = await mutate<{ campaign: StartCampaignResponse }>({
        mutation: qry,
        variables: { campaignInput: data },
      });
      return { ...res, data: res.data };
    } catch (error) {
      throw new Error(`Mutation failed: ${error}`);
    }
  }
  private async startRatingCampaign(data: CampaignInput) {
    const qry = gql`
      mutation StartRatingCampaign($campaignInput: CampaignInput!) {
        campaign: startRatingCampaign(campaign_input: $campaignInput) {
          id
          campaign_type
          number
          created
          campaign_action
        }
      }
    `;
    try {
      const res = await mutate<{ campaign: StartCampaignResponse }>({
        mutation: qry,
        variables: { campaignInput: data },
      });
      return { ...res, data: res.data };
    } catch (error) {
      throw new Error(`Mutation failed: ${error}`);
    }
  }

  static async selectFiles(campaign_id: string) {
    const qry = gql`
      query Campaign($where: CampaignWhereUniqueInput!, $filesWhere2: FileWhereInput) {
        campaign(where: $where) {
          id
          contact_id
          project_id
          number
          files(where: $filesWhere2) {
            id
            mime
            name
            main_file_id
            s3key
            size
            url
            tags
          }
        }
      }
    `;

    const res = await query<{ campaign: Campaign }>({
      query: qry,
      variables: {
        where: {
          id: campaign_id,
        },
        filesWhere2: {
          deleted: {
            equals: null,
          },
        },
      },
    });
    return { ...res, data: res.data?.campaign };
  }

  static async endCampaign(campaignId: string, message: string) {
    const mutation = gql`
      mutation EndCampaign($campaignId: String!, $message: String!) {
        endCampaign(campaign_id: $campaignId, message: $message) {
          id
          campaign_id
          main_state
          message
          sub_state
          url
          created
        }
      }
    `;

    try {
      const res = await mutate<{ endCampaign: InsertActionResponse }>({
        mutation: mutation,
        variables: {
          campaignId,
          message,
        },
      });
      return { ...res, data: res.data?.endCampaign };
    } catch (error) {
      console.error(`Ending campaign failed: ${error}`);
      throw new Error(`Ending campaign failed: ${error}`);
    }
  }

  static async offerCampaignAction(data: OfferCampaignActionInput) {
    const qry = gql`
      mutation OfferCampaignAction($input: OfferCampaignActionInput!) {
        res: offerCampaignAction(input: $input) {
          id
          state
          execution_date
          notifications {
            id
            execution_date
          }
        }
      }
    `;
    try {
      const res = await mutate<{ res: Array<OfferCampaignActionResponse> }>({
        mutation: qry,
        variables: { input: data },
      });
      return { ...res, data: res.data?.res };
    } catch (error) {
      throw new Error(`Mutation failed: ${error}`);
    }
  }

  static async startCampaign(data: CampaignInput | OfferCampaignInput | AppointmentCampaignInput) {
    switch (data.campaign_type) {
      case Campaign_Type.Offer:
        return new VBCampaign().startOfferCampaign(data as OfferCampaignInput);
      case Campaign_Type.Appointment:
        return new VBCampaign().startAppointmentCampaign(data as AppointmentCampaignInput);
      case Campaign_Type.Rating:
        return new VBCampaign().startRatingCampaign(data);
      default:
        throw new Error(`Campaign type ${data.campaign_type} not found`);
    }
  }

  static async checkRating(campaign_id: string) {
    const qry = gql`
      query CheckRating($campaignId: String!) {
        rating: checkRating(campaign_id: $campaignId) {
          alreadyRated
          formal
        }
      }
    `;

    const res = await query<{ rating: CheckRatingResult }>({
      query: qry,
      variables: {
        campaignId: campaign_id,
      },
    });
    return { ...res, data: res.data?.rating };
  }

  static async insertRating(input: RatingInput) {
    const query = gql`
      mutation InsertRating($input: RatingInput!) {
        rating: insertRating(input: $input) {
          id
        }
      }
    `;

    const res = await mutate<{ rating: InsertActionResponse }>({
      mutation: query,
      variables: {
        input,
      },
    });

    return { ...res, data: res.data?.rating };
  }

  static async insertGoogleRating(input: RatingInput) {
    const query = gql`
      mutation InsertGoogleRating($input: RatingInput!) {
        rating: insertGoogleRating(input: $input) {
          id
        }
      }
    `;

    const res = await mutate<{ rating: InsertActionResponse }>({
      mutation: query,
      variables: {
        input,
      },
    });

    return { ...res, data: res.data?.rating };
  }

  static async insertFeedback(input: RatingInput) {
    const query = gql`
      mutation InsertFeedback($input: RatingInput!) {
        rating: insertFeedback(input: $input) {
          id
        }
      }
    `;

    const res = await mutate<{ rating: InsertActionResponse }>({
      mutation: query,
      variables: {
        input,
      },
    });

    return { ...res, data: res.data?.rating };
  }

  static async getAvailableCampaigns() {
    const qry = gql`
      query AvailableCampaigns {
        availableCampaigns {
          offer
          appointment
          rating
        }
      }
    `;
    const res = await query<{
      availableCampaigns: { offer: boolean; appointment: boolean; rating: boolean };
    }>({
      query: qry,
    });
    return { ...res, data: res.data?.availableCampaigns };
  }
}

const DEFAULT_ACTION_FIELDS = `
  created
  campaign_id
  main_state
  sub_state
  url
  message
  id
`;

export class VBCampaignAction {
  private static baseClass = new VBBaseClass<Campaign_Action>(
    'Campaign_action',
    DEFAULT_ACTION_FIELDS,
  );

  static createOne(data: Campaign_Action) {
    return this.baseClass.createOne(data);
  }

  static updateOne(data: Campaign_Action) {
    return this.baseClass.updateOne(data);
  }

  static deleteOne(id: string) {
    return this.baseClass.deleteOne(id);
  }

  static selectOne(id: string) {
    return this.baseClass.selectOne(id);
  }

  static selectMany(campaign_id: string) {
    return this.baseClass.selectMany([{ field: 'created', type: 'sortOrder' }], {
      campaign_id,
    });
  }

  static async insertVideoRecorded(input: ActionVideoRecordedInput) {
    const query = gql`
      mutation InsertActionVideoRecorded($input: ActionVideoRecordedInput!) {
        action: insertActionVideoRecorded(input: $input) {
          id
        }
      }
    `;

    const res = await mutate<{ action: InsertActionResponse }>({
      mutation: query,
      variables: {
        input,
      },
    });
    return { ...res, data: res.data?.action };
  }

  static async insertDocumentSigned(input: ActionDocumentSignedInput) {
    const query = gql`
      mutation InsertActionDocumentSigned($input: ActionDocumentSignedInput!) {
        insertActionDocumentSigned(input: $input) {
          id
        }
      }
    `;

    const res = await mutate<{ action: InsertActionResponse }>({
      mutation: query,
      variables: {
        input,
      },
    });
    return { ...res, data: res.data?.action };
  }

  static async insertVideoPlaybackCompleted(input: ActionVideoPlaybackCompletedInput) {
    const query = gql`
      mutation InsertActionVideoPlaybackCompleted($input: ActionVideoPlaybackCompletedInput!) {
        action: insertActionVideoPlaybackCompleted(input: $input) {
          id
        }
      }
    `;

    const res = await mutate<{ action: InsertActionResponse }>({
      mutation: query,
      variables: {
        input,
      },
    });
    return { ...res, data: res.data?.action };
  }
}
