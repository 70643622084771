import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AbortMultipartUploadInput = {
  key: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
};

export type ActionDocumentSignedInput = {
  campaign_id: Scalars['String']['input'];
  document: Scalars['JSONObject']['input'];
  file_id: Scalars['String']['input'];
};

export type ActionVideoPlaybackCompletedInput = {
  campaign_id: Scalars['String']['input'];
  main_state: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Scalars['String']['input'];
};

export type ActionVideoRecordedInput = {
  campaign_id: Scalars['String']['input'];
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  ext_thumbnail_id?: InputMaybe<Scalars['String']['input']>;
  file_name: Scalars['String']['input'];
  main_state: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  s3Key: Scalars['String']['input'];
  start_now?: InputMaybe<Scalars['Boolean']['input']>;
  sub_state: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AdminCreateAgentInput = {
  additional_info?: InputMaybe<Scalars['JSONObject']['input']>;
  dest_client_id: Scalars['String']['input'];
  dest_user_pool_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: User_Role;
  salutation: Salutation;
  sendPassword?: Scalars['Boolean']['input'];
};

export type AdminCreateClientInput = {
  dkim: Scalars['String']['input'];
  embed_domains: Array<Scalars['String']['input']>;
  instance: Scalars['String']['input'];
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  styles: Scalars['String']['input'];
};

export type AdminCreateClientResponse = {
  __typename?: 'AdminCreateClientResponse';
  client_id: Scalars['String']['output'];
  dkim: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  user_pool_id: Scalars['String']['output'];
};

export type AdminDkimStatus = {
  __typename?: 'AdminDKIMStatus';
  identityType: Scalars['String']['output'];
  token?: Maybe<Array<Scalars['JSONObject']['output']>>;
  verificationStatus?: Maybe<Scalars['String']['output']>;
};

export type AdminDeleteAgentInput = {
  dest_user_pool_id: Scalars['String']['input'];
  new_user_id: Scalars['String']['input'];
  old_user_id: Scalars['String']['input'];
};

export type AdminDeleteAgentResponse = {
  __typename?: 'AdminDeleteAgentResponse';
  success: Scalars['Boolean']['output'];
};

export type AdminResendPasswordInput = {
  user_id: Scalars['String']['input'];
  user_pool_id: Scalars['String']['input'];
};

export type AdminResendPasswordResponse = {
  __typename?: 'AdminResendPasswordResponse';
  success: Scalars['Boolean']['output'];
};

export type AdminUpdateAgentInput = {
  additional_info?: InputMaybe<Scalars['JSONObject']['input']>;
  agent_id: Scalars['String']['input'];
  dest_client_id: Scalars['String']['input'];
  dest_user_pool_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: User_Role;
  salutation: Salutation;
};

export type AdminUpdateClientInput = {
  dest_client_id: Scalars['String']['input'];
  dest_user_pool_id: Scalars['String']['input'];
  dkim: Scalars['String']['input'];
  embed_domains: Array<Scalars['String']['input']>;
  instance: Scalars['String']['input'];
  logo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  styles: Scalars['String']['input'];
};

export type AdminUpdateClientResponse = {
  __typename?: 'AdminUpdateClientResponse';
  client_id: Scalars['String']['output'];
  dkim: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  embed_domains: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  user_pool_id: Scalars['String']['output'];
};

export type AdminUserResponse = {
  __typename?: 'AdminUserResponse';
  client_id: Scalars['String']['output'];
  cognito_user_id: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  role: User_Role;
  salutation: Scalars['String']['output'];
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type Agent = {
  __typename?: 'Agent';
  _count?: Maybe<AgentCount>;
  /** [AgentAdditionalInfo] */
  additional_info?: Maybe<Scalars['JSON']['output']>;
  campaign: Array<Campaign>;
  campaign_action: Array<Campaign_Action>;
  client: Client;
  client_id: Scalars['String']['output'];
  cognito_user?: Maybe<Scalars['Boolean']['output']>;
  cognito_user_id?: Maybe<Scalars['String']['output']>;
  contact: Array<Contact>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  parameter: Array<Parameter>;
  phone?: Maybe<Scalars['String']['output']>;
  role: User_Role;
  salutation: Salutation;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};


export type AgentCampaignArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type AgentCampaign_ActionArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type AgentContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type AgentParameterArgs = {
  cursor?: InputMaybe<ParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};

export type AgentCount = {
  __typename?: 'AgentCount';
  campaign: Scalars['Int']['output'];
  campaign_action: Scalars['Int']['output'];
  contact: Scalars['Int']['output'];
  parameter: Scalars['Int']['output'];
};


export type AgentCountCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type AgentCountCampaign_ActionArgs = {
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type AgentCountContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


export type AgentCountParameterArgs = {
  where?: InputMaybe<ParameterWhereInput>;
};

export type AgentCountAggregate = {
  __typename?: 'AgentCountAggregate';
  _all: Scalars['Int']['output'];
  additional_info: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  cognito_user: Scalars['Int']['output'];
  cognito_user_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstname: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  lastname: Scalars['Int']['output'];
  phone: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  salutation: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type AgentCountOrderByAggregateInput = {
  additional_info?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  cognito_user?: InputMaybe<SortOrder>;
  cognito_user_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type AgentCreateInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutAgentInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutAgentInput>;
  client: ClientCreateNestedOneWithoutAgentInput;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutAgentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutAgentInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateManyClientInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateManyClientInputEnvelope = {
  data: Array<AgentCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AgentCreateManyInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  client_id: Scalars['String']['input'];
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<AgentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AgentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<AgentCreateWithoutClientInput>>;
  createMany?: InputMaybe<AgentCreateManyClientInputEnvelope>;
};

export type AgentCreateNestedOneWithoutCampaignInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<AgentCreateWithoutCampaignInput>;
};

export type AgentCreateNestedOneWithoutCampaign_ActionInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<AgentCreateWithoutCampaign_ActionInput>;
};

export type AgentCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<AgentCreateWithoutContactInput>;
};

export type AgentCreateNestedOneWithoutParameterInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutParameterInput>;
  create?: InputMaybe<AgentCreateWithoutParameterInput>;
};

export type AgentCreateOrConnectWithoutCampaignInput = {
  create: AgentCreateWithoutCampaignInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutCampaign_ActionInput = {
  create: AgentCreateWithoutCampaign_ActionInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutClientInput = {
  create: AgentCreateWithoutClientInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutContactInput = {
  create: AgentCreateWithoutContactInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateOrConnectWithoutParameterInput = {
  create: AgentCreateWithoutParameterInput;
  where: AgentWhereUniqueInput;
};

export type AgentCreateWithoutCampaignInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutAgentInput>;
  client: ClientCreateNestedOneWithoutAgentInput;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutAgentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutAgentInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateWithoutCampaign_ActionInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutAgentInput>;
  client: ClientCreateNestedOneWithoutAgentInput;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutAgentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutAgentInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateWithoutClientInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutAgentInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutAgentInput>;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutAgentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutAgentInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateWithoutContactInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutAgentInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutAgentInput>;
  client: ClientCreateNestedOneWithoutAgentInput;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutAgentInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentCreateWithoutParameterInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutAgentInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutAgentInput>;
  client: ClientCreateNestedOneWithoutAgentInput;
  cognito_user?: InputMaybe<Scalars['Boolean']['input']>;
  cognito_user_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutAgentInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<User_Role>;
  salutation: Salutation;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type AgentGroupBy = {
  __typename?: 'AgentGroupBy';
  _count?: Maybe<AgentCountAggregate>;
  _max?: Maybe<AgentMaxAggregate>;
  _min?: Maybe<AgentMinAggregate>;
  additional_info?: Maybe<Scalars['JSON']['output']>;
  client_id: Scalars['String']['output'];
  cognito_user?: Maybe<Scalars['Boolean']['output']>;
  cognito_user_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  role: User_Role;
  salutation: Salutation;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AgentListRelationFilter = {
  every?: InputMaybe<AgentWhereInput>;
  none?: InputMaybe<AgentWhereInput>;
  some?: InputMaybe<AgentWhereInput>;
};

export type AgentMaxAggregate = {
  __typename?: 'AgentMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  cognito_user?: Maybe<Scalars['Boolean']['output']>;
  cognito_user_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<User_Role>;
  salutation?: Maybe<Salutation>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AgentMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  cognito_user?: InputMaybe<SortOrder>;
  cognito_user_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type AgentMinAggregate = {
  __typename?: 'AgentMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  cognito_user?: Maybe<Scalars['Boolean']['output']>;
  cognito_user_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<User_Role>;
  salutation?: Maybe<Salutation>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type AgentMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  cognito_user?: InputMaybe<SortOrder>;
  cognito_user_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type AgentNullableRelationFilter = {
  is?: InputMaybe<AgentWhereInput>;
  isNot?: InputMaybe<AgentWhereInput>;
};

export type AgentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AgentOrderByWithAggregationInput = {
  _count?: InputMaybe<AgentCountOrderByAggregateInput>;
  _max?: InputMaybe<AgentMaxOrderByAggregateInput>;
  _min?: InputMaybe<AgentMinOrderByAggregateInput>;
  additional_info?: InputMaybe<SortOrderInput>;
  client_id?: InputMaybe<SortOrder>;
  cognito_user?: InputMaybe<SortOrderInput>;
  cognito_user_id?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrderInput>;
};

export type AgentOrderByWithRelationInput = {
  additional_info?: InputMaybe<SortOrderInput>;
  campaign?: InputMaybe<CampaignOrderByRelationAggregateInput>;
  campaign_action?: InputMaybe<Campaign_ActionOrderByRelationAggregateInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  cognito_user?: InputMaybe<SortOrderInput>;
  cognito_user_id?: InputMaybe<SortOrderInput>;
  contact?: InputMaybe<ContactOrderByRelationAggregateInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  parameter?: InputMaybe<ParameterOrderByRelationAggregateInput>;
  phone?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrderInput>;
};

export enum AgentScalarFieldEnum {
  AdditionalInfo = 'additional_info',
  ClientId = 'client_id',
  CognitoUser = 'cognito_user',
  CognitoUserId = 'cognito_user_id',
  Created = 'created',
  Deleted = 'deleted',
  Email = 'email',
  Firstname = 'firstname',
  Id = 'id',
  Lastname = 'lastname',
  Phone = 'phone',
  Role = 'role',
  Salutation = 'salutation',
  Updated = 'updated'
}

export type AgentScalarWhereInput = {
  AND?: InputMaybe<Array<AgentScalarWhereInput>>;
  NOT?: InputMaybe<Array<AgentScalarWhereInput>>;
  OR?: InputMaybe<Array<AgentScalarWhereInput>>;
  additional_info?: InputMaybe<JsonNullableFilter>;
  client_id?: InputMaybe<StringFilter>;
  cognito_user?: InputMaybe<BoolNullableFilter>;
  cognito_user_id?: InputMaybe<UuidNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  lastname?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<Enumuser_RoleFilter>;
  salutation?: InputMaybe<EnumsalutationFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type AgentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AgentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AgentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AgentScalarWhereWithAggregatesInput>>;
  additional_info?: InputMaybe<JsonNullableWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  cognito_user?: InputMaybe<BoolNullableWithAggregatesFilter>;
  cognito_user_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  firstname?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  lastname?: InputMaybe<StringWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  role?: InputMaybe<Enumuser_RoleWithAggregatesFilter>;
  salutation?: InputMaybe<EnumsalutationWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type AgentUpdateInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutAgentNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutAgentNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutAgentNestedInput>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutAgentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutAgentNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpdateManyMutationInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpdateManyWithWhereWithoutClientInput = {
  data: AgentUpdateManyMutationInput;
  where: AgentScalarWhereInput;
};

export type AgentUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<AgentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AgentCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<AgentCreateWithoutClientInput>>;
  createMany?: InputMaybe<AgentCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<AgentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AgentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AgentWhereUniqueInput>>;
  set?: InputMaybe<Array<AgentWhereUniqueInput>>;
  update?: InputMaybe<Array<AgentUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<AgentUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<AgentUpsertWithWhereUniqueWithoutClientInput>>;
};

export type AgentUpdateOneWithoutCampaignNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<AgentCreateWithoutCampaignInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutCampaignInput>;
  upsert?: InputMaybe<AgentUpsertWithoutCampaignInput>;
};

export type AgentUpdateOneWithoutCampaign_ActionNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<AgentCreateWithoutCampaign_ActionInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutCampaign_ActionInput>;
  upsert?: InputMaybe<AgentUpsertWithoutCampaign_ActionInput>;
};

export type AgentUpdateOneWithoutContactNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<AgentCreateWithoutContactInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutContactInput>;
  upsert?: InputMaybe<AgentUpsertWithoutContactInput>;
};

export type AgentUpdateOneWithoutParameterNestedInput = {
  connect?: InputMaybe<AgentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AgentCreateOrConnectWithoutParameterInput>;
  create?: InputMaybe<AgentCreateWithoutParameterInput>;
  delete?: InputMaybe<AgentWhereInput>;
  disconnect?: InputMaybe<AgentWhereInput>;
  update?: InputMaybe<AgentUpdateToOneWithWhereWithoutParameterInput>;
  upsert?: InputMaybe<AgentUpsertWithoutParameterInput>;
};

export type AgentUpdateToOneWithWhereWithoutCampaignInput = {
  data: AgentUpdateWithoutCampaignInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutCampaign_ActionInput = {
  data: AgentUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutContactInput = {
  data: AgentUpdateWithoutContactInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateToOneWithWhereWithoutParameterInput = {
  data: AgentUpdateWithoutParameterInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpdateWithWhereUniqueWithoutClientInput = {
  data: AgentUpdateWithoutClientInput;
  where: AgentWhereUniqueInput;
};

export type AgentUpdateWithoutCampaignInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutAgentNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutAgentNestedInput>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutAgentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutAgentNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpdateWithoutCampaign_ActionInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutAgentNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutAgentNestedInput>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutAgentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutAgentNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpdateWithoutClientInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutAgentNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutAgentNestedInput>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutAgentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutAgentNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpdateWithoutContactInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutAgentNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutAgentNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutAgentNestedInput>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutAgentNestedInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpdateWithoutParameterInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutAgentNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutAgentNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutAgentNestedInput>;
  cognito_user?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  cognito_user_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutAgentNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<Enumuser_RoleFieldUpdateOperationsInput>;
  salutation?: InputMaybe<EnumsalutationFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type AgentUpsertWithWhereUniqueWithoutClientInput = {
  create: AgentCreateWithoutClientInput;
  update: AgentUpdateWithoutClientInput;
  where: AgentWhereUniqueInput;
};

export type AgentUpsertWithoutCampaignInput = {
  create: AgentCreateWithoutCampaignInput;
  update: AgentUpdateWithoutCampaignInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutCampaign_ActionInput = {
  create: AgentCreateWithoutCampaign_ActionInput;
  update: AgentUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutContactInput = {
  create: AgentCreateWithoutContactInput;
  update: AgentUpdateWithoutContactInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentUpsertWithoutParameterInput = {
  create: AgentCreateWithoutParameterInput;
  update: AgentUpdateWithoutParameterInput;
  where?: InputMaybe<AgentWhereInput>;
};

export type AgentWhereInput = {
  AND?: InputMaybe<Array<AgentWhereInput>>;
  NOT?: InputMaybe<Array<AgentWhereInput>>;
  OR?: InputMaybe<Array<AgentWhereInput>>;
  additional_info?: InputMaybe<JsonNullableFilter>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  cognito_user?: InputMaybe<BoolNullableFilter>;
  cognito_user_id?: InputMaybe<UuidNullableFilter>;
  contact?: InputMaybe<ContactListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  lastname?: InputMaybe<StringFilter>;
  parameter?: InputMaybe<ParameterListRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<Enumuser_RoleFilter>;
  salutation?: InputMaybe<EnumsalutationFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type AgentWhereUniqueInput = {
  AND?: InputMaybe<Array<AgentWhereInput>>;
  NOT?: InputMaybe<Array<AgentWhereInput>>;
  OR?: InputMaybe<Array<AgentWhereInput>>;
  additional_info?: InputMaybe<JsonNullableFilter>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  cognito_user?: InputMaybe<BoolNullableFilter>;
  cognito_user_id?: InputMaybe<UuidNullableFilter>;
  contact?: InputMaybe<ContactListRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  email_client_id?: InputMaybe<AgentEmailClient_IdCompoundUniqueInput>;
  firstname?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<StringFilter>;
  parameter?: InputMaybe<ParameterListRelationFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<Enumuser_RoleFilter>;
  salutation?: InputMaybe<EnumsalutationFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type AggregateAgent = {
  __typename?: 'AggregateAgent';
  _count?: Maybe<AgentCountAggregate>;
  _max?: Maybe<AgentMaxAggregate>;
  _min?: Maybe<AgentMinAggregate>;
};

export type AggregateCampaign = {
  __typename?: 'AggregateCampaign';
  _avg?: Maybe<CampaignAvgAggregate>;
  _count?: Maybe<CampaignCountAggregate>;
  _max?: Maybe<CampaignMaxAggregate>;
  _min?: Maybe<CampaignMinAggregate>;
  _sum?: Maybe<CampaignSumAggregate>;
};

export type AggregateCampaign_Action = {
  __typename?: 'AggregateCampaign_action';
  _count?: Maybe<Campaign_ActionCountAggregate>;
  _max?: Maybe<Campaign_ActionMaxAggregate>;
  _min?: Maybe<Campaign_ActionMinAggregate>;
};

export type AggregateClient = {
  __typename?: 'AggregateClient';
  _count?: Maybe<ClientCountAggregate>;
  _max?: Maybe<ClientMaxAggregate>;
  _min?: Maybe<ClientMinAggregate>;
};

export type AggregateConfig_Campaign_Client = {
  __typename?: 'AggregateConfig_campaign_client';
  _count?: Maybe<Config_Campaign_ClientCountAggregate>;
  _max?: Maybe<Config_Campaign_ClientMaxAggregate>;
  _min?: Maybe<Config_Campaign_ClientMinAggregate>;
};

export type AggregateConfig_Global = {
  __typename?: 'AggregateConfig_global';
  _count?: Maybe<Config_GlobalCountAggregate>;
  _max?: Maybe<Config_GlobalMaxAggregate>;
  _min?: Maybe<Config_GlobalMinAggregate>;
};

export type AggregateContact = {
  __typename?: 'AggregateContact';
  _count?: Maybe<ContactCountAggregate>;
  _max?: Maybe<ContactMaxAggregate>;
  _min?: Maybe<ContactMinAggregate>;
};

export type AggregateCountry = {
  __typename?: 'AggregateCountry';
  _avg?: Maybe<CountryAvgAggregate>;
  _count?: Maybe<CountryCountAggregate>;
  _max?: Maybe<CountryMaxAggregate>;
  _min?: Maybe<CountryMinAggregate>;
  _sum?: Maybe<CountrySumAggregate>;
};

export type AggregateCron = {
  __typename?: 'AggregateCron';
  _avg?: Maybe<CronAvgAggregate>;
  _count?: Maybe<CronCountAggregate>;
  _max?: Maybe<CronMaxAggregate>;
  _min?: Maybe<CronMinAggregate>;
  _sum?: Maybe<CronSumAggregate>;
};

export type AggregateFile = {
  __typename?: 'AggregateFile';
  _avg?: Maybe<FileAvgAggregate>;
  _count?: Maybe<FileCountAggregate>;
  _max?: Maybe<FileMaxAggregate>;
  _min?: Maybe<FileMinAggregate>;
  _sum?: Maybe<FileSumAggregate>;
};

export type AggregateList = {
  __typename?: 'AggregateList';
  _count?: Maybe<ListCountAggregate>;
  _max?: Maybe<ListMaxAggregate>;
  _min?: Maybe<ListMinAggregate>;
};

export type AggregateMail_Template = {
  __typename?: 'AggregateMail_template';
  _count?: Maybe<Mail_TemplateCountAggregate>;
  _max?: Maybe<Mail_TemplateMaxAggregate>;
  _min?: Maybe<Mail_TemplateMinAggregate>;
};

export type AggregateMessage = {
  __typename?: 'AggregateMessage';
  _count?: Maybe<MessageCountAggregate>;
  _max?: Maybe<MessageMaxAggregate>;
  _min?: Maybe<MessageMinAggregate>;
};

export type AggregateParameter = {
  __typename?: 'AggregateParameter';
  _count?: Maybe<ParameterCountAggregate>;
  _max?: Maybe<ParameterMaxAggregate>;
  _min?: Maybe<ParameterMinAggregate>;
};

export type AggregateProject = {
  __typename?: 'AggregateProject';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
};

export type AggregateSurvey_Published = {
  __typename?: 'AggregateSurvey_published';
  _count?: Maybe<Survey_PublishedCountAggregate>;
  _max?: Maybe<Survey_PublishedMaxAggregate>;
  _min?: Maybe<Survey_PublishedMinAggregate>;
};

export type AggregateSurvey_Result = {
  __typename?: 'AggregateSurvey_result';
  _count?: Maybe<Survey_ResultCountAggregate>;
  _max?: Maybe<Survey_ResultMaxAggregate>;
  _min?: Maybe<Survey_ResultMinAggregate>;
};

export type AggregateSurvey_Schema = {
  __typename?: 'AggregateSurvey_schema';
  _count?: Maybe<Survey_SchemaCountAggregate>;
  _max?: Maybe<Survey_SchemaMaxAggregate>;
  _min?: Maybe<Survey_SchemaMinAggregate>;
};

export type AppointmentCampaignInput = {
  additional_info: Scalars['JSONObject']['input'];
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_type: Campaign_Type;
  contact_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};

export type AvailableCampaigns = {
  __typename?: 'AvailableCampaigns';
  appointment: Scalars['Boolean']['output'];
  offer: Scalars['Boolean']['output'];
  rating: Scalars['Boolean']['output'];
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type Campaign = {
  __typename?: 'Campaign';
  _count?: Maybe<CampaignCount>;
  additional_info?: Maybe<Scalars['JSON']['output']>;
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign?: Maybe<Campaign>;
  campaign_action: Array<Campaign_Action>;
  campaign_name?: Maybe<Scalars['String']['output']>;
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  campaign_type?: Maybe<Campaign_Type>;
  client: Client;
  client_id: Scalars['String']['output'];
  contact: Contact;
  contact_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  crons: Array<Cron>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_campaign_id?: Maybe<Scalars['Int']['output']>;
  ext_video_id?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  previous_campaign?: Maybe<Campaign>;
  previous_campaign_id?: Maybe<Scalars['String']['output']>;
  project: Project;
  project_id: Scalars['String']['output'];
  statement?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};


export type CampaignAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type CampaignCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type CampaignCampaign_ActionArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type CampaignCronsArgs = {
  cursor?: InputMaybe<CronWhereUniqueInput>;
  distinct?: InputMaybe<Array<CronScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CronOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type CampaignFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type CampaignPrevious_CampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignAvgAggregate = {
  __typename?: 'CampaignAvgAggregate';
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  ext_campaign_id?: Maybe<Scalars['Float']['output']>;
};

export type CampaignAvgOrderByAggregateInput = {
  campaign_state?: InputMaybe<SortOrder>;
  ext_campaign_id?: InputMaybe<SortOrder>;
};

export type CampaignCount = {
  __typename?: 'CampaignCount';
  campaign_action: Scalars['Int']['output'];
  crons: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
};


export type CampaignCountCampaign_ActionArgs = {
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type CampaignCountCronsArgs = {
  where?: InputMaybe<CronWhereInput>;
};


export type CampaignCountFilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};

export type CampaignCountAggregate = {
  __typename?: 'CampaignCountAggregate';
  _all: Scalars['Int']['output'];
  additional_info: Scalars['Int']['output'];
  agent_id: Scalars['Int']['output'];
  campaign_name: Scalars['Int']['output'];
  campaign_state: Scalars['Int']['output'];
  campaign_type: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  contact_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  ext_campaign_id: Scalars['Int']['output'];
  ext_video_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  previous_campaign_id: Scalars['Int']['output'];
  project_id: Scalars['Int']['output'];
  statement: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type CampaignCountOrderByAggregateInput = {
  additional_info?: InputMaybe<SortOrder>;
  agent_id?: InputMaybe<SortOrder>;
  campaign_name?: InputMaybe<SortOrder>;
  campaign_state?: InputMaybe<SortOrder>;
  campaign_type?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  ext_campaign_id?: InputMaybe<SortOrder>;
  ext_video_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  previous_campaign_id?: InputMaybe<SortOrder>;
  project_id?: InputMaybe<SortOrder>;
  statement?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type CampaignCreateInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateManyAgentInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client_id: Scalars['String']['input'];
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign_id?: InputMaybe<Scalars['String']['input']>;
  project_id: Scalars['String']['input'];
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateManyAgentInputEnvelope = {
  data: Array<CampaignCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignCreateManyClientInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign_id?: InputMaybe<Scalars['String']['input']>;
  project_id: Scalars['String']['input'];
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateManyClientInputEnvelope = {
  data: Array<CampaignCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignCreateManyContactInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign_id?: InputMaybe<Scalars['String']['input']>;
  project_id: Scalars['String']['input'];
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateManyContactInputEnvelope = {
  data: Array<CampaignCreateManyContactInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignCreateManyInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client_id: Scalars['String']['input'];
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign_id?: InputMaybe<Scalars['String']['input']>;
  project_id: Scalars['String']['input'];
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateManyProjectInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client_id: Scalars['String']['input'];
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign_id?: InputMaybe<Scalars['String']['input']>;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateManyProjectInputEnvelope = {
  data: Array<CampaignCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutAgentInput>>;
  createMany?: InputMaybe<CampaignCreateManyAgentInputEnvelope>;
};

export type CampaignCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutClientInput>>;
  createMany?: InputMaybe<CampaignCreateManyClientInputEnvelope>;
};

export type CampaignCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutContactInput>>;
  createMany?: InputMaybe<CampaignCreateManyContactInputEnvelope>;
};

export type CampaignCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutProjectInput>>;
  createMany?: InputMaybe<CampaignCreateManyProjectInputEnvelope>;
};

export type CampaignCreateNestedOneWithoutCampaignInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<CampaignCreateWithoutCampaignInput>;
};

export type CampaignCreateNestedOneWithoutCampaign_ActionInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<CampaignCreateWithoutCampaign_ActionInput>;
};

export type CampaignCreateNestedOneWithoutCronsInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutCronsInput>;
  create?: InputMaybe<CampaignCreateWithoutCronsInput>;
};

export type CampaignCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<CampaignCreateWithoutFilesInput>;
};

export type CampaignCreateNestedOneWithoutPrevious_CampaignInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutPrevious_CampaignInput>;
  create?: InputMaybe<CampaignCreateWithoutPrevious_CampaignInput>;
};

export type CampaignCreateOrConnectWithoutAgentInput = {
  create: CampaignCreateWithoutAgentInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutCampaignInput = {
  create: CampaignCreateWithoutCampaignInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutCampaign_ActionInput = {
  create: CampaignCreateWithoutCampaign_ActionInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutClientInput = {
  create: CampaignCreateWithoutClientInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutContactInput = {
  create: CampaignCreateWithoutContactInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutCronsInput = {
  create: CampaignCreateWithoutCronsInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutFilesInput = {
  create: CampaignCreateWithoutFilesInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutPrevious_CampaignInput = {
  create: CampaignCreateWithoutPrevious_CampaignInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateOrConnectWithoutProjectInput = {
  create: CampaignCreateWithoutProjectInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignCreateWithoutAgentInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutCampaignInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutCampaign_ActionInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutClientInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutContactInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutCronsInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutFilesInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutPrevious_CampaignInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  project: ProjectCreateNestedOneWithoutCampaignInput;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignCreateWithoutProjectInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaignInput>;
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutPrevious_CampaignInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutCampaignInput>;
  campaign_name?: InputMaybe<Scalars['String']['input']>;
  campaign_state?: InputMaybe<Scalars['Decimal']['input']>;
  campaign_type?: InputMaybe<Campaign_Type>;
  client: ClientCreateNestedOneWithoutCampaignInput;
  contact: ContactCreateNestedOneWithoutCampaignInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  crons?: InputMaybe<CronCreateNestedManyWithoutCampaignInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_campaign_id?: InputMaybe<Scalars['Int']['input']>;
  ext_video_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutCampaignInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  previous_campaign?: InputMaybe<CampaignCreateNestedOneWithoutCampaignInput>;
  statement?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CampaignGroupBy = {
  __typename?: 'CampaignGroupBy';
  _avg?: Maybe<CampaignAvgAggregate>;
  _count?: Maybe<CampaignCountAggregate>;
  _max?: Maybe<CampaignMaxAggregate>;
  _min?: Maybe<CampaignMinAggregate>;
  _sum?: Maybe<CampaignSumAggregate>;
  additional_info?: Maybe<Scalars['JSON']['output']>;
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_name?: Maybe<Scalars['String']['output']>;
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  campaign_type?: Maybe<Campaign_Type>;
  client_id: Scalars['String']['output'];
  contact_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_campaign_id?: Maybe<Scalars['Int']['output']>;
  ext_video_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  previous_campaign_id?: Maybe<Scalars['String']['output']>;
  project_id: Scalars['String']['output'];
  statement?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CampaignInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_type: Campaign_Type;
  contact_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};

export type CampaignListRelationFilter = {
  every?: InputMaybe<CampaignWhereInput>;
  none?: InputMaybe<CampaignWhereInput>;
  some?: InputMaybe<CampaignWhereInput>;
};

export type CampaignMaxAggregate = {
  __typename?: 'CampaignMaxAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_name?: Maybe<Scalars['String']['output']>;
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  campaign_type?: Maybe<Campaign_Type>;
  client_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_campaign_id?: Maybe<Scalars['Int']['output']>;
  ext_video_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  previous_campaign_id?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  statement?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CampaignMaxOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  campaign_name?: InputMaybe<SortOrder>;
  campaign_state?: InputMaybe<SortOrder>;
  campaign_type?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  ext_campaign_id?: InputMaybe<SortOrder>;
  ext_video_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  previous_campaign_id?: InputMaybe<SortOrder>;
  project_id?: InputMaybe<SortOrder>;
  statement?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type CampaignMinAggregate = {
  __typename?: 'CampaignMinAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_name?: Maybe<Scalars['String']['output']>;
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  campaign_type?: Maybe<Campaign_Type>;
  client_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_campaign_id?: Maybe<Scalars['Int']['output']>;
  ext_video_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  previous_campaign_id?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  statement?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CampaignMinOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  campaign_name?: InputMaybe<SortOrder>;
  campaign_state?: InputMaybe<SortOrder>;
  campaign_type?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  ext_campaign_id?: InputMaybe<SortOrder>;
  ext_video_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  previous_campaign_id?: InputMaybe<SortOrder>;
  project_id?: InputMaybe<SortOrder>;
  statement?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type CampaignNullableRelationFilter = {
  is?: InputMaybe<CampaignWhereInput>;
  isNot?: InputMaybe<CampaignWhereInput>;
};

export type CampaignOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CampaignOrderByWithAggregationInput = {
  _avg?: InputMaybe<CampaignAvgOrderByAggregateInput>;
  _count?: InputMaybe<CampaignCountOrderByAggregateInput>;
  _max?: InputMaybe<CampaignMaxOrderByAggregateInput>;
  _min?: InputMaybe<CampaignMinOrderByAggregateInput>;
  _sum?: InputMaybe<CampaignSumOrderByAggregateInput>;
  additional_info?: InputMaybe<SortOrderInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  campaign_name?: InputMaybe<SortOrderInput>;
  campaign_state?: InputMaybe<SortOrderInput>;
  campaign_type?: InputMaybe<SortOrderInput>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  ext_campaign_id?: InputMaybe<SortOrderInput>;
  ext_video_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrderInput>;
  previous_campaign_id?: InputMaybe<SortOrderInput>;
  project_id?: InputMaybe<SortOrder>;
  statement?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export type CampaignOrderByWithRelationInput = {
  additional_info?: InputMaybe<SortOrderInput>;
  agent?: InputMaybe<AgentOrderByWithRelationInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  campaign?: InputMaybe<CampaignOrderByWithRelationInput>;
  campaign_action?: InputMaybe<Campaign_ActionOrderByRelationAggregateInput>;
  campaign_name?: InputMaybe<SortOrderInput>;
  campaign_state?: InputMaybe<SortOrderInput>;
  campaign_type?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  contact?: InputMaybe<ContactOrderByWithRelationInput>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  crons?: InputMaybe<CronOrderByRelationAggregateInput>;
  deleted?: InputMaybe<SortOrderInput>;
  ext_campaign_id?: InputMaybe<SortOrderInput>;
  ext_video_id?: InputMaybe<SortOrderInput>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrderInput>;
  previous_campaign?: InputMaybe<CampaignOrderByWithRelationInput>;
  previous_campaign_id?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  project_id?: InputMaybe<SortOrder>;
  statement?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export type CampaignRelationFilter = {
  is?: InputMaybe<CampaignWhereInput>;
  isNot?: InputMaybe<CampaignWhereInput>;
};

export enum CampaignScalarFieldEnum {
  AdditionalInfo = 'additional_info',
  AgentId = 'agent_id',
  CampaignName = 'campaign_name',
  CampaignState = 'campaign_state',
  CampaignType = 'campaign_type',
  ClientId = 'client_id',
  ContactId = 'contact_id',
  Created = 'created',
  Deleted = 'deleted',
  ExtCampaignId = 'ext_campaign_id',
  ExtVideoId = 'ext_video_id',
  Id = 'id',
  Number = 'number',
  PreviousCampaignId = 'previous_campaign_id',
  ProjectId = 'project_id',
  Statement = 'statement',
  Updated = 'updated'
}

export type CampaignScalarWhereInput = {
  AND?: InputMaybe<Array<CampaignScalarWhereInput>>;
  NOT?: InputMaybe<Array<CampaignScalarWhereInput>>;
  OR?: InputMaybe<Array<CampaignScalarWhereInput>>;
  additional_info?: InputMaybe<JsonNullableFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  campaign_name?: InputMaybe<StringNullableFilter>;
  campaign_state?: InputMaybe<DecimalNullableFilter>;
  campaign_type?: InputMaybe<Enumcampaign_TypeNullableFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact_id?: InputMaybe<UuidFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  ext_campaign_id?: InputMaybe<IntNullableFilter>;
  ext_video_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  number?: InputMaybe<StringNullableFilter>;
  previous_campaign_id?: InputMaybe<UuidNullableFilter>;
  project_id?: InputMaybe<UuidFilter>;
  statement?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type CampaignScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CampaignScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CampaignScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CampaignScalarWhereWithAggregatesInput>>;
  additional_info?: InputMaybe<JsonNullableWithAggregatesFilter>;
  agent_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  campaign_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  campaign_state?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  campaign_type?: InputMaybe<Enumcampaign_TypeNullableWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  contact_id?: InputMaybe<UuidWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  ext_campaign_id?: InputMaybe<IntNullableWithAggregatesFilter>;
  ext_video_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  number?: InputMaybe<StringNullableWithAggregatesFilter>;
  previous_campaign_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  project_id?: InputMaybe<UuidWithAggregatesFilter>;
  statement?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type CampaignSumAggregate = {
  __typename?: 'CampaignSumAggregate';
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  ext_campaign_id?: Maybe<Scalars['Int']['output']>;
};

export type CampaignSumOrderByAggregateInput = {
  campaign_state?: InputMaybe<SortOrder>;
  ext_campaign_id?: InputMaybe<SortOrder>;
};

export type CampaignUpdateInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateManyMutationInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateManyWithWhereWithoutAgentInput = {
  data: CampaignUpdateManyMutationInput;
  where: CampaignScalarWhereInput;
};

export type CampaignUpdateManyWithWhereWithoutClientInput = {
  data: CampaignUpdateManyMutationInput;
  where: CampaignScalarWhereInput;
};

export type CampaignUpdateManyWithWhereWithoutContactInput = {
  data: CampaignUpdateManyMutationInput;
  where: CampaignScalarWhereInput;
};

export type CampaignUpdateManyWithWhereWithoutProjectInput = {
  data: CampaignUpdateManyMutationInput;
  where: CampaignScalarWhereInput;
};

export type CampaignUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutAgentInput>>;
  createMany?: InputMaybe<CampaignCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<CampaignUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<Array<CampaignUpdateManyWithWhereWithoutAgentInput>>;
  upsert?: InputMaybe<Array<CampaignUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type CampaignUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutClientInput>>;
  createMany?: InputMaybe<CampaignCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<CampaignUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<CampaignUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<CampaignUpsertWithWhereUniqueWithoutClientInput>>;
};

export type CampaignUpdateManyWithoutContactNestedInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutContactInput>>;
  createMany?: InputMaybe<CampaignCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<CampaignUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: InputMaybe<Array<CampaignUpdateManyWithWhereWithoutContactInput>>;
  upsert?: InputMaybe<Array<CampaignUpsertWithWhereUniqueWithoutContactInput>>;
};

export type CampaignUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CampaignCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<CampaignCreateWithoutProjectInput>>;
  createMany?: InputMaybe<CampaignCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CampaignScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  set?: InputMaybe<Array<CampaignWhereUniqueInput>>;
  update?: InputMaybe<Array<CampaignUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<CampaignUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<CampaignUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type CampaignUpdateOneRequiredWithoutCampaign_ActionNestedInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<CampaignCreateWithoutCampaign_ActionInput>;
  update?: InputMaybe<CampaignUpdateToOneWithWhereWithoutCampaign_ActionInput>;
  upsert?: InputMaybe<CampaignUpsertWithoutCampaign_ActionInput>;
};

export type CampaignUpdateOneRequiredWithoutCronsNestedInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutCronsInput>;
  create?: InputMaybe<CampaignCreateWithoutCronsInput>;
  update?: InputMaybe<CampaignUpdateToOneWithWhereWithoutCronsInput>;
  upsert?: InputMaybe<CampaignUpsertWithoutCronsInput>;
};

export type CampaignUpdateOneWithoutCampaignNestedInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<CampaignCreateWithoutCampaignInput>;
  delete?: InputMaybe<CampaignWhereInput>;
  disconnect?: InputMaybe<CampaignWhereInput>;
  update?: InputMaybe<CampaignUpdateToOneWithWhereWithoutCampaignInput>;
  upsert?: InputMaybe<CampaignUpsertWithoutCampaignInput>;
};

export type CampaignUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<CampaignCreateWithoutFilesInput>;
  delete?: InputMaybe<CampaignWhereInput>;
  disconnect?: InputMaybe<CampaignWhereInput>;
  update?: InputMaybe<CampaignUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<CampaignUpsertWithoutFilesInput>;
};

export type CampaignUpdateOneWithoutPrevious_CampaignNestedInput = {
  connect?: InputMaybe<CampaignWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CampaignCreateOrConnectWithoutPrevious_CampaignInput>;
  create?: InputMaybe<CampaignCreateWithoutPrevious_CampaignInput>;
  delete?: InputMaybe<CampaignWhereInput>;
  disconnect?: InputMaybe<CampaignWhereInput>;
  update?: InputMaybe<CampaignUpdateToOneWithWhereWithoutPrevious_CampaignInput>;
  upsert?: InputMaybe<CampaignUpsertWithoutPrevious_CampaignInput>;
};

export type CampaignUpdateToOneWithWhereWithoutCampaignInput = {
  data: CampaignUpdateWithoutCampaignInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpdateToOneWithWhereWithoutCampaign_ActionInput = {
  data: CampaignUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpdateToOneWithWhereWithoutCronsInput = {
  data: CampaignUpdateWithoutCronsInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpdateToOneWithWhereWithoutFilesInput = {
  data: CampaignUpdateWithoutFilesInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpdateToOneWithWhereWithoutPrevious_CampaignInput = {
  data: CampaignUpdateWithoutPrevious_CampaignInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpdateWithWhereUniqueWithoutAgentInput = {
  data: CampaignUpdateWithoutAgentInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpdateWithWhereUniqueWithoutClientInput = {
  data: CampaignUpdateWithoutClientInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpdateWithWhereUniqueWithoutContactInput = {
  data: CampaignUpdateWithoutContactInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpdateWithWhereUniqueWithoutProjectInput = {
  data: CampaignUpdateWithoutProjectInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpdateWithoutAgentInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutCampaignInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutCampaign_ActionInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutClientInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutContactInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutCronsInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutFilesInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutPrevious_CampaignInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneRequiredWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpdateWithoutProjectInput = {
  additional_info?: InputMaybe<Scalars['JSON']['input']>;
  agent?: InputMaybe<AgentUpdateOneWithoutCampaignNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneWithoutPrevious_CampaignNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutCampaignNestedInput>;
  campaign_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  campaign_state?: InputMaybe<NullableDecimalFieldUpdateOperationsInput>;
  campaign_type?: InputMaybe<NullableEnumcampaign_TypeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaignNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutCampaignNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  crons?: InputMaybe<CronUpdateManyWithoutCampaignNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ext_campaign_id?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  ext_video_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutCampaignNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  number?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previous_campaign?: InputMaybe<CampaignUpdateOneWithoutCampaignNestedInput>;
  statement?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CampaignUpsertWithWhereUniqueWithoutAgentInput = {
  create: CampaignCreateWithoutAgentInput;
  update: CampaignUpdateWithoutAgentInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpsertWithWhereUniqueWithoutClientInput = {
  create: CampaignCreateWithoutClientInput;
  update: CampaignUpdateWithoutClientInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpsertWithWhereUniqueWithoutContactInput = {
  create: CampaignCreateWithoutContactInput;
  update: CampaignUpdateWithoutContactInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpsertWithWhereUniqueWithoutProjectInput = {
  create: CampaignCreateWithoutProjectInput;
  update: CampaignUpdateWithoutProjectInput;
  where: CampaignWhereUniqueInput;
};

export type CampaignUpsertWithoutCampaignInput = {
  create: CampaignCreateWithoutCampaignInput;
  update: CampaignUpdateWithoutCampaignInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpsertWithoutCampaign_ActionInput = {
  create: CampaignCreateWithoutCampaign_ActionInput;
  update: CampaignUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpsertWithoutCronsInput = {
  create: CampaignCreateWithoutCronsInput;
  update: CampaignUpdateWithoutCronsInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpsertWithoutFilesInput = {
  create: CampaignCreateWithoutFilesInput;
  update: CampaignUpdateWithoutFilesInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignUpsertWithoutPrevious_CampaignInput = {
  create: CampaignCreateWithoutPrevious_CampaignInput;
  update: CampaignUpdateWithoutPrevious_CampaignInput;
  where?: InputMaybe<CampaignWhereInput>;
};

export type CampaignWhereInput = {
  AND?: InputMaybe<Array<CampaignWhereInput>>;
  NOT?: InputMaybe<Array<CampaignWhereInput>>;
  OR?: InputMaybe<Array<CampaignWhereInput>>;
  additional_info?: InputMaybe<JsonNullableFilter>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  campaign?: InputMaybe<CampaignNullableRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  campaign_name?: InputMaybe<StringNullableFilter>;
  campaign_state?: InputMaybe<DecimalNullableFilter>;
  campaign_type?: InputMaybe<Enumcampaign_TypeNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact?: InputMaybe<ContactRelationFilter>;
  contact_id?: InputMaybe<UuidFilter>;
  created?: InputMaybe<DateTimeFilter>;
  crons?: InputMaybe<CronListRelationFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  ext_campaign_id?: InputMaybe<IntNullableFilter>;
  ext_video_id?: InputMaybe<StringNullableFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  number?: InputMaybe<StringNullableFilter>;
  previous_campaign?: InputMaybe<CampaignNullableRelationFilter>;
  previous_campaign_id?: InputMaybe<UuidNullableFilter>;
  project?: InputMaybe<ProjectRelationFilter>;
  project_id?: InputMaybe<UuidFilter>;
  statement?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type CampaignWhereUniqueInput = {
  AND?: InputMaybe<Array<CampaignWhereInput>>;
  NOT?: InputMaybe<Array<CampaignWhereInput>>;
  OR?: InputMaybe<Array<CampaignWhereInput>>;
  additional_info?: InputMaybe<JsonNullableFilter>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  campaign?: InputMaybe<CampaignNullableRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  campaign_name?: InputMaybe<StringNullableFilter>;
  campaign_state?: InputMaybe<DecimalNullableFilter>;
  campaign_type?: InputMaybe<Enumcampaign_TypeNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact?: InputMaybe<ContactRelationFilter>;
  contact_id?: InputMaybe<UuidFilter>;
  created?: InputMaybe<DateTimeFilter>;
  crons?: InputMaybe<CronListRelationFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  ext_campaign_id?: InputMaybe<IntNullableFilter>;
  ext_video_id?: InputMaybe<StringNullableFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<StringNullableFilter>;
  previous_campaign?: InputMaybe<CampaignNullableRelationFilter>;
  previous_campaign_id?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectRelationFilter>;
  project_id?: InputMaybe<UuidFilter>;
  statement?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type Campaign_Action = {
  __typename?: 'Campaign_action';
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign: Campaign;
  campaign_id: Scalars['String']['output'];
  client: Client;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  ext_file_id?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  file_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_state: Campaign_Action_Main_State;
  message?: Maybe<Scalars['String']['output']>;
  sub_state: Campaign_Action_Sub_State;
  url?: Maybe<Scalars['String']['output']>;
};


export type Campaign_ActionAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type Campaign_ActionFileArgs = {
  where?: InputMaybe<FileWhereInput>;
};

export type Campaign_ActionCountAggregate = {
  __typename?: 'Campaign_actionCountAggregate';
  _all: Scalars['Int']['output'];
  agent_id: Scalars['Int']['output'];
  campaign_id: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  ext_file_id: Scalars['Int']['output'];
  file_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  main_state: Scalars['Int']['output'];
  message: Scalars['Int']['output'];
  sub_state: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type Campaign_ActionCountOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  ext_file_id?: InputMaybe<SortOrder>;
  file_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  main_state?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  sub_state?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type Campaign_ActionCreateInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaign_ActionInput>;
  campaign: CampaignCreateNestedOneWithoutCampaign_ActionInput;
  client: ClientCreateNestedOneWithoutCampaign_ActionInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<FileCreateNestedOneWithoutCampaign_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateManyAgentInput = {
  campaign_id: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateManyAgentInputEnvelope = {
  data: Array<Campaign_ActionCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Campaign_ActionCreateManyCampaignInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateManyCampaignInputEnvelope = {
  data: Array<Campaign_ActionCreateManyCampaignInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Campaign_ActionCreateManyClientInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateManyClientInputEnvelope = {
  data: Array<Campaign_ActionCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Campaign_ActionCreateManyFileInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_id: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateManyFileInputEnvelope = {
  data: Array<Campaign_ActionCreateManyFileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Campaign_ActionCreateManyInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_id: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutAgentInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyAgentInputEnvelope>;
};

export type Campaign_ActionCreateNestedManyWithoutCampaignInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyCampaignInputEnvelope>;
};

export type Campaign_ActionCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutClientInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyClientInputEnvelope>;
};

export type Campaign_ActionCreateNestedManyWithoutFileInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutFileInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutFileInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyFileInputEnvelope>;
};

export type Campaign_ActionCreateOrConnectWithoutAgentInput = {
  create: Campaign_ActionCreateWithoutAgentInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionCreateOrConnectWithoutCampaignInput = {
  create: Campaign_ActionCreateWithoutCampaignInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionCreateOrConnectWithoutClientInput = {
  create: Campaign_ActionCreateWithoutClientInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionCreateOrConnectWithoutFileInput = {
  create: Campaign_ActionCreateWithoutFileInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionCreateWithoutAgentInput = {
  campaign: CampaignCreateNestedOneWithoutCampaign_ActionInput;
  client: ClientCreateNestedOneWithoutCampaign_ActionInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<FileCreateNestedOneWithoutCampaign_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateWithoutCampaignInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaign_ActionInput>;
  client: ClientCreateNestedOneWithoutCampaign_ActionInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<FileCreateNestedOneWithoutCampaign_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateWithoutClientInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaign_ActionInput>;
  campaign: CampaignCreateNestedOneWithoutCampaign_ActionInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<FileCreateNestedOneWithoutCampaign_ActionInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionCreateWithoutFileInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutCampaign_ActionInput>;
  campaign: CampaignCreateNestedOneWithoutCampaign_ActionInput;
  client: ClientCreateNestedOneWithoutCampaign_ActionInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  ext_file_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state: Campaign_Action_Main_State;
  message?: InputMaybe<Scalars['String']['input']>;
  sub_state: Campaign_Action_Sub_State;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Campaign_ActionGroupBy = {
  __typename?: 'Campaign_actionGroupBy';
  _count?: Maybe<Campaign_ActionCountAggregate>;
  _max?: Maybe<Campaign_ActionMaxAggregate>;
  _min?: Maybe<Campaign_ActionMinAggregate>;
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_id: Scalars['String']['output'];
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  ext_file_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_state: Campaign_Action_Main_State;
  message?: Maybe<Scalars['String']['output']>;
  sub_state: Campaign_Action_Sub_State;
  url?: Maybe<Scalars['String']['output']>;
};

export type Campaign_ActionListRelationFilter = {
  every?: InputMaybe<Campaign_ActionWhereInput>;
  none?: InputMaybe<Campaign_ActionWhereInput>;
  some?: InputMaybe<Campaign_ActionWhereInput>;
};

export type Campaign_ActionMaxAggregate = {
  __typename?: 'Campaign_actionMaxAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_file_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  main_state?: Maybe<Campaign_Action_Main_State>;
  message?: Maybe<Scalars['String']['output']>;
  sub_state?: Maybe<Campaign_Action_Sub_State>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Campaign_ActionMaxOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  ext_file_id?: InputMaybe<SortOrder>;
  file_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  main_state?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  sub_state?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type Campaign_ActionMinAggregate = {
  __typename?: 'Campaign_actionMinAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_file_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  main_state?: Maybe<Campaign_Action_Main_State>;
  message?: Maybe<Scalars['String']['output']>;
  sub_state?: Maybe<Campaign_Action_Sub_State>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Campaign_ActionMinOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  ext_file_id?: InputMaybe<SortOrder>;
  file_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  main_state?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  sub_state?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type Campaign_ActionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Campaign_ActionOrderByWithAggregationInput = {
  _count?: InputMaybe<Campaign_ActionCountOrderByAggregateInput>;
  _max?: InputMaybe<Campaign_ActionMaxOrderByAggregateInput>;
  _min?: InputMaybe<Campaign_ActionMinOrderByAggregateInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  ext_file_id?: InputMaybe<SortOrderInput>;
  file_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  main_state?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrderInput>;
  sub_state?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export type Campaign_ActionOrderByWithRelationInput = {
  agent?: InputMaybe<AgentOrderByWithRelationInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  campaign?: InputMaybe<CampaignOrderByWithRelationInput>;
  campaign_id?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  ext_file_id?: InputMaybe<SortOrderInput>;
  file?: InputMaybe<FileOrderByWithRelationInput>;
  file_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  main_state?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrderInput>;
  sub_state?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrderInput>;
};

export enum Campaign_ActionScalarFieldEnum {
  AgentId = 'agent_id',
  CampaignId = 'campaign_id',
  ClientId = 'client_id',
  Created = 'created',
  ExtFileId = 'ext_file_id',
  FileId = 'file_id',
  Id = 'id',
  MainState = 'main_state',
  Message = 'message',
  SubState = 'sub_state',
  Url = 'url'
}

export type Campaign_ActionScalarWhereInput = {
  AND?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  NOT?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  OR?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  campaign_id?: InputMaybe<UuidFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  ext_file_id?: InputMaybe<StringNullableFilter>;
  file_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFilter>;
  message?: InputMaybe<StringNullableFilter>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type Campaign_ActionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Campaign_ActionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Campaign_ActionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Campaign_ActionScalarWhereWithAggregatesInput>>;
  agent_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  campaign_id?: InputMaybe<UuidWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  ext_file_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  file_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateWithAggregatesFilter>;
  message?: InputMaybe<StringNullableWithAggregatesFilter>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type Campaign_ActionUpdateInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutCampaign_ActionNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ext_file_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  file?: InputMaybe<FileUpdateOneWithoutCampaign_ActionNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type Campaign_ActionUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ext_file_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type Campaign_ActionUpdateManyWithWhereWithoutAgentInput = {
  data: Campaign_ActionUpdateManyMutationInput;
  where: Campaign_ActionScalarWhereInput;
};

export type Campaign_ActionUpdateManyWithWhereWithoutCampaignInput = {
  data: Campaign_ActionUpdateManyMutationInput;
  where: Campaign_ActionScalarWhereInput;
};

export type Campaign_ActionUpdateManyWithWhereWithoutClientInput = {
  data: Campaign_ActionUpdateManyMutationInput;
  where: Campaign_ActionScalarWhereInput;
};

export type Campaign_ActionUpdateManyWithWhereWithoutFileInput = {
  data: Campaign_ActionUpdateManyMutationInput;
  where: Campaign_ActionScalarWhereInput;
};

export type Campaign_ActionUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutAgentInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  set?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  update?: InputMaybe<Array<Campaign_ActionUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<Array<Campaign_ActionUpdateManyWithWhereWithoutAgentInput>>;
  upsert?: InputMaybe<Array<Campaign_ActionUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type Campaign_ActionUpdateManyWithoutCampaignNestedInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyCampaignInputEnvelope>;
  delete?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  set?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  update?: InputMaybe<Array<Campaign_ActionUpdateWithWhereUniqueWithoutCampaignInput>>;
  updateMany?: InputMaybe<Array<Campaign_ActionUpdateManyWithWhereWithoutCampaignInput>>;
  upsert?: InputMaybe<Array<Campaign_ActionUpsertWithWhereUniqueWithoutCampaignInput>>;
};

export type Campaign_ActionUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutClientInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  set?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  update?: InputMaybe<Array<Campaign_ActionUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<Campaign_ActionUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<Campaign_ActionUpsertWithWhereUniqueWithoutClientInput>>;
};

export type Campaign_ActionUpdateManyWithoutFileNestedInput = {
  connect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Campaign_ActionCreateOrConnectWithoutFileInput>>;
  create?: InputMaybe<Array<Campaign_ActionCreateWithoutFileInput>>;
  createMany?: InputMaybe<Campaign_ActionCreateManyFileInputEnvelope>;
  delete?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Campaign_ActionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  set?: InputMaybe<Array<Campaign_ActionWhereUniqueInput>>;
  update?: InputMaybe<Array<Campaign_ActionUpdateWithWhereUniqueWithoutFileInput>>;
  updateMany?: InputMaybe<Array<Campaign_ActionUpdateManyWithWhereWithoutFileInput>>;
  upsert?: InputMaybe<Array<Campaign_ActionUpsertWithWhereUniqueWithoutFileInput>>;
};

export type Campaign_ActionUpdateWithWhereUniqueWithoutAgentInput = {
  data: Campaign_ActionUpdateWithoutAgentInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpdateWithWhereUniqueWithoutCampaignInput = {
  data: Campaign_ActionUpdateWithoutCampaignInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpdateWithWhereUniqueWithoutClientInput = {
  data: Campaign_ActionUpdateWithoutClientInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpdateWithWhereUniqueWithoutFileInput = {
  data: Campaign_ActionUpdateWithoutFileInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpdateWithoutAgentInput = {
  campaign?: InputMaybe<CampaignUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ext_file_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  file?: InputMaybe<FileUpdateOneWithoutCampaign_ActionNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type Campaign_ActionUpdateWithoutCampaignInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutCampaign_ActionNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ext_file_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  file?: InputMaybe<FileUpdateOneWithoutCampaign_ActionNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type Campaign_ActionUpdateWithoutClientInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutCampaign_ActionNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ext_file_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  file?: InputMaybe<FileUpdateOneWithoutCampaign_ActionNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type Campaign_ActionUpdateWithoutFileInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutCampaign_ActionNestedInput>;
  campaign?: InputMaybe<CampaignUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCampaign_ActionNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ext_file_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type Campaign_ActionUpsertWithWhereUniqueWithoutAgentInput = {
  create: Campaign_ActionCreateWithoutAgentInput;
  update: Campaign_ActionUpdateWithoutAgentInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpsertWithWhereUniqueWithoutCampaignInput = {
  create: Campaign_ActionCreateWithoutCampaignInput;
  update: Campaign_ActionUpdateWithoutCampaignInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpsertWithWhereUniqueWithoutClientInput = {
  create: Campaign_ActionCreateWithoutClientInput;
  update: Campaign_ActionUpdateWithoutClientInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionUpsertWithWhereUniqueWithoutFileInput = {
  create: Campaign_ActionCreateWithoutFileInput;
  update: Campaign_ActionUpdateWithoutFileInput;
  where: Campaign_ActionWhereUniqueInput;
};

export type Campaign_ActionWhereInput = {
  AND?: InputMaybe<Array<Campaign_ActionWhereInput>>;
  NOT?: InputMaybe<Array<Campaign_ActionWhereInput>>;
  OR?: InputMaybe<Array<Campaign_ActionWhereInput>>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  campaign?: InputMaybe<CampaignRelationFilter>;
  campaign_id?: InputMaybe<UuidFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  ext_file_id?: InputMaybe<StringNullableFilter>;
  file?: InputMaybe<FileNullableRelationFilter>;
  file_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFilter>;
  message?: InputMaybe<StringNullableFilter>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type Campaign_ActionWhereUniqueInput = {
  AND?: InputMaybe<Array<Campaign_ActionWhereInput>>;
  NOT?: InputMaybe<Array<Campaign_ActionWhereInput>>;
  OR?: InputMaybe<Array<Campaign_ActionWhereInput>>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  campaign?: InputMaybe<CampaignRelationFilter>;
  campaign_id?: InputMaybe<UuidFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  ext_file_id?: InputMaybe<StringNullableFilter>;
  file?: InputMaybe<FileNullableRelationFilter>;
  file_id?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_state?: InputMaybe<Enumcampaign_Action_Main_StateFilter>;
  message?: InputMaybe<StringNullableFilter>;
  sub_state?: InputMaybe<Enumcampaign_Action_Sub_StateFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type CheckRatingResult = {
  __typename?: 'CheckRatingResult';
  alreadyRated: Scalars['String']['output'];
  formal: Scalars['String']['output'];
};

export type Client = {
  __typename?: 'Client';
  _count?: Maybe<ClientCount>;
  agent: Array<Agent>;
  bucket: Scalars['String']['output'];
  campaign: Array<Campaign>;
  campaign_action: Array<Campaign_Action>;
  client_id: Scalars['String']['output'];
  /** [ClientConfig] */
  config: Scalars['JSON']['output'];
  config_campaign_client?: Maybe<Config_Campaign_Client>;
  contact: Array<Contact>;
  cron: Array<Cron>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  dkim?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  embed_domains: Array<Scalars['String']['output']>;
  files: Array<File>;
  id: Scalars['String']['output'];
  identity_pool_id: Scalars['String']['output'];
  lists: Array<List>;
  mail_templates: Array<Mail_Template>;
  message: Array<Message>;
  name?: Maybe<Scalars['String']['output']>;
  parameter: Array<Parameter>;
  project: Array<Project>;
  survey_published: Array<Survey_Published>;
  survey_schema: Array<Survey_Schema>;
  user_pool_id: Scalars['String']['output'];
};


export type ClientAgentArgs = {
  cursor?: InputMaybe<AgentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AgentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AgentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgentWhereInput>;
};


export type ClientCampaignArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type ClientCampaign_ActionArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type ClientConfig_Campaign_ClientArgs = {
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type ClientContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type ClientCronArgs = {
  cursor?: InputMaybe<CronWhereUniqueInput>;
  distinct?: InputMaybe<Array<CronScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CronOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type ClientFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type ClientListsArgs = {
  cursor?: InputMaybe<ListWhereUniqueInput>;
  distinct?: InputMaybe<Array<ListScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ListOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListWhereInput>;
};


export type ClientMail_TemplatesArgs = {
  cursor?: InputMaybe<Mail_TemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<Mail_TemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Mail_TemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type ClientMessageArgs = {
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type ClientParameterArgs = {
  cursor?: InputMaybe<ParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};


export type ClientProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type ClientSurvey_PublishedArgs = {
  cursor?: InputMaybe<Survey_PublishedWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_PublishedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type ClientSurvey_SchemaArgs = {
  cursor?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_SchemaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_SchemaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type ClientCount = {
  __typename?: 'ClientCount';
  agent: Scalars['Int']['output'];
  campaign: Scalars['Int']['output'];
  campaign_action: Scalars['Int']['output'];
  contact: Scalars['Int']['output'];
  cron: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
  lists: Scalars['Int']['output'];
  mail_templates: Scalars['Int']['output'];
  message: Scalars['Int']['output'];
  parameter: Scalars['Int']['output'];
  project: Scalars['Int']['output'];
  survey_published: Scalars['Int']['output'];
  survey_schema: Scalars['Int']['output'];
};


export type ClientCountAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type ClientCountCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type ClientCountCampaign_ActionArgs = {
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type ClientCountContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


export type ClientCountCronArgs = {
  where?: InputMaybe<CronWhereInput>;
};


export type ClientCountFilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type ClientCountListsArgs = {
  where?: InputMaybe<ListWhereInput>;
};


export type ClientCountMail_TemplatesArgs = {
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type ClientCountMessageArgs = {
  where?: InputMaybe<MessageWhereInput>;
};


export type ClientCountParameterArgs = {
  where?: InputMaybe<ParameterWhereInput>;
};


export type ClientCountProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type ClientCountSurvey_PublishedArgs = {
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type ClientCountSurvey_SchemaArgs = {
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type ClientCountAggregate = {
  __typename?: 'ClientCountAggregate';
  _all: Scalars['Int']['output'];
  bucket: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  config: Scalars['Int']['output'];
  disabled: Scalars['Int']['output'];
  dkim: Scalars['Int']['output'];
  domain: Scalars['Int']['output'];
  embed_domains: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  identity_pool_id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  user_pool_id: Scalars['Int']['output'];
};

export type ClientCountOrderByAggregateInput = {
  bucket?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrder>;
  disabled?: InputMaybe<SortOrder>;
  dkim?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  embed_domains?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identity_pool_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  user_pool_id?: InputMaybe<SortOrder>;
};

export type ClientCreateInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateManyInput = {
  bucket: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateNestedOneWithoutAgentInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<ClientCreateWithoutAgentInput>;
};

export type ClientCreateNestedOneWithoutCampaignInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<ClientCreateWithoutCampaignInput>;
};

export type ClientCreateNestedOneWithoutCampaign_ActionInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<ClientCreateWithoutCampaign_ActionInput>;
};

export type ClientCreateNestedOneWithoutConfig_Campaign_ClientInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutConfig_Campaign_ClientInput>;
  create?: InputMaybe<ClientCreateWithoutConfig_Campaign_ClientInput>;
};

export type ClientCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<ClientCreateWithoutContactInput>;
};

export type ClientCreateNestedOneWithoutCronInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCronInput>;
  create?: InputMaybe<ClientCreateWithoutCronInput>;
};

export type ClientCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ClientCreateWithoutFilesInput>;
};

export type ClientCreateNestedOneWithoutListsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutListsInput>;
  create?: InputMaybe<ClientCreateWithoutListsInput>;
};

export type ClientCreateNestedOneWithoutMail_TemplatesInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMail_TemplatesInput>;
  create?: InputMaybe<ClientCreateWithoutMail_TemplatesInput>;
};

export type ClientCreateNestedOneWithoutMessageInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<ClientCreateWithoutMessageInput>;
};

export type ClientCreateNestedOneWithoutParameterInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutParameterInput>;
  create?: InputMaybe<ClientCreateWithoutParameterInput>;
};

export type ClientCreateNestedOneWithoutProjectInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ClientCreateWithoutProjectInput>;
};

export type ClientCreateNestedOneWithoutSurvey_PublishedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSurvey_PublishedInput>;
  create?: InputMaybe<ClientCreateWithoutSurvey_PublishedInput>;
};

export type ClientCreateNestedOneWithoutSurvey_SchemaInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSurvey_SchemaInput>;
  create?: InputMaybe<ClientCreateWithoutSurvey_SchemaInput>;
};

export type ClientCreateOrConnectWithoutAgentInput = {
  create: ClientCreateWithoutAgentInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutCampaignInput = {
  create: ClientCreateWithoutCampaignInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutCampaign_ActionInput = {
  create: ClientCreateWithoutCampaign_ActionInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutConfig_Campaign_ClientInput = {
  create: ClientCreateWithoutConfig_Campaign_ClientInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutContactInput = {
  create: ClientCreateWithoutContactInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutCronInput = {
  create: ClientCreateWithoutCronInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutFilesInput = {
  create: ClientCreateWithoutFilesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutListsInput = {
  create: ClientCreateWithoutListsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutMail_TemplatesInput = {
  create: ClientCreateWithoutMail_TemplatesInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutMessageInput = {
  create: ClientCreateWithoutMessageInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutParameterInput = {
  create: ClientCreateWithoutParameterInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutProjectInput = {
  create: ClientCreateWithoutProjectInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutSurvey_PublishedInput = {
  create: ClientCreateWithoutSurvey_PublishedInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutSurvey_SchemaInput = {
  create: ClientCreateWithoutSurvey_SchemaInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateWithoutAgentInput = {
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutCampaignInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutCampaign_ActionInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutConfig_Campaign_ClientInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutContactInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutCronInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutFilesInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutListsInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutMail_TemplatesInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutMessageInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutParameterInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutProjectInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutSurvey_PublishedInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_schema?: InputMaybe<Survey_SchemaCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateWithoutSurvey_SchemaInput = {
  agent?: InputMaybe<AgentCreateNestedManyWithoutClientInput>;
  bucket: Scalars['String']['input'];
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutClientInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutClientInput>;
  client_id: Scalars['String']['input'];
  config: Scalars['JSON']['input'];
  config_campaign_client?: InputMaybe<Config_Campaign_ClientCreateNestedOneWithoutClientInput>;
  contact?: InputMaybe<ContactCreateNestedManyWithoutClientInput>;
  cron?: InputMaybe<CronCreateNestedManyWithoutClientInput>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  dkim?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  embed_domains?: InputMaybe<ClientCreateembed_DomainsInput>;
  files?: InputMaybe<FileCreateNestedManyWithoutClientInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id: Scalars['String']['input'];
  lists?: InputMaybe<ListCreateNestedManyWithoutClientInput>;
  mail_templates?: InputMaybe<Mail_TemplateCreateNestedManyWithoutClientInput>;
  message?: InputMaybe<MessageCreateNestedManyWithoutClientInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parameter?: InputMaybe<ParameterCreateNestedManyWithoutClientInput>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutClientInput>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutClientInput>;
  user_pool_id: Scalars['String']['input'];
};

export type ClientCreateembed_DomainsInput = {
  set: Array<Scalars['String']['input']>;
};

export type ClientGroupBy = {
  __typename?: 'ClientGroupBy';
  _count?: Maybe<ClientCountAggregate>;
  _max?: Maybe<ClientMaxAggregate>;
  _min?: Maybe<ClientMinAggregate>;
  bucket: Scalars['String']['output'];
  client_id: Scalars['String']['output'];
  config: Scalars['JSON']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  dkim?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  embed_domains?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  identity_pool_id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  user_pool_id: Scalars['String']['output'];
};

export type ClientMaxAggregate = {
  __typename?: 'ClientMaxAggregate';
  bucket?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  dkim?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identity_pool_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_pool_id?: Maybe<Scalars['String']['output']>;
};

export type ClientMaxOrderByAggregateInput = {
  bucket?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  disabled?: InputMaybe<SortOrder>;
  dkim?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identity_pool_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  user_pool_id?: InputMaybe<SortOrder>;
};

export type ClientMinAggregate = {
  __typename?: 'ClientMinAggregate';
  bucket?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  dkim?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identity_pool_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  user_pool_id?: Maybe<Scalars['String']['output']>;
};

export type ClientMinOrderByAggregateInput = {
  bucket?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  disabled?: InputMaybe<SortOrder>;
  dkim?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identity_pool_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  user_pool_id?: InputMaybe<SortOrder>;
};

export type ClientNullableRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export type ClientOrderByWithAggregationInput = {
  _count?: InputMaybe<ClientCountOrderByAggregateInput>;
  _max?: InputMaybe<ClientMaxOrderByAggregateInput>;
  _min?: InputMaybe<ClientMinOrderByAggregateInput>;
  bucket?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrder>;
  disabled?: InputMaybe<SortOrderInput>;
  dkim?: InputMaybe<SortOrderInput>;
  domain?: InputMaybe<SortOrder>;
  embed_domains?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  identity_pool_id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  user_pool_id?: InputMaybe<SortOrder>;
};

export type ClientOrderByWithRelationInput = {
  agent?: InputMaybe<AgentOrderByRelationAggregateInput>;
  bucket?: InputMaybe<SortOrder>;
  campaign?: InputMaybe<CampaignOrderByRelationAggregateInput>;
  campaign_action?: InputMaybe<Campaign_ActionOrderByRelationAggregateInput>;
  client_id?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrder>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientOrderByWithRelationInput>;
  contact?: InputMaybe<ContactOrderByRelationAggregateInput>;
  cron?: InputMaybe<CronOrderByRelationAggregateInput>;
  disabled?: InputMaybe<SortOrderInput>;
  dkim?: InputMaybe<SortOrderInput>;
  domain?: InputMaybe<SortOrder>;
  embed_domains?: InputMaybe<SortOrder>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  identity_pool_id?: InputMaybe<SortOrder>;
  lists?: InputMaybe<ListOrderByRelationAggregateInput>;
  mail_templates?: InputMaybe<Mail_TemplateOrderByRelationAggregateInput>;
  message?: InputMaybe<MessageOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrderInput>;
  parameter?: InputMaybe<ParameterOrderByRelationAggregateInput>;
  project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  survey_published?: InputMaybe<Survey_PublishedOrderByRelationAggregateInput>;
  survey_schema?: InputMaybe<Survey_SchemaOrderByRelationAggregateInput>;
  user_pool_id?: InputMaybe<SortOrder>;
};

export type ClientRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export enum ClientScalarFieldEnum {
  Bucket = 'bucket',
  ClientId = 'client_id',
  Config = 'config',
  Disabled = 'disabled',
  Dkim = 'dkim',
  Domain = 'domain',
  EmbedDomains = 'embed_domains',
  Id = 'id',
  IdentityPoolId = 'identity_pool_id',
  Name = 'name',
  UserPoolId = 'user_pool_id'
}

export type ClientScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ClientScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ClientScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ClientScalarWhereWithAggregatesInput>>;
  bucket?: InputMaybe<StringWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  config?: InputMaybe<JsonWithAggregatesFilter>;
  disabled?: InputMaybe<BoolNullableWithAggregatesFilter>;
  dkim?: InputMaybe<StringNullableWithAggregatesFilter>;
  domain?: InputMaybe<StringWithAggregatesFilter>;
  embed_domains?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  identity_pool_id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  user_pool_id?: InputMaybe<StringWithAggregatesFilter>;
};

export type ClientUpdateInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateManyMutationInput = {
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateOneRequiredWithoutAgentNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutAgentInput>;
  create?: InputMaybe<ClientCreateWithoutAgentInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutAgentInput>;
  upsert?: InputMaybe<ClientUpsertWithoutAgentInput>;
};

export type ClientUpdateOneRequiredWithoutCampaignNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<ClientCreateWithoutCampaignInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutCampaignInput>;
  upsert?: InputMaybe<ClientUpsertWithoutCampaignInput>;
};

export type ClientUpdateOneRequiredWithoutCampaign_ActionNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<ClientCreateWithoutCampaign_ActionInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutCampaign_ActionInput>;
  upsert?: InputMaybe<ClientUpsertWithoutCampaign_ActionInput>;
};

export type ClientUpdateOneRequiredWithoutConfig_Campaign_ClientNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutConfig_Campaign_ClientInput>;
  create?: InputMaybe<ClientCreateWithoutConfig_Campaign_ClientInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutConfig_Campaign_ClientInput>;
  upsert?: InputMaybe<ClientUpsertWithoutConfig_Campaign_ClientInput>;
};

export type ClientUpdateOneRequiredWithoutContactNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<ClientCreateWithoutContactInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutContactInput>;
  upsert?: InputMaybe<ClientUpsertWithoutContactInput>;
};

export type ClientUpdateOneRequiredWithoutCronNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCronInput>;
  create?: InputMaybe<ClientCreateWithoutCronInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutCronInput>;
  upsert?: InputMaybe<ClientUpsertWithoutCronInput>;
};

export type ClientUpdateOneRequiredWithoutFilesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ClientCreateWithoutFilesInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutFilesInput>;
};

export type ClientUpdateOneRequiredWithoutListsNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutListsInput>;
  create?: InputMaybe<ClientCreateWithoutListsInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutListsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutListsInput>;
};

export type ClientUpdateOneRequiredWithoutMail_TemplatesNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMail_TemplatesInput>;
  create?: InputMaybe<ClientCreateWithoutMail_TemplatesInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutMail_TemplatesInput>;
  upsert?: InputMaybe<ClientUpsertWithoutMail_TemplatesInput>;
};

export type ClientUpdateOneRequiredWithoutMessageNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<ClientCreateWithoutMessageInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutMessageInput>;
  upsert?: InputMaybe<ClientUpsertWithoutMessageInput>;
};

export type ClientUpdateOneRequiredWithoutProjectNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ClientCreateWithoutProjectInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutProjectInput>;
  upsert?: InputMaybe<ClientUpsertWithoutProjectInput>;
};

export type ClientUpdateOneRequiredWithoutSurvey_PublishedNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSurvey_PublishedInput>;
  create?: InputMaybe<ClientCreateWithoutSurvey_PublishedInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutSurvey_PublishedInput>;
  upsert?: InputMaybe<ClientUpsertWithoutSurvey_PublishedInput>;
};

export type ClientUpdateOneRequiredWithoutSurvey_SchemaNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSurvey_SchemaInput>;
  create?: InputMaybe<ClientCreateWithoutSurvey_SchemaInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutSurvey_SchemaInput>;
  upsert?: InputMaybe<ClientUpsertWithoutSurvey_SchemaInput>;
};

export type ClientUpdateOneWithoutParameterNestedInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutParameterInput>;
  create?: InputMaybe<ClientCreateWithoutParameterInput>;
  delete?: InputMaybe<ClientWhereInput>;
  disconnect?: InputMaybe<ClientWhereInput>;
  update?: InputMaybe<ClientUpdateToOneWithWhereWithoutParameterInput>;
  upsert?: InputMaybe<ClientUpsertWithoutParameterInput>;
};

export type ClientUpdateToOneWithWhereWithoutAgentInput = {
  data: ClientUpdateWithoutAgentInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutCampaignInput = {
  data: ClientUpdateWithoutCampaignInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutCampaign_ActionInput = {
  data: ClientUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutConfig_Campaign_ClientInput = {
  data: ClientUpdateWithoutConfig_Campaign_ClientInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutContactInput = {
  data: ClientUpdateWithoutContactInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutCronInput = {
  data: ClientUpdateWithoutCronInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutFilesInput = {
  data: ClientUpdateWithoutFilesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutListsInput = {
  data: ClientUpdateWithoutListsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutMail_TemplatesInput = {
  data: ClientUpdateWithoutMail_TemplatesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutMessageInput = {
  data: ClientUpdateWithoutMessageInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutParameterInput = {
  data: ClientUpdateWithoutParameterInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutProjectInput = {
  data: ClientUpdateWithoutProjectInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutSurvey_PublishedInput = {
  data: ClientUpdateWithoutSurvey_PublishedInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateToOneWithWhereWithoutSurvey_SchemaInput = {
  data: ClientUpdateWithoutSurvey_SchemaInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpdateWithoutAgentInput = {
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutCampaignInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutCampaign_ActionInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutConfig_Campaign_ClientInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutContactInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutCronInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutFilesInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutListsInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutMail_TemplatesInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutMessageInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutParameterInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutProjectInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutSurvey_PublishedInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutSurvey_SchemaInput = {
  agent?: InputMaybe<AgentUpdateManyWithoutClientNestedInput>;
  bucket?: InputMaybe<StringFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutClientNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutClientNestedInput>;
  client_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientUpdateOneWithoutClientNestedInput>;
  contact?: InputMaybe<ContactUpdateManyWithoutClientNestedInput>;
  cron?: InputMaybe<CronUpdateManyWithoutClientNestedInput>;
  disabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  dkim?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  embed_domains?: InputMaybe<ClientUpdateembed_DomainsInput>;
  files?: InputMaybe<FileUpdateManyWithoutClientNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  identity_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lists?: InputMaybe<ListUpdateManyWithoutClientNestedInput>;
  mail_templates?: InputMaybe<Mail_TemplateUpdateManyWithoutClientNestedInput>;
  message?: InputMaybe<MessageUpdateManyWithoutClientNestedInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parameter?: InputMaybe<ParameterUpdateManyWithoutClientNestedInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutClientNestedInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutClientNestedInput>;
  user_pool_id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpdateembed_DomainsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ClientUpsertWithoutAgentInput = {
  create: ClientCreateWithoutAgentInput;
  update: ClientUpdateWithoutAgentInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutCampaignInput = {
  create: ClientCreateWithoutCampaignInput;
  update: ClientUpdateWithoutCampaignInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutCampaign_ActionInput = {
  create: ClientCreateWithoutCampaign_ActionInput;
  update: ClientUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutConfig_Campaign_ClientInput = {
  create: ClientCreateWithoutConfig_Campaign_ClientInput;
  update: ClientUpdateWithoutConfig_Campaign_ClientInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutContactInput = {
  create: ClientCreateWithoutContactInput;
  update: ClientUpdateWithoutContactInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutCronInput = {
  create: ClientCreateWithoutCronInput;
  update: ClientUpdateWithoutCronInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutFilesInput = {
  create: ClientCreateWithoutFilesInput;
  update: ClientUpdateWithoutFilesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutListsInput = {
  create: ClientCreateWithoutListsInput;
  update: ClientUpdateWithoutListsInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutMail_TemplatesInput = {
  create: ClientCreateWithoutMail_TemplatesInput;
  update: ClientUpdateWithoutMail_TemplatesInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutMessageInput = {
  create: ClientCreateWithoutMessageInput;
  update: ClientUpdateWithoutMessageInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutParameterInput = {
  create: ClientCreateWithoutParameterInput;
  update: ClientUpdateWithoutParameterInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutProjectInput = {
  create: ClientCreateWithoutProjectInput;
  update: ClientUpdateWithoutProjectInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutSurvey_PublishedInput = {
  create: ClientCreateWithoutSurvey_PublishedInput;
  update: ClientUpdateWithoutSurvey_PublishedInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientUpsertWithoutSurvey_SchemaInput = {
  create: ClientCreateWithoutSurvey_SchemaInput;
  update: ClientUpdateWithoutSurvey_SchemaInput;
  where?: InputMaybe<ClientWhereInput>;
};

export type ClientWhereInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  agent?: InputMaybe<AgentListRelationFilter>;
  bucket?: InputMaybe<StringFilter>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  config?: InputMaybe<JsonFilter>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientNullableRelationFilter>;
  contact?: InputMaybe<ContactListRelationFilter>;
  cron?: InputMaybe<CronListRelationFilter>;
  disabled?: InputMaybe<BoolNullableFilter>;
  dkim?: InputMaybe<StringNullableFilter>;
  domain?: InputMaybe<StringFilter>;
  embed_domains?: InputMaybe<StringNullableListFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  identity_pool_id?: InputMaybe<StringFilter>;
  lists?: InputMaybe<ListListRelationFilter>;
  mail_templates?: InputMaybe<Mail_TemplateListRelationFilter>;
  message?: InputMaybe<MessageListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  parameter?: InputMaybe<ParameterListRelationFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  survey_published?: InputMaybe<Survey_PublishedListRelationFilter>;
  survey_schema?: InputMaybe<Survey_SchemaListRelationFilter>;
  user_pool_id?: InputMaybe<StringFilter>;
};

export type ClientWhereUniqueInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  agent?: InputMaybe<AgentListRelationFilter>;
  bucket?: InputMaybe<Scalars['String']['input']>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<JsonFilter>;
  config_campaign_client?: InputMaybe<Config_Campaign_ClientNullableRelationFilter>;
  contact?: InputMaybe<ContactListRelationFilter>;
  cron?: InputMaybe<CronListRelationFilter>;
  disabled?: InputMaybe<BoolNullableFilter>;
  dkim?: InputMaybe<StringNullableFilter>;
  domain?: InputMaybe<Scalars['String']['input']>;
  embed_domains?: InputMaybe<StringNullableListFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  identity_pool_id?: InputMaybe<Scalars['String']['input']>;
  lists?: InputMaybe<ListListRelationFilter>;
  mail_templates?: InputMaybe<Mail_TemplateListRelationFilter>;
  message?: InputMaybe<MessageListRelationFilter>;
  name?: InputMaybe<StringNullableFilter>;
  parameter?: InputMaybe<ParameterListRelationFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  survey_published?: InputMaybe<Survey_PublishedListRelationFilter>;
  survey_schema?: InputMaybe<Survey_SchemaListRelationFilter>;
  user_pool_id?: InputMaybe<StringFilter>;
  user_pool_id_client_id?: InputMaybe<ClientUser_Pool_IdClient_IdCompoundUniqueInput>;
};

/** Company User Group Type */
export type CompanyUserGroup = {
  __typename?: 'CompanyUserGroup';
  groups: Array<Groups>;
  users: Array<Users>;
};

export type CompleteMultipartUploadInput = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  contact_id: Scalars['String']['input'];
  duration: Scalars['Float']['input'];
  filename: Scalars['String']['input'];
  folder: Scalars['String']['input'];
  key: Scalars['String']['input'];
  parts: Array<PartInput>;
  project_id: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Float']['input']>;
  tags: Array<Tag_File>;
  thumbnailKey: Scalars['String']['input'];
  uploadId: Scalars['String']['input'];
};

export type Config_Campaign_Client = {
  __typename?: 'Config_campaign_client';
  client: Client;
  client_id: Scalars['String']['output'];
  config?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
};

export type Config_Campaign_ClientCountAggregate = {
  __typename?: 'Config_campaign_clientCountAggregate';
  _all: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  config: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type Config_Campaign_ClientCountOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type Config_Campaign_ClientCreateInput = {
  client: ClientCreateNestedOneWithoutConfig_Campaign_ClientInput;
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Config_Campaign_ClientCreateManyInput = {
  client_id: Scalars['String']['input'];
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Config_Campaign_ClientCreateNestedOneWithoutClientInput = {
  connect?: InputMaybe<Config_Campaign_ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Config_Campaign_ClientCreateOrConnectWithoutClientInput>;
  create?: InputMaybe<Config_Campaign_ClientCreateWithoutClientInput>;
};

export type Config_Campaign_ClientCreateOrConnectWithoutClientInput = {
  create: Config_Campaign_ClientCreateWithoutClientInput;
  where: Config_Campaign_ClientWhereUniqueInput;
};

export type Config_Campaign_ClientCreateWithoutClientInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Config_Campaign_ClientGroupBy = {
  __typename?: 'Config_campaign_clientGroupBy';
  _count?: Maybe<Config_Campaign_ClientCountAggregate>;
  _max?: Maybe<Config_Campaign_ClientMaxAggregate>;
  _min?: Maybe<Config_Campaign_ClientMinAggregate>;
  client_id: Scalars['String']['output'];
  config?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
};

export type Config_Campaign_ClientMaxAggregate = {
  __typename?: 'Config_campaign_clientMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Config_Campaign_ClientMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type Config_Campaign_ClientMinAggregate = {
  __typename?: 'Config_campaign_clientMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Config_Campaign_ClientMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type Config_Campaign_ClientNullableRelationFilter = {
  is?: InputMaybe<Config_Campaign_ClientWhereInput>;
  isNot?: InputMaybe<Config_Campaign_ClientWhereInput>;
};

export type Config_Campaign_ClientOrderByWithAggregationInput = {
  _count?: InputMaybe<Config_Campaign_ClientCountOrderByAggregateInput>;
  _max?: InputMaybe<Config_Campaign_ClientMaxOrderByAggregateInput>;
  _min?: InputMaybe<Config_Campaign_ClientMinOrderByAggregateInput>;
  client_id?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
};

export type Config_Campaign_ClientOrderByWithRelationInput = {
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
};

export enum Config_Campaign_ClientScalarFieldEnum {
  ClientId = 'client_id',
  Config = 'config',
  Id = 'id'
}

export type Config_Campaign_ClientScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Config_Campaign_ClientScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Config_Campaign_ClientScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Config_Campaign_ClientScalarWhereWithAggregatesInput>>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  config?: InputMaybe<JsonNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type Config_Campaign_ClientUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutConfig_Campaign_ClientNestedInput>;
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Config_Campaign_ClientUpdateManyMutationInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Config_Campaign_ClientUpdateOneWithoutClientNestedInput = {
  connect?: InputMaybe<Config_Campaign_ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Config_Campaign_ClientCreateOrConnectWithoutClientInput>;
  create?: InputMaybe<Config_Campaign_ClientCreateWithoutClientInput>;
  delete?: InputMaybe<Config_Campaign_ClientWhereInput>;
  disconnect?: InputMaybe<Config_Campaign_ClientWhereInput>;
  update?: InputMaybe<Config_Campaign_ClientUpdateToOneWithWhereWithoutClientInput>;
  upsert?: InputMaybe<Config_Campaign_ClientUpsertWithoutClientInput>;
};

export type Config_Campaign_ClientUpdateToOneWithWhereWithoutClientInput = {
  data: Config_Campaign_ClientUpdateWithoutClientInput;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};

export type Config_Campaign_ClientUpdateWithoutClientInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Config_Campaign_ClientUpsertWithoutClientInput = {
  create: Config_Campaign_ClientCreateWithoutClientInput;
  update: Config_Campaign_ClientUpdateWithoutClientInput;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};

export type Config_Campaign_ClientWhereInput = {
  AND?: InputMaybe<Array<Config_Campaign_ClientWhereInput>>;
  NOT?: InputMaybe<Array<Config_Campaign_ClientWhereInput>>;
  OR?: InputMaybe<Array<Config_Campaign_ClientWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  config?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<UuidFilter>;
};

export type Config_Campaign_ClientWhereUniqueInput = {
  AND?: InputMaybe<Array<Config_Campaign_ClientWhereInput>>;
  NOT?: InputMaybe<Array<Config_Campaign_ClientWhereInput>>;
  OR?: InputMaybe<Array<Config_Campaign_ClientWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Config_Global = {
  __typename?: 'Config_global';
  config?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  name: Config_Name;
};

export type Config_GlobalCountAggregate = {
  __typename?: 'Config_globalCountAggregate';
  _all: Scalars['Int']['output'];
  config: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
};

export type Config_GlobalCountOrderByAggregateInput = {
  config?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type Config_GlobalCreateInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Config_Name;
};

export type Config_GlobalCreateManyInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Config_Name;
};

export type Config_GlobalGroupBy = {
  __typename?: 'Config_globalGroupBy';
  _count?: Maybe<Config_GlobalCountAggregate>;
  _max?: Maybe<Config_GlobalMaxAggregate>;
  _min?: Maybe<Config_GlobalMinAggregate>;
  config?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  name: Config_Name;
};

export type Config_GlobalMaxAggregate = {
  __typename?: 'Config_globalMaxAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Config_Name>;
};

export type Config_GlobalMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type Config_GlobalMinAggregate = {
  __typename?: 'Config_globalMinAggregate';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Config_Name>;
};

export type Config_GlobalMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type Config_GlobalOrderByWithAggregationInput = {
  _count?: InputMaybe<Config_GlobalCountOrderByAggregateInput>;
  _max?: InputMaybe<Config_GlobalMaxOrderByAggregateInput>;
  _min?: InputMaybe<Config_GlobalMinOrderByAggregateInput>;
  config?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type Config_GlobalOrderByWithRelationInput = {
  config?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export enum Config_GlobalScalarFieldEnum {
  Config = 'config',
  Id = 'id',
  Name = 'name'
}

export type Config_GlobalScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Config_GlobalScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Config_GlobalScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Config_GlobalScalarWhereWithAggregatesInput>>;
  config?: InputMaybe<JsonNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<Enumconfig_NameWithAggregatesFilter>;
};

export type Config_GlobalUpdateInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<Enumconfig_NameFieldUpdateOperationsInput>;
};

export type Config_GlobalUpdateManyMutationInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<Enumconfig_NameFieldUpdateOperationsInput>;
};

export type Config_GlobalWhereInput = {
  AND?: InputMaybe<Array<Config_GlobalWhereInput>>;
  NOT?: InputMaybe<Array<Config_GlobalWhereInput>>;
  OR?: InputMaybe<Array<Config_GlobalWhereInput>>;
  config?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<Enumconfig_NameFilter>;
};

export type Config_GlobalWhereUniqueInput = {
  AND?: InputMaybe<Array<Config_GlobalWhereInput>>;
  NOT?: InputMaybe<Array<Config_GlobalWhereInput>>;
  OR?: InputMaybe<Array<Config_GlobalWhereInput>>;
  config?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Config_Name>;
};

export type Contact = {
  __typename?: 'Contact';
  _count?: Maybe<ContactCount>;
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  archive_date?: Maybe<Scalars['DateTimeISO']['output']>;
  campaign: Array<Campaign>;
  city?: Maybe<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  company_name?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  ext_import_id?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  firstname?: Maybe<Scalars['String']['output']>;
  formal?: Maybe<Formal>;
  id: Scalars['String']['output'];
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  project: Array<Project>;
  salutation?: Maybe<Salutation>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type ContactAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type ContactCampaignArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type ContactCountryArgs = {
  where?: InputMaybe<CountryWhereInput>;
};


export type ContactFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type ContactProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};

export type ContactCount = {
  __typename?: 'ContactCount';
  campaign: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
  project: Scalars['Int']['output'];
};


export type ContactCountCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type ContactCountFilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type ContactCountProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};

export type ContactCountAggregate = {
  __typename?: 'ContactCountAggregate';
  _all: Scalars['Int']['output'];
  agent_id: Scalars['Int']['output'];
  archive_date: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  company_name: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  ext_import_id: Scalars['Int']['output'];
  firstname: Scalars['Int']['output'];
  formal: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  iso_3166_1: Scalars['Int']['output'];
  lastname: Scalars['Int']['output'];
  mobile: Scalars['Int']['output'];
  note: Scalars['Int']['output'];
  phone: Scalars['Int']['output'];
  salutation: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
  zip: Scalars['Int']['output'];
};

export type ContactCountOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  archive_date?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  company_name?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  ext_import_id?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  formal?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  mobile?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ContactCreateInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutContactInput>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutContactInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutContactInput;
  company_name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCreateNestedOneWithoutContactInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutContactInput>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateManyAgentInput = {
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  company_name?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateManyAgentInputEnvelope = {
  data: Array<ContactCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactCreateManyClientInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateManyClientInputEnvelope = {
  data: Array<ContactCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactCreateManyCountryInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  company_name?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateManyCountryInputEnvelope = {
  data: Array<ContactCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContactCreateManyInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  company_name?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutAgentInput>>;
  createMany?: InputMaybe<ContactCreateManyAgentInputEnvelope>;
};

export type ContactCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutClientInput>>;
  createMany?: InputMaybe<ContactCreateManyClientInputEnvelope>;
};

export type ContactCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ContactCreateManyCountryInputEnvelope>;
};

export type ContactCreateNestedOneWithoutCampaignInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<ContactCreateWithoutCampaignInput>;
};

export type ContactCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ContactCreateWithoutFilesInput>;
};

export type ContactCreateNestedOneWithoutProjectInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ContactCreateWithoutProjectInput>;
};

export type ContactCreateOrConnectWithoutAgentInput = {
  create: ContactCreateWithoutAgentInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutCampaignInput = {
  create: ContactCreateWithoutCampaignInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutClientInput = {
  create: ContactCreateWithoutClientInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutCountryInput = {
  create: ContactCreateWithoutCountryInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutFilesInput = {
  create: ContactCreateWithoutFilesInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutProjectInput = {
  create: ContactCreateWithoutProjectInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateWithoutAgentInput = {
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutContactInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutContactInput;
  company_name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCreateNestedOneWithoutContactInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutContactInput>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutCampaignInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutContactInput>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutContactInput;
  company_name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCreateNestedOneWithoutContactInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutContactInput>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutClientInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutContactInput>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutContactInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  company_name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCreateNestedOneWithoutContactInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutContactInput>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutCountryInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutContactInput>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutContactInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutContactInput;
  company_name?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutContactInput>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutFilesInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutContactInput>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutContactInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutContactInput;
  company_name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCreateNestedOneWithoutContactInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutContactInput>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactCreateWithoutProjectInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutContactInput>;
  archive_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutContactInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutContactInput;
  company_name?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryCreateNestedOneWithoutContactInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  email: Scalars['String']['input'];
  ext_import_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  formal?: InputMaybe<Formal>;
  id?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Salutation>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ContactGroupBy = {
  __typename?: 'ContactGroupBy';
  _count?: Maybe<ContactCountAggregate>;
  _max?: Maybe<ContactMaxAggregate>;
  _min?: Maybe<ContactMinAggregate>;
  agent_id?: Maybe<Scalars['String']['output']>;
  archive_date?: Maybe<Scalars['DateTimeISO']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_id: Scalars['String']['output'];
  company_name?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  ext_import_id?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  formal?: Maybe<Formal>;
  id: Scalars['String']['output'];
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Salutation>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ContactListRelationFilter = {
  every?: InputMaybe<ContactWhereInput>;
  none?: InputMaybe<ContactWhereInput>;
  some?: InputMaybe<ContactWhereInput>;
};

export type ContactMaxAggregate = {
  __typename?: 'ContactMaxAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  archive_date?: Maybe<Scalars['DateTimeISO']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  ext_import_id?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  formal?: Maybe<Formal>;
  id?: Maybe<Scalars['String']['output']>;
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Salutation>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ContactMaxOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  archive_date?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  company_name?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  ext_import_id?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  formal?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  mobile?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ContactMinAggregate = {
  __typename?: 'ContactMinAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  archive_date?: Maybe<Scalars['DateTimeISO']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  ext_import_id?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  formal?: Maybe<Formal>;
  id?: Maybe<Scalars['String']['output']>;
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Salutation>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ContactMinOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  archive_date?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  company_name?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  ext_import_id?: InputMaybe<SortOrder>;
  firstname?: InputMaybe<SortOrder>;
  formal?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  lastname?: InputMaybe<SortOrder>;
  mobile?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  salutation?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ContactNullableRelationFilter = {
  is?: InputMaybe<ContactWhereInput>;
  isNot?: InputMaybe<ContactWhereInput>;
};

export type ContactOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ContactOrderByWithAggregationInput = {
  _count?: InputMaybe<ContactCountOrderByAggregateInput>;
  _max?: InputMaybe<ContactMaxOrderByAggregateInput>;
  _min?: InputMaybe<ContactMinOrderByAggregateInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  archive_date?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  client_id?: InputMaybe<SortOrder>;
  company_name?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  ext_import_id?: InputMaybe<SortOrderInput>;
  firstname?: InputMaybe<SortOrderInput>;
  formal?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrderInput>;
  lastname?: InputMaybe<SortOrderInput>;
  mobile?: InputMaybe<SortOrderInput>;
  note?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  salutation?: InputMaybe<SortOrderInput>;
  street?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type ContactOrderByWithRelationInput = {
  agent?: InputMaybe<AgentOrderByWithRelationInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  archive_date?: InputMaybe<SortOrderInput>;
  campaign?: InputMaybe<CampaignOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  company_name?: InputMaybe<SortOrderInput>;
  country?: InputMaybe<CountryOrderByWithRelationInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrder>;
  ext_import_id?: InputMaybe<SortOrderInput>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  firstname?: InputMaybe<SortOrderInput>;
  formal?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrderInput>;
  lastname?: InputMaybe<SortOrderInput>;
  mobile?: InputMaybe<SortOrderInput>;
  note?: InputMaybe<SortOrderInput>;
  phone?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  salutation?: InputMaybe<SortOrderInput>;
  street?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type ContactRelationFilter = {
  is?: InputMaybe<ContactWhereInput>;
  isNot?: InputMaybe<ContactWhereInput>;
};

export enum ContactScalarFieldEnum {
  AgentId = 'agent_id',
  ArchiveDate = 'archive_date',
  City = 'city',
  ClientId = 'client_id',
  CompanyName = 'company_name',
  Created = 'created',
  Deleted = 'deleted',
  Email = 'email',
  ExtImportId = 'ext_import_id',
  Firstname = 'firstname',
  Formal = 'formal',
  Id = 'id',
  Iso_3166_1 = 'iso_3166_1',
  Lastname = 'lastname',
  Mobile = 'mobile',
  Note = 'note',
  Phone = 'phone',
  Salutation = 'salutation',
  Street = 'street',
  Updated = 'updated',
  Zip = 'zip'
}

export type ContactScalarWhereInput = {
  AND?: InputMaybe<Array<ContactScalarWhereInput>>;
  NOT?: InputMaybe<Array<ContactScalarWhereInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereInput>>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  archive_date?: InputMaybe<DateTimeNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  client_id?: InputMaybe<StringFilter>;
  company_name?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  ext_import_id?: InputMaybe<StringNullableFilter>;
  firstname?: InputMaybe<StringNullableFilter>;
  formal?: InputMaybe<EnumformalNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  iso_3166_1?: InputMaybe<StringNullableFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  mobile?: InputMaybe<StringNullableFilter>;
  note?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  salutation?: InputMaybe<EnumsalutationNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ContactScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ContactScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ContactScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ContactScalarWhereWithAggregatesInput>>;
  agent_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  archive_date?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  company_name?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  email?: InputMaybe<StringWithAggregatesFilter>;
  ext_import_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  firstname?: InputMaybe<StringNullableWithAggregatesFilter>;
  formal?: InputMaybe<EnumformalNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  iso_3166_1?: InputMaybe<StringNullableWithAggregatesFilter>;
  lastname?: InputMaybe<StringNullableWithAggregatesFilter>;
  mobile?: InputMaybe<StringNullableWithAggregatesFilter>;
  note?: InputMaybe<StringNullableWithAggregatesFilter>;
  phone?: InputMaybe<StringNullableWithAggregatesFilter>;
  salutation?: InputMaybe<EnumsalutationNullableWithAggregatesFilter>;
  street?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  zip?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type ContactUpdateInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutContactNestedInput>;
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutContactNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContactNestedInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneWithoutContactNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutContactNestedInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutContactNestedInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateManyMutationInput = {
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateManyWithWhereWithoutAgentInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithWhereWithoutClientInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithWhereWithoutCountryInput = {
  data: ContactUpdateManyMutationInput;
  where: ContactScalarWhereInput;
};

export type ContactUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutAgentInput>>;
  createMany?: InputMaybe<ContactCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutAgentInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type ContactUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutClientInput>>;
  createMany?: InputMaybe<ContactCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ContactUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ContactCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<ContactCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ContactCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ContactScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ContactWhereUniqueInput>>;
  set?: InputMaybe<Array<ContactWhereUniqueInput>>;
  update?: InputMaybe<Array<ContactUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<ContactUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<ContactUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type ContactUpdateOneRequiredWithoutCampaignNestedInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<ContactCreateWithoutCampaignInput>;
  update?: InputMaybe<ContactUpdateToOneWithWhereWithoutCampaignInput>;
  upsert?: InputMaybe<ContactUpsertWithoutCampaignInput>;
};

export type ContactUpdateOneRequiredWithoutProjectNestedInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<ContactCreateWithoutProjectInput>;
  update?: InputMaybe<ContactUpdateToOneWithWhereWithoutProjectInput>;
  upsert?: InputMaybe<ContactUpsertWithoutProjectInput>;
};

export type ContactUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ContactCreateWithoutFilesInput>;
  delete?: InputMaybe<ContactWhereInput>;
  disconnect?: InputMaybe<ContactWhereInput>;
  update?: InputMaybe<ContactUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<ContactUpsertWithoutFilesInput>;
};

export type ContactUpdateToOneWithWhereWithoutCampaignInput = {
  data: ContactUpdateWithoutCampaignInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpdateToOneWithWhereWithoutFilesInput = {
  data: ContactUpdateWithoutFilesInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpdateToOneWithWhereWithoutProjectInput = {
  data: ContactUpdateWithoutProjectInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpdateWithWhereUniqueWithoutAgentInput = {
  data: ContactUpdateWithoutAgentInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithWhereUniqueWithoutClientInput = {
  data: ContactUpdateWithoutClientInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithWhereUniqueWithoutCountryInput = {
  data: ContactUpdateWithoutCountryInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpdateWithoutAgentInput = {
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutContactNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContactNestedInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneWithoutContactNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutContactNestedInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutContactNestedInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutCampaignInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutContactNestedInput>;
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContactNestedInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneWithoutContactNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutContactNestedInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutContactNestedInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutClientInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutContactNestedInput>;
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutContactNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneWithoutContactNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutContactNestedInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutContactNestedInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutCountryInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutContactNestedInput>;
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutContactNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContactNestedInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutContactNestedInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutContactNestedInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutFilesInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutContactNestedInput>;
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutContactNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContactNestedInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneWithoutContactNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutContactNestedInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutProjectInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutContactNestedInput>;
  archive_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateManyWithoutContactNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutContactNestedInput>;
  company_name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<CountryUpdateOneWithoutContactNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  ext_import_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutContactNestedInput>;
  firstname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  formal?: InputMaybe<NullableEnumformalFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastname?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mobile?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  salutation?: InputMaybe<NullableEnumsalutationFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ContactUpsertWithWhereUniqueWithoutAgentInput = {
  create: ContactCreateWithoutAgentInput;
  update: ContactUpdateWithoutAgentInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithWhereUniqueWithoutClientInput = {
  create: ContactCreateWithoutClientInput;
  update: ContactUpdateWithoutClientInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithWhereUniqueWithoutCountryInput = {
  create: ContactCreateWithoutCountryInput;
  update: ContactUpdateWithoutCountryInput;
  where: ContactWhereUniqueInput;
};

export type ContactUpsertWithoutCampaignInput = {
  create: ContactCreateWithoutCampaignInput;
  update: ContactUpdateWithoutCampaignInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpsertWithoutFilesInput = {
  create: ContactCreateWithoutFilesInput;
  update: ContactUpdateWithoutFilesInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactUpsertWithoutProjectInput = {
  create: ContactCreateWithoutProjectInput;
  update: ContactUpdateWithoutProjectInput;
  where?: InputMaybe<ContactWhereInput>;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  archive_date?: InputMaybe<DateTimeNullableFilter>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  company_name?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<CountryNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  ext_import_id?: InputMaybe<StringNullableFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  firstname?: InputMaybe<StringNullableFilter>;
  formal?: InputMaybe<EnumformalNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  iso_3166_1?: InputMaybe<StringNullableFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  mobile?: InputMaybe<StringNullableFilter>;
  note?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  salutation?: InputMaybe<EnumsalutationNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ContactWhereUniqueInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  archive_date?: InputMaybe<DateTimeNullableFilter>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  company_name?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<CountryNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringFilter>;
  email_client_id?: InputMaybe<ContactEmailClient_IdCompoundUniqueInput>;
  ext_import_id?: InputMaybe<StringNullableFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  firstname?: InputMaybe<StringNullableFilter>;
  formal?: InputMaybe<EnumformalNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<StringNullableFilter>;
  lastname?: InputMaybe<StringNullableFilter>;
  mobile?: InputMaybe<StringNullableFilter>;
  note?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  salutation?: InputMaybe<EnumsalutationNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type Country = {
  __typename?: 'Country';
  _count?: Maybe<CountryCount>;
  contact: Array<Contact>;
  country_de: Scalars['String']['output'];
  country_en: Scalars['String']['output'];
  dialing_prefix?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  fifa?: Maybe<Scalars['String']['output']>;
  fips10?: Maybe<Scalars['String']['output']>;
  ioc?: Maybe<Scalars['String']['output']>;
  iso_3166_1: Scalars['String']['output'];
  iso_3166_1_alpha3: Scalars['String']['output'];
  iso_3166_1_num?: Maybe<Scalars['Int']['output']>;
  itu?: Maybe<Scalars['String']['output']>;
  maritime?: Maybe<Scalars['String']['output']>;
  mmc?: Maybe<Scalars['String']['output']>;
  project: Array<Project>;
  stanag_1059?: Maybe<Scalars['String']['output']>;
  uic?: Maybe<Scalars['String']['output']>;
  un_locode?: Maybe<Scalars['String']['output']>;
  vehicle_code?: Maybe<Scalars['String']['output']>;
};


export type CountryContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type CountryProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};

export type CountryAvgAggregate = {
  __typename?: 'CountryAvgAggregate';
  iso_3166_1_num?: Maybe<Scalars['Float']['output']>;
};

export type CountryAvgOrderByAggregateInput = {
  iso_3166_1_num?: InputMaybe<SortOrder>;
};

export type CountryCount = {
  __typename?: 'CountryCount';
  contact: Scalars['Int']['output'];
  project: Scalars['Int']['output'];
};


export type CountryCountContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


export type CountryCountProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};

export type CountryCountAggregate = {
  __typename?: 'CountryCountAggregate';
  _all: Scalars['Int']['output'];
  country_de: Scalars['Int']['output'];
  country_en: Scalars['Int']['output'];
  dialing_prefix: Scalars['Int']['output'];
  domain: Scalars['Int']['output'];
  fifa: Scalars['Int']['output'];
  fips10: Scalars['Int']['output'];
  ioc: Scalars['Int']['output'];
  iso_3166_1: Scalars['Int']['output'];
  iso_3166_1_alpha3: Scalars['Int']['output'];
  iso_3166_1_num: Scalars['Int']['output'];
  itu: Scalars['Int']['output'];
  maritime: Scalars['Int']['output'];
  mmc: Scalars['Int']['output'];
  stanag_1059: Scalars['Int']['output'];
  uic: Scalars['Int']['output'];
  un_locode: Scalars['Int']['output'];
  vehicle_code: Scalars['Int']['output'];
};

export type CountryCountOrderByAggregateInput = {
  country_de?: InputMaybe<SortOrder>;
  country_en?: InputMaybe<SortOrder>;
  dialing_prefix?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  fifa?: InputMaybe<SortOrder>;
  fips10?: InputMaybe<SortOrder>;
  ioc?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  iso_3166_1_alpha3?: InputMaybe<SortOrder>;
  iso_3166_1_num?: InputMaybe<SortOrder>;
  itu?: InputMaybe<SortOrder>;
  maritime?: InputMaybe<SortOrder>;
  mmc?: InputMaybe<SortOrder>;
  stanag_1059?: InputMaybe<SortOrder>;
  uic?: InputMaybe<SortOrder>;
  un_locode?: InputMaybe<SortOrder>;
  vehicle_code?: InputMaybe<SortOrder>;
};

export type CountryCreateInput = {
  contact?: InputMaybe<ContactCreateNestedManyWithoutCountryInput>;
  country_de: Scalars['String']['input'];
  country_en: Scalars['String']['input'];
  dialing_prefix?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  fifa?: InputMaybe<Scalars['String']['input']>;
  fips10?: InputMaybe<Scalars['String']['input']>;
  ioc?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1: Scalars['String']['input'];
  iso_3166_1_alpha3: Scalars['String']['input'];
  iso_3166_1_num?: InputMaybe<Scalars['Int']['input']>;
  itu?: InputMaybe<Scalars['String']['input']>;
  maritime?: InputMaybe<Scalars['String']['input']>;
  mmc?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutCountryInput>;
  stanag_1059?: InputMaybe<Scalars['String']['input']>;
  uic?: InputMaybe<Scalars['String']['input']>;
  un_locode?: InputMaybe<Scalars['String']['input']>;
  vehicle_code?: InputMaybe<Scalars['String']['input']>;
};

export type CountryCreateManyInput = {
  country_de: Scalars['String']['input'];
  country_en: Scalars['String']['input'];
  dialing_prefix?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  fifa?: InputMaybe<Scalars['String']['input']>;
  fips10?: InputMaybe<Scalars['String']['input']>;
  ioc?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1: Scalars['String']['input'];
  iso_3166_1_alpha3: Scalars['String']['input'];
  iso_3166_1_num?: InputMaybe<Scalars['Int']['input']>;
  itu?: InputMaybe<Scalars['String']['input']>;
  maritime?: InputMaybe<Scalars['String']['input']>;
  mmc?: InputMaybe<Scalars['String']['input']>;
  stanag_1059?: InputMaybe<Scalars['String']['input']>;
  uic?: InputMaybe<Scalars['String']['input']>;
  un_locode?: InputMaybe<Scalars['String']['input']>;
  vehicle_code?: InputMaybe<Scalars['String']['input']>;
};

export type CountryCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<CountryCreateWithoutContactInput>;
};

export type CountryCreateNestedOneWithoutProjectInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<CountryCreateWithoutProjectInput>;
};

export type CountryCreateOrConnectWithoutContactInput = {
  create: CountryCreateWithoutContactInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateOrConnectWithoutProjectInput = {
  create: CountryCreateWithoutProjectInput;
  where: CountryWhereUniqueInput;
};

export type CountryCreateWithoutContactInput = {
  country_de: Scalars['String']['input'];
  country_en: Scalars['String']['input'];
  dialing_prefix?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  fifa?: InputMaybe<Scalars['String']['input']>;
  fips10?: InputMaybe<Scalars['String']['input']>;
  ioc?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1: Scalars['String']['input'];
  iso_3166_1_alpha3: Scalars['String']['input'];
  iso_3166_1_num?: InputMaybe<Scalars['Int']['input']>;
  itu?: InputMaybe<Scalars['String']['input']>;
  maritime?: InputMaybe<Scalars['String']['input']>;
  mmc?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedManyWithoutCountryInput>;
  stanag_1059?: InputMaybe<Scalars['String']['input']>;
  uic?: InputMaybe<Scalars['String']['input']>;
  un_locode?: InputMaybe<Scalars['String']['input']>;
  vehicle_code?: InputMaybe<Scalars['String']['input']>;
};

export type CountryCreateWithoutProjectInput = {
  contact?: InputMaybe<ContactCreateNestedManyWithoutCountryInput>;
  country_de: Scalars['String']['input'];
  country_en: Scalars['String']['input'];
  dialing_prefix?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  fifa?: InputMaybe<Scalars['String']['input']>;
  fips10?: InputMaybe<Scalars['String']['input']>;
  ioc?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1: Scalars['String']['input'];
  iso_3166_1_alpha3: Scalars['String']['input'];
  iso_3166_1_num?: InputMaybe<Scalars['Int']['input']>;
  itu?: InputMaybe<Scalars['String']['input']>;
  maritime?: InputMaybe<Scalars['String']['input']>;
  mmc?: InputMaybe<Scalars['String']['input']>;
  stanag_1059?: InputMaybe<Scalars['String']['input']>;
  uic?: InputMaybe<Scalars['String']['input']>;
  un_locode?: InputMaybe<Scalars['String']['input']>;
  vehicle_code?: InputMaybe<Scalars['String']['input']>;
};

export type CountryGroupBy = {
  __typename?: 'CountryGroupBy';
  _avg?: Maybe<CountryAvgAggregate>;
  _count?: Maybe<CountryCountAggregate>;
  _max?: Maybe<CountryMaxAggregate>;
  _min?: Maybe<CountryMinAggregate>;
  _sum?: Maybe<CountrySumAggregate>;
  country_de: Scalars['String']['output'];
  country_en: Scalars['String']['output'];
  dialing_prefix?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  fifa?: Maybe<Scalars['String']['output']>;
  fips10?: Maybe<Scalars['String']['output']>;
  ioc?: Maybe<Scalars['String']['output']>;
  iso_3166_1: Scalars['String']['output'];
  iso_3166_1_alpha3: Scalars['String']['output'];
  iso_3166_1_num?: Maybe<Scalars['Int']['output']>;
  itu?: Maybe<Scalars['String']['output']>;
  maritime?: Maybe<Scalars['String']['output']>;
  mmc?: Maybe<Scalars['String']['output']>;
  stanag_1059?: Maybe<Scalars['String']['output']>;
  uic?: Maybe<Scalars['String']['output']>;
  un_locode?: Maybe<Scalars['String']['output']>;
  vehicle_code?: Maybe<Scalars['String']['output']>;
};

export type CountryMaxAggregate = {
  __typename?: 'CountryMaxAggregate';
  country_de?: Maybe<Scalars['String']['output']>;
  country_en?: Maybe<Scalars['String']['output']>;
  dialing_prefix?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  fifa?: Maybe<Scalars['String']['output']>;
  fips10?: Maybe<Scalars['String']['output']>;
  ioc?: Maybe<Scalars['String']['output']>;
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  iso_3166_1_alpha3?: Maybe<Scalars['String']['output']>;
  iso_3166_1_num?: Maybe<Scalars['Int']['output']>;
  itu?: Maybe<Scalars['String']['output']>;
  maritime?: Maybe<Scalars['String']['output']>;
  mmc?: Maybe<Scalars['String']['output']>;
  stanag_1059?: Maybe<Scalars['String']['output']>;
  uic?: Maybe<Scalars['String']['output']>;
  un_locode?: Maybe<Scalars['String']['output']>;
  vehicle_code?: Maybe<Scalars['String']['output']>;
};

export type CountryMaxOrderByAggregateInput = {
  country_de?: InputMaybe<SortOrder>;
  country_en?: InputMaybe<SortOrder>;
  dialing_prefix?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  fifa?: InputMaybe<SortOrder>;
  fips10?: InputMaybe<SortOrder>;
  ioc?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  iso_3166_1_alpha3?: InputMaybe<SortOrder>;
  iso_3166_1_num?: InputMaybe<SortOrder>;
  itu?: InputMaybe<SortOrder>;
  maritime?: InputMaybe<SortOrder>;
  mmc?: InputMaybe<SortOrder>;
  stanag_1059?: InputMaybe<SortOrder>;
  uic?: InputMaybe<SortOrder>;
  un_locode?: InputMaybe<SortOrder>;
  vehicle_code?: InputMaybe<SortOrder>;
};

export type CountryMinAggregate = {
  __typename?: 'CountryMinAggregate';
  country_de?: Maybe<Scalars['String']['output']>;
  country_en?: Maybe<Scalars['String']['output']>;
  dialing_prefix?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  fifa?: Maybe<Scalars['String']['output']>;
  fips10?: Maybe<Scalars['String']['output']>;
  ioc?: Maybe<Scalars['String']['output']>;
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  iso_3166_1_alpha3?: Maybe<Scalars['String']['output']>;
  iso_3166_1_num?: Maybe<Scalars['Int']['output']>;
  itu?: Maybe<Scalars['String']['output']>;
  maritime?: Maybe<Scalars['String']['output']>;
  mmc?: Maybe<Scalars['String']['output']>;
  stanag_1059?: Maybe<Scalars['String']['output']>;
  uic?: Maybe<Scalars['String']['output']>;
  un_locode?: Maybe<Scalars['String']['output']>;
  vehicle_code?: Maybe<Scalars['String']['output']>;
};

export type CountryMinOrderByAggregateInput = {
  country_de?: InputMaybe<SortOrder>;
  country_en?: InputMaybe<SortOrder>;
  dialing_prefix?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  fifa?: InputMaybe<SortOrder>;
  fips10?: InputMaybe<SortOrder>;
  ioc?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  iso_3166_1_alpha3?: InputMaybe<SortOrder>;
  iso_3166_1_num?: InputMaybe<SortOrder>;
  itu?: InputMaybe<SortOrder>;
  maritime?: InputMaybe<SortOrder>;
  mmc?: InputMaybe<SortOrder>;
  stanag_1059?: InputMaybe<SortOrder>;
  uic?: InputMaybe<SortOrder>;
  un_locode?: InputMaybe<SortOrder>;
  vehicle_code?: InputMaybe<SortOrder>;
};

export type CountryNullableRelationFilter = {
  is?: InputMaybe<CountryWhereInput>;
  isNot?: InputMaybe<CountryWhereInput>;
};

export type CountryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CountryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CountryCountOrderByAggregateInput>;
  _max?: InputMaybe<CountryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CountryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CountrySumOrderByAggregateInput>;
  country_de?: InputMaybe<SortOrder>;
  country_en?: InputMaybe<SortOrder>;
  dialing_prefix?: InputMaybe<SortOrderInput>;
  domain?: InputMaybe<SortOrder>;
  fifa?: InputMaybe<SortOrderInput>;
  fips10?: InputMaybe<SortOrderInput>;
  ioc?: InputMaybe<SortOrderInput>;
  iso_3166_1?: InputMaybe<SortOrder>;
  iso_3166_1_alpha3?: InputMaybe<SortOrder>;
  iso_3166_1_num?: InputMaybe<SortOrderInput>;
  itu?: InputMaybe<SortOrderInput>;
  maritime?: InputMaybe<SortOrderInput>;
  mmc?: InputMaybe<SortOrderInput>;
  stanag_1059?: InputMaybe<SortOrderInput>;
  uic?: InputMaybe<SortOrderInput>;
  un_locode?: InputMaybe<SortOrderInput>;
  vehicle_code?: InputMaybe<SortOrderInput>;
};

export type CountryOrderByWithRelationInput = {
  contact?: InputMaybe<ContactOrderByRelationAggregateInput>;
  country_de?: InputMaybe<SortOrder>;
  country_en?: InputMaybe<SortOrder>;
  dialing_prefix?: InputMaybe<SortOrderInput>;
  domain?: InputMaybe<SortOrder>;
  fifa?: InputMaybe<SortOrderInput>;
  fips10?: InputMaybe<SortOrderInput>;
  ioc?: InputMaybe<SortOrderInput>;
  iso_3166_1?: InputMaybe<SortOrder>;
  iso_3166_1_alpha3?: InputMaybe<SortOrder>;
  iso_3166_1_num?: InputMaybe<SortOrderInput>;
  itu?: InputMaybe<SortOrderInput>;
  maritime?: InputMaybe<SortOrderInput>;
  mmc?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByRelationAggregateInput>;
  stanag_1059?: InputMaybe<SortOrderInput>;
  uic?: InputMaybe<SortOrderInput>;
  un_locode?: InputMaybe<SortOrderInput>;
  vehicle_code?: InputMaybe<SortOrderInput>;
};

export enum CountryScalarFieldEnum {
  CountryDe = 'country_de',
  CountryEn = 'country_en',
  DialingPrefix = 'dialing_prefix',
  Domain = 'domain',
  Fifa = 'fifa',
  Fips10 = 'fips10',
  Ioc = 'ioc',
  Iso_3166_1 = 'iso_3166_1',
  Iso_3166_1Alpha3 = 'iso_3166_1_alpha3',
  Iso_3166_1Num = 'iso_3166_1_num',
  Itu = 'itu',
  Maritime = 'maritime',
  Mmc = 'mmc',
  Stanag_1059 = 'stanag_1059',
  Uic = 'uic',
  UnLocode = 'un_locode',
  VehicleCode = 'vehicle_code'
}

export type CountryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CountryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CountryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CountryScalarWhereWithAggregatesInput>>;
  country_de?: InputMaybe<StringWithAggregatesFilter>;
  country_en?: InputMaybe<StringWithAggregatesFilter>;
  dialing_prefix?: InputMaybe<StringNullableWithAggregatesFilter>;
  domain?: InputMaybe<StringWithAggregatesFilter>;
  fifa?: InputMaybe<StringNullableWithAggregatesFilter>;
  fips10?: InputMaybe<StringNullableWithAggregatesFilter>;
  ioc?: InputMaybe<StringNullableWithAggregatesFilter>;
  iso_3166_1?: InputMaybe<StringWithAggregatesFilter>;
  iso_3166_1_alpha3?: InputMaybe<StringWithAggregatesFilter>;
  iso_3166_1_num?: InputMaybe<IntNullableWithAggregatesFilter>;
  itu?: InputMaybe<StringNullableWithAggregatesFilter>;
  maritime?: InputMaybe<StringNullableWithAggregatesFilter>;
  mmc?: InputMaybe<StringNullableWithAggregatesFilter>;
  stanag_1059?: InputMaybe<StringNullableWithAggregatesFilter>;
  uic?: InputMaybe<StringNullableWithAggregatesFilter>;
  un_locode?: InputMaybe<StringNullableWithAggregatesFilter>;
  vehicle_code?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type CountrySumAggregate = {
  __typename?: 'CountrySumAggregate';
  iso_3166_1_num?: Maybe<Scalars['Int']['output']>;
};

export type CountrySumOrderByAggregateInput = {
  iso_3166_1_num?: InputMaybe<SortOrder>;
};

export type CountryUpdateInput = {
  contact?: InputMaybe<ContactUpdateManyWithoutCountryNestedInput>;
  country_de?: InputMaybe<StringFieldUpdateOperationsInput>;
  country_en?: InputMaybe<StringFieldUpdateOperationsInput>;
  dialing_prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  fifa?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fips10?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ioc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iso_3166_1?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_alpha3?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  itu?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maritime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mmc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutCountryNestedInput>;
  stanag_1059?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uic?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  un_locode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vehicle_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CountryUpdateManyMutationInput = {
  country_de?: InputMaybe<StringFieldUpdateOperationsInput>;
  country_en?: InputMaybe<StringFieldUpdateOperationsInput>;
  dialing_prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  fifa?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fips10?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ioc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iso_3166_1?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_alpha3?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  itu?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maritime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mmc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stanag_1059?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uic?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  un_locode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vehicle_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CountryUpdateOneWithoutContactNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<CountryCreateWithoutContactInput>;
  delete?: InputMaybe<CountryWhereInput>;
  disconnect?: InputMaybe<CountryWhereInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutContactInput>;
  upsert?: InputMaybe<CountryUpsertWithoutContactInput>;
};

export type CountryUpdateOneWithoutProjectNestedInput = {
  connect?: InputMaybe<CountryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CountryCreateOrConnectWithoutProjectInput>;
  create?: InputMaybe<CountryCreateWithoutProjectInput>;
  delete?: InputMaybe<CountryWhereInput>;
  disconnect?: InputMaybe<CountryWhereInput>;
  update?: InputMaybe<CountryUpdateToOneWithWhereWithoutProjectInput>;
  upsert?: InputMaybe<CountryUpsertWithoutProjectInput>;
};

export type CountryUpdateToOneWithWhereWithoutContactInput = {
  data: CountryUpdateWithoutContactInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateToOneWithWhereWithoutProjectInput = {
  data: CountryUpdateWithoutProjectInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpdateWithoutContactInput = {
  country_de?: InputMaybe<StringFieldUpdateOperationsInput>;
  country_en?: InputMaybe<StringFieldUpdateOperationsInput>;
  dialing_prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  fifa?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fips10?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ioc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iso_3166_1?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_alpha3?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  itu?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maritime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mmc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateManyWithoutCountryNestedInput>;
  stanag_1059?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uic?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  un_locode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vehicle_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CountryUpdateWithoutProjectInput = {
  contact?: InputMaybe<ContactUpdateManyWithoutCountryNestedInput>;
  country_de?: InputMaybe<StringFieldUpdateOperationsInput>;
  country_en?: InputMaybe<StringFieldUpdateOperationsInput>;
  dialing_prefix?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  domain?: InputMaybe<StringFieldUpdateOperationsInput>;
  fifa?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fips10?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ioc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iso_3166_1?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_alpha3?: InputMaybe<StringFieldUpdateOperationsInput>;
  iso_3166_1_num?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  itu?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maritime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mmc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stanag_1059?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  uic?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  un_locode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  vehicle_code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CountryUpsertWithoutContactInput = {
  create: CountryCreateWithoutContactInput;
  update: CountryUpdateWithoutContactInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryUpsertWithoutProjectInput = {
  create: CountryCreateWithoutProjectInput;
  update: CountryUpdateWithoutProjectInput;
  where?: InputMaybe<CountryWhereInput>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  contact?: InputMaybe<ContactListRelationFilter>;
  country_de?: InputMaybe<StringFilter>;
  country_en?: InputMaybe<StringFilter>;
  dialing_prefix?: InputMaybe<StringNullableFilter>;
  domain?: InputMaybe<StringFilter>;
  fifa?: InputMaybe<StringNullableFilter>;
  fips10?: InputMaybe<StringNullableFilter>;
  ioc?: InputMaybe<StringNullableFilter>;
  iso_3166_1?: InputMaybe<StringFilter>;
  iso_3166_1_alpha3?: InputMaybe<StringFilter>;
  iso_3166_1_num?: InputMaybe<IntNullableFilter>;
  itu?: InputMaybe<StringNullableFilter>;
  maritime?: InputMaybe<StringNullableFilter>;
  mmc?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  stanag_1059?: InputMaybe<StringNullableFilter>;
  uic?: InputMaybe<StringNullableFilter>;
  un_locode?: InputMaybe<StringNullableFilter>;
  vehicle_code?: InputMaybe<StringNullableFilter>;
};

export type CountryWhereUniqueInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  contact?: InputMaybe<ContactListRelationFilter>;
  country_de?: InputMaybe<StringFilter>;
  country_en?: InputMaybe<StringFilter>;
  dialing_prefix?: InputMaybe<StringNullableFilter>;
  domain?: InputMaybe<StringFilter>;
  fifa?: InputMaybe<StringNullableFilter>;
  fips10?: InputMaybe<StringNullableFilter>;
  ioc?: InputMaybe<StringNullableFilter>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1_alpha3?: InputMaybe<StringFilter>;
  iso_3166_1_num?: InputMaybe<IntNullableFilter>;
  itu?: InputMaybe<StringNullableFilter>;
  maritime?: InputMaybe<StringNullableFilter>;
  mmc?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectListRelationFilter>;
  stanag_1059?: InputMaybe<StringNullableFilter>;
  uic?: InputMaybe<StringNullableFilter>;
  un_locode?: InputMaybe<StringNullableFilter>;
  vehicle_code?: InputMaybe<StringNullableFilter>;
};

export type CreateManyAndReturnAgent = {
  __typename?: 'CreateManyAndReturnAgent';
  additional_info?: Maybe<Scalars['JSON']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  cognito_user?: Maybe<Scalars['Boolean']['output']>;
  cognito_user_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  role: User_Role;
  salutation: Salutation;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CreateManyAndReturnCampaign = {
  __typename?: 'CreateManyAndReturnCampaign';
  additional_info?: Maybe<Scalars['JSON']['output']>;
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign_name?: Maybe<Scalars['String']['output']>;
  campaign_state?: Maybe<Scalars['Decimal']['output']>;
  campaign_type?: Maybe<Campaign_Type>;
  client: Client;
  client_id: Scalars['String']['output'];
  contact: Contact;
  contact_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  ext_campaign_id?: Maybe<Scalars['Int']['output']>;
  ext_video_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  previous_campaign?: Maybe<Campaign>;
  previous_campaign_id?: Maybe<Scalars['String']['output']>;
  project: Project;
  project_id: Scalars['String']['output'];
  statement?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};


export type CreateManyAndReturnCampaignAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type CreateManyAndReturnCampaignPrevious_CampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};

export type CreateManyAndReturnCampaign_Action = {
  __typename?: 'CreateManyAndReturnCampaign_action';
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  campaign: Campaign;
  campaign_id: Scalars['String']['output'];
  client: Client;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  ext_file_id?: Maybe<Scalars['String']['output']>;
  file?: Maybe<File>;
  file_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_state: Campaign_Action_Main_State;
  message?: Maybe<Scalars['String']['output']>;
  sub_state: Campaign_Action_Sub_State;
  url?: Maybe<Scalars['String']['output']>;
};


export type CreateManyAndReturnCampaign_ActionAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type CreateManyAndReturnCampaign_ActionFileArgs = {
  where?: InputMaybe<FileWhereInput>;
};

export type CreateManyAndReturnClient = {
  __typename?: 'CreateManyAndReturnClient';
  bucket: Scalars['String']['output'];
  client_id: Scalars['String']['output'];
  config: Scalars['JSON']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  dkim?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  embed_domains?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  identity_pool_id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  user_pool_id: Scalars['String']['output'];
};

export type CreateManyAndReturnConfig_Campaign_Client = {
  __typename?: 'CreateManyAndReturnConfig_campaign_client';
  client: Client;
  client_id: Scalars['String']['output'];
  config?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
};

export type CreateManyAndReturnConfig_Global = {
  __typename?: 'CreateManyAndReturnConfig_global';
  config?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  name: Config_Name;
};

export type CreateManyAndReturnContact = {
  __typename?: 'CreateManyAndReturnContact';
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  archive_date?: Maybe<Scalars['DateTimeISO']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  company_name?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  ext_import_id?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  formal?: Maybe<Formal>;
  id: Scalars['String']['output'];
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Salutation>;
  street?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type CreateManyAndReturnContactAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type CreateManyAndReturnContactCountryArgs = {
  where?: InputMaybe<CountryWhereInput>;
};

export type CreateManyAndReturnCountry = {
  __typename?: 'CreateManyAndReturnCountry';
  country_de: Scalars['String']['output'];
  country_en: Scalars['String']['output'];
  dialing_prefix?: Maybe<Scalars['String']['output']>;
  domain: Scalars['String']['output'];
  fifa?: Maybe<Scalars['String']['output']>;
  fips10?: Maybe<Scalars['String']['output']>;
  ioc?: Maybe<Scalars['String']['output']>;
  iso_3166_1: Scalars['String']['output'];
  iso_3166_1_alpha3: Scalars['String']['output'];
  iso_3166_1_num?: Maybe<Scalars['Int']['output']>;
  itu?: Maybe<Scalars['String']['output']>;
  maritime?: Maybe<Scalars['String']['output']>;
  mmc?: Maybe<Scalars['String']['output']>;
  stanag_1059?: Maybe<Scalars['String']['output']>;
  uic?: Maybe<Scalars['String']['output']>;
  un_locode?: Maybe<Scalars['String']['output']>;
  vehicle_code?: Maybe<Scalars['String']['output']>;
};

export type CreateManyAndReturnCron = {
  __typename?: 'CreateManyAndReturnCron';
  bounced?: Maybe<Scalars['DateTimeISO']['output']>;
  campaign: Campaign;
  campaign_id: Scalars['String']['output'];
  client: Client;
  client_id: Scalars['String']['output'];
  complaint?: Maybe<Scalars['DateTimeISO']['output']>;
  created: Scalars['DateTimeISO']['output'];
  delayed?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  delivered?: Maybe<Scalars['DateTimeISO']['output']>;
  execute_cron_id?: Maybe<Scalars['String']['output']>;
  execution_counter: Scalars['Int']['output'];
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  job: Cron_Job;
  mail_message_id?: Maybe<Scalars['String']['output']>;
  opened?: Maybe<Scalars['DateTimeISO']['output']>;
  rejected?: Maybe<Scalars['DateTimeISO']['output']>;
  sent?: Maybe<Scalars['DateTimeISO']['output']>;
  state: Cron_State;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CreateManyAndReturnFile = {
  __typename?: 'CreateManyAndReturnFile';
  campaign?: Maybe<Campaign>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  contact?: Maybe<Contact>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  folder?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_file?: Maybe<File>;
  main_file_id?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['String']['output']>;
  s3key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  sync_date?: Maybe<Scalars['DateTimeISO']['output']>;
  sync_id?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag_File>>;
  thumbnail_s3key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type CreateManyAndReturnFileCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type CreateManyAndReturnFileContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


export type CreateManyAndReturnFileMain_FileArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type CreateManyAndReturnFileProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};

export type CreateManyAndReturnList = {
  __typename?: 'CreateManyAndReturnList';
  client: Client;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<ListType>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CreateManyAndReturnMail_Template = {
  __typename?: 'CreateManyAndReturnMail_template';
  client: Client;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  scenario_name: Mail_Scenario;
  template_name?: Maybe<Mail_Template_Name>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CreateManyAndReturnMessage = {
  __typename?: 'CreateManyAndReturnMessage';
  agents?: Maybe<Array<Scalars['String']['output']>>;
  client: Client;
  client_id: Scalars['String']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  read?: Maybe<Scalars['JSON']['output']>;
  subject: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type CreateManyAndReturnParameter = {
  __typename?: 'CreateManyAndReturnParameter';
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  client_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: Maybe<Parameter_Type>;
  value: Scalars['JSON']['output'];
};


export type CreateManyAndReturnParameterAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type CreateManyAndReturnParameterClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type CreateManyAndReturnProject = {
  __typename?: 'CreateManyAndReturnProject';
  city?: Maybe<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  contact: Contact;
  contact_id: Scalars['String']['output'];
  country?: Maybe<Country>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type CreateManyAndReturnProjectCountryArgs = {
  where?: InputMaybe<CountryWhereInput>;
};

export type CreateManyAndReturnSurvey_Published = {
  __typename?: 'CreateManyAndReturnSurvey_published';
  client: Client;
  client_id: Scalars['String']['output'];
  content_string?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  survey_schema: Survey_Schema;
  survey_schema_id: Scalars['String']['output'];
};

export type CreateManyAndReturnSurvey_Result = {
  __typename?: 'CreateManyAndReturnSurvey_result';
  content?: Maybe<Scalars['JSON']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  survey_schema: Survey_Schema;
  survey_schema_id: Scalars['String']['output'];
};

export type CreateManyAndReturnSurvey_Schema = {
  __typename?: 'CreateManyAndReturnSurvey_schema';
  client: Client;
  client_id: Scalars['String']['output'];
  content_string?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Cron = {
  __typename?: 'Cron';
  bounced?: Maybe<Scalars['DateTimeISO']['output']>;
  campaign: Campaign;
  campaign_id: Scalars['String']['output'];
  client: Client;
  client_id: Scalars['String']['output'];
  complaint?: Maybe<Scalars['DateTimeISO']['output']>;
  created: Scalars['DateTimeISO']['output'];
  delayed?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  delivered?: Maybe<Scalars['DateTimeISO']['output']>;
  execute_cron_id?: Maybe<Scalars['String']['output']>;
  execution_counter: Scalars['Int']['output'];
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  job: Cron_Job;
  mail_message_id?: Maybe<Scalars['String']['output']>;
  opened?: Maybe<Scalars['DateTimeISO']['output']>;
  rejected?: Maybe<Scalars['DateTimeISO']['output']>;
  sent?: Maybe<Scalars['DateTimeISO']['output']>;
  state: Cron_State;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CronAvgAggregate = {
  __typename?: 'CronAvgAggregate';
  execution_counter?: Maybe<Scalars['Float']['output']>;
};

export type CronAvgOrderByAggregateInput = {
  execution_counter?: InputMaybe<SortOrder>;
};

export type CronCountAggregate = {
  __typename?: 'CronCountAggregate';
  _all: Scalars['Int']['output'];
  bounced: Scalars['Int']['output'];
  campaign_id: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  complaint: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  delayed: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  delivered: Scalars['Int']['output'];
  execute_cron_id: Scalars['Int']['output'];
  execution_counter: Scalars['Int']['output'];
  execution_date: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  job: Scalars['Int']['output'];
  mail_message_id: Scalars['Int']['output'];
  opened: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  sent: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type CronCountOrderByAggregateInput = {
  bounced?: InputMaybe<SortOrder>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  complaint?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  delayed?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  delivered?: InputMaybe<SortOrder>;
  execute_cron_id?: InputMaybe<SortOrder>;
  execution_counter?: InputMaybe<SortOrder>;
  execution_date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<SortOrder>;
  mail_message_id?: InputMaybe<SortOrder>;
  opened?: InputMaybe<SortOrder>;
  rejected?: InputMaybe<SortOrder>;
  sent?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type CronCreateInput = {
  bounced?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign: CampaignCreateNestedOneWithoutCronsInput;
  client: ClientCreateNestedOneWithoutCronInput;
  complaint?: InputMaybe<Scalars['DateTimeISO']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delayed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delivered?: InputMaybe<Scalars['DateTimeISO']['input']>;
  execute_cron_id?: InputMaybe<Scalars['String']['input']>;
  execution_counter?: InputMaybe<Scalars['Int']['input']>;
  execution_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job: Cron_Job;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rejected?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sent?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state: Cron_State;
  title?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CronCreateManyCampaignInput = {
  bounced?: InputMaybe<Scalars['DateTimeISO']['input']>;
  client_id: Scalars['String']['input'];
  complaint?: InputMaybe<Scalars['DateTimeISO']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delayed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delivered?: InputMaybe<Scalars['DateTimeISO']['input']>;
  execute_cron_id?: InputMaybe<Scalars['String']['input']>;
  execution_counter?: InputMaybe<Scalars['Int']['input']>;
  execution_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job: Cron_Job;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rejected?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sent?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state: Cron_State;
  title?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CronCreateManyCampaignInputEnvelope = {
  data: Array<CronCreateManyCampaignInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CronCreateManyClientInput = {
  bounced?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign_id: Scalars['String']['input'];
  complaint?: InputMaybe<Scalars['DateTimeISO']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delayed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delivered?: InputMaybe<Scalars['DateTimeISO']['input']>;
  execute_cron_id?: InputMaybe<Scalars['String']['input']>;
  execution_counter?: InputMaybe<Scalars['Int']['input']>;
  execution_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job: Cron_Job;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rejected?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sent?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state: Cron_State;
  title?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CronCreateManyClientInputEnvelope = {
  data: Array<CronCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CronCreateManyInput = {
  bounced?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign_id: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  complaint?: InputMaybe<Scalars['DateTimeISO']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delayed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delivered?: InputMaybe<Scalars['DateTimeISO']['input']>;
  execute_cron_id?: InputMaybe<Scalars['String']['input']>;
  execution_counter?: InputMaybe<Scalars['Int']['input']>;
  execution_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job: Cron_Job;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rejected?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sent?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state: Cron_State;
  title?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CronCreateNestedManyWithoutCampaignInput = {
  connect?: InputMaybe<Array<CronWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CronCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<CronCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<CronCreateManyCampaignInputEnvelope>;
};

export type CronCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<CronWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CronCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<CronCreateWithoutClientInput>>;
  createMany?: InputMaybe<CronCreateManyClientInputEnvelope>;
};

export type CronCreateOrConnectWithoutCampaignInput = {
  create: CronCreateWithoutCampaignInput;
  where: CronWhereUniqueInput;
};

export type CronCreateOrConnectWithoutClientInput = {
  create: CronCreateWithoutClientInput;
  where: CronWhereUniqueInput;
};

export type CronCreateWithoutCampaignInput = {
  bounced?: InputMaybe<Scalars['DateTimeISO']['input']>;
  client: ClientCreateNestedOneWithoutCronInput;
  complaint?: InputMaybe<Scalars['DateTimeISO']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delayed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delivered?: InputMaybe<Scalars['DateTimeISO']['input']>;
  execute_cron_id?: InputMaybe<Scalars['String']['input']>;
  execution_counter?: InputMaybe<Scalars['Int']['input']>;
  execution_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job: Cron_Job;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rejected?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sent?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state: Cron_State;
  title?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CronCreateWithoutClientInput = {
  bounced?: InputMaybe<Scalars['DateTimeISO']['input']>;
  campaign: CampaignCreateNestedOneWithoutCronsInput;
  complaint?: InputMaybe<Scalars['DateTimeISO']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delayed?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  delivered?: InputMaybe<Scalars['DateTimeISO']['input']>;
  execute_cron_id?: InputMaybe<Scalars['String']['input']>;
  execution_counter?: InputMaybe<Scalars['Int']['input']>;
  execution_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  job: Cron_Job;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTimeISO']['input']>;
  rejected?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sent?: InputMaybe<Scalars['DateTimeISO']['input']>;
  state: Cron_State;
  title?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type CronGroupBy = {
  __typename?: 'CronGroupBy';
  _avg?: Maybe<CronAvgAggregate>;
  _count?: Maybe<CronCountAggregate>;
  _max?: Maybe<CronMaxAggregate>;
  _min?: Maybe<CronMinAggregate>;
  _sum?: Maybe<CronSumAggregate>;
  bounced?: Maybe<Scalars['DateTimeISO']['output']>;
  campaign_id: Scalars['String']['output'];
  client_id: Scalars['String']['output'];
  complaint?: Maybe<Scalars['DateTimeISO']['output']>;
  created: Scalars['DateTimeISO']['output'];
  delayed?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  delivered?: Maybe<Scalars['DateTimeISO']['output']>;
  execute_cron_id?: Maybe<Scalars['String']['output']>;
  execution_counter: Scalars['Int']['output'];
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  job: Cron_Job;
  mail_message_id?: Maybe<Scalars['String']['output']>;
  opened?: Maybe<Scalars['DateTimeISO']['output']>;
  rejected?: Maybe<Scalars['DateTimeISO']['output']>;
  sent?: Maybe<Scalars['DateTimeISO']['output']>;
  state: Cron_State;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CronListRelationFilter = {
  every?: InputMaybe<CronWhereInput>;
  none?: InputMaybe<CronWhereInput>;
  some?: InputMaybe<CronWhereInput>;
};

export type CronMaxAggregate = {
  __typename?: 'CronMaxAggregate';
  bounced?: Maybe<Scalars['DateTimeISO']['output']>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  complaint?: Maybe<Scalars['DateTimeISO']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  delayed?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  delivered?: Maybe<Scalars['DateTimeISO']['output']>;
  execute_cron_id?: Maybe<Scalars['String']['output']>;
  execution_counter?: Maybe<Scalars['Int']['output']>;
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Cron_Job>;
  mail_message_id?: Maybe<Scalars['String']['output']>;
  opened?: Maybe<Scalars['DateTimeISO']['output']>;
  rejected?: Maybe<Scalars['DateTimeISO']['output']>;
  sent?: Maybe<Scalars['DateTimeISO']['output']>;
  state?: Maybe<Cron_State>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CronMaxOrderByAggregateInput = {
  bounced?: InputMaybe<SortOrder>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  complaint?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  delayed?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  delivered?: InputMaybe<SortOrder>;
  execute_cron_id?: InputMaybe<SortOrder>;
  execution_counter?: InputMaybe<SortOrder>;
  execution_date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<SortOrder>;
  mail_message_id?: InputMaybe<SortOrder>;
  opened?: InputMaybe<SortOrder>;
  rejected?: InputMaybe<SortOrder>;
  sent?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type CronMinAggregate = {
  __typename?: 'CronMinAggregate';
  bounced?: Maybe<Scalars['DateTimeISO']['output']>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  complaint?: Maybe<Scalars['DateTimeISO']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  delayed?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  delivered?: Maybe<Scalars['DateTimeISO']['output']>;
  execute_cron_id?: Maybe<Scalars['String']['output']>;
  execution_counter?: Maybe<Scalars['Int']['output']>;
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Cron_Job>;
  mail_message_id?: Maybe<Scalars['String']['output']>;
  opened?: Maybe<Scalars['DateTimeISO']['output']>;
  rejected?: Maybe<Scalars['DateTimeISO']['output']>;
  sent?: Maybe<Scalars['DateTimeISO']['output']>;
  state?: Maybe<Cron_State>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type CronMinOrderByAggregateInput = {
  bounced?: InputMaybe<SortOrder>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  complaint?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  delayed?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  delivered?: InputMaybe<SortOrder>;
  execute_cron_id?: InputMaybe<SortOrder>;
  execution_counter?: InputMaybe<SortOrder>;
  execution_date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<SortOrder>;
  mail_message_id?: InputMaybe<SortOrder>;
  opened?: InputMaybe<SortOrder>;
  rejected?: InputMaybe<SortOrder>;
  sent?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type CronOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CronOrderByWithAggregationInput = {
  _avg?: InputMaybe<CronAvgOrderByAggregateInput>;
  _count?: InputMaybe<CronCountOrderByAggregateInput>;
  _max?: InputMaybe<CronMaxOrderByAggregateInput>;
  _min?: InputMaybe<CronMinOrderByAggregateInput>;
  _sum?: InputMaybe<CronSumOrderByAggregateInput>;
  bounced?: InputMaybe<SortOrderInput>;
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  complaint?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  delayed?: InputMaybe<SortOrderInput>;
  deleted?: InputMaybe<SortOrderInput>;
  delivered?: InputMaybe<SortOrderInput>;
  execute_cron_id?: InputMaybe<SortOrderInput>;
  execution_counter?: InputMaybe<SortOrder>;
  execution_date?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<SortOrder>;
  mail_message_id?: InputMaybe<SortOrderInput>;
  opened?: InputMaybe<SortOrderInput>;
  rejected?: InputMaybe<SortOrderInput>;
  sent?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export type CronOrderByWithRelationInput = {
  bounced?: InputMaybe<SortOrderInput>;
  campaign?: InputMaybe<CampaignOrderByWithRelationInput>;
  campaign_id?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  complaint?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  delayed?: InputMaybe<SortOrderInput>;
  deleted?: InputMaybe<SortOrderInput>;
  delivered?: InputMaybe<SortOrderInput>;
  execute_cron_id?: InputMaybe<SortOrderInput>;
  execution_counter?: InputMaybe<SortOrder>;
  execution_date?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  job?: InputMaybe<SortOrder>;
  mail_message_id?: InputMaybe<SortOrderInput>;
  opened?: InputMaybe<SortOrderInput>;
  rejected?: InputMaybe<SortOrderInput>;
  sent?: InputMaybe<SortOrderInput>;
  state?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export enum CronScalarFieldEnum {
  Bounced = 'bounced',
  CampaignId = 'campaign_id',
  ClientId = 'client_id',
  Complaint = 'complaint',
  Created = 'created',
  Delayed = 'delayed',
  Deleted = 'deleted',
  Delivered = 'delivered',
  ExecuteCronId = 'execute_cron_id',
  ExecutionCounter = 'execution_counter',
  ExecutionDate = 'execution_date',
  Id = 'id',
  Job = 'job',
  MailMessageId = 'mail_message_id',
  Opened = 'opened',
  Rejected = 'rejected',
  Sent = 'sent',
  State = 'state',
  Title = 'title',
  Updated = 'updated'
}

export type CronScalarWhereInput = {
  AND?: InputMaybe<Array<CronScalarWhereInput>>;
  NOT?: InputMaybe<Array<CronScalarWhereInput>>;
  OR?: InputMaybe<Array<CronScalarWhereInput>>;
  bounced?: InputMaybe<DateTimeNullableFilter>;
  campaign_id?: InputMaybe<UuidFilter>;
  client_id?: InputMaybe<StringFilter>;
  complaint?: InputMaybe<DateTimeNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  delayed?: InputMaybe<DateTimeNullableFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  delivered?: InputMaybe<DateTimeNullableFilter>;
  execute_cron_id?: InputMaybe<UuidNullableFilter>;
  execution_counter?: InputMaybe<IntFilter>;
  execution_date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  job?: InputMaybe<Enumcron_JobFilter>;
  mail_message_id?: InputMaybe<StringNullableFilter>;
  opened?: InputMaybe<DateTimeNullableFilter>;
  rejected?: InputMaybe<DateTimeNullableFilter>;
  sent?: InputMaybe<DateTimeNullableFilter>;
  state?: InputMaybe<Enumcron_StateFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type CronScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CronScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CronScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CronScalarWhereWithAggregatesInput>>;
  bounced?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  campaign_id?: InputMaybe<UuidWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  complaint?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  delayed?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  delivered?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  execute_cron_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  execution_counter?: InputMaybe<IntWithAggregatesFilter>;
  execution_date?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  job?: InputMaybe<Enumcron_JobWithAggregatesFilter>;
  mail_message_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  opened?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  rejected?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  sent?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  state?: InputMaybe<Enumcron_StateWithAggregatesFilter>;
  title?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type CronSumAggregate = {
  __typename?: 'CronSumAggregate';
  execution_counter?: Maybe<Scalars['Int']['output']>;
};

export type CronSumOrderByAggregateInput = {
  execution_counter?: InputMaybe<SortOrder>;
};

export type CronUpdateInput = {
  bounced?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateOneRequiredWithoutCronsNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCronNestedInput>;
  complaint?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delayed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  execute_cron_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  execution_counter?: InputMaybe<IntFieldUpdateOperationsInput>;
  execution_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  job?: InputMaybe<Enumcron_JobFieldUpdateOperationsInput>;
  mail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  opened?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rejected?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sent?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<Enumcron_StateFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CronUpdateManyMutationInput = {
  bounced?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  complaint?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delayed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  execute_cron_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  execution_counter?: InputMaybe<IntFieldUpdateOperationsInput>;
  execution_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  job?: InputMaybe<Enumcron_JobFieldUpdateOperationsInput>;
  mail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  opened?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rejected?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sent?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<Enumcron_StateFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CronUpdateManyWithWhereWithoutCampaignInput = {
  data: CronUpdateManyMutationInput;
  where: CronScalarWhereInput;
};

export type CronUpdateManyWithWhereWithoutClientInput = {
  data: CronUpdateManyMutationInput;
  where: CronScalarWhereInput;
};

export type CronUpdateManyWithoutCampaignNestedInput = {
  connect?: InputMaybe<Array<CronWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CronCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<CronCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<CronCreateManyCampaignInputEnvelope>;
  delete?: InputMaybe<Array<CronWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CronScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CronWhereUniqueInput>>;
  set?: InputMaybe<Array<CronWhereUniqueInput>>;
  update?: InputMaybe<Array<CronUpdateWithWhereUniqueWithoutCampaignInput>>;
  updateMany?: InputMaybe<Array<CronUpdateManyWithWhereWithoutCampaignInput>>;
  upsert?: InputMaybe<Array<CronUpsertWithWhereUniqueWithoutCampaignInput>>;
};

export type CronUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<CronWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CronCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<CronCreateWithoutClientInput>>;
  createMany?: InputMaybe<CronCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<CronWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CronScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CronWhereUniqueInput>>;
  set?: InputMaybe<Array<CronWhereUniqueInput>>;
  update?: InputMaybe<Array<CronUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<CronUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<CronUpsertWithWhereUniqueWithoutClientInput>>;
};

export type CronUpdateWithWhereUniqueWithoutCampaignInput = {
  data: CronUpdateWithoutCampaignInput;
  where: CronWhereUniqueInput;
};

export type CronUpdateWithWhereUniqueWithoutClientInput = {
  data: CronUpdateWithoutClientInput;
  where: CronWhereUniqueInput;
};

export type CronUpdateWithoutCampaignInput = {
  bounced?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCronNestedInput>;
  complaint?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delayed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  execute_cron_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  execution_counter?: InputMaybe<IntFieldUpdateOperationsInput>;
  execution_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  job?: InputMaybe<Enumcron_JobFieldUpdateOperationsInput>;
  mail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  opened?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rejected?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sent?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<Enumcron_StateFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CronUpdateWithoutClientInput = {
  bounced?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  campaign?: InputMaybe<CampaignUpdateOneRequiredWithoutCronsNestedInput>;
  complaint?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  delayed?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  delivered?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  execute_cron_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  execution_counter?: InputMaybe<IntFieldUpdateOperationsInput>;
  execution_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  job?: InputMaybe<Enumcron_JobFieldUpdateOperationsInput>;
  mail_message_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  opened?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  rejected?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sent?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  state?: InputMaybe<Enumcron_StateFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type CronUpsertWithWhereUniqueWithoutCampaignInput = {
  create: CronCreateWithoutCampaignInput;
  update: CronUpdateWithoutCampaignInput;
  where: CronWhereUniqueInput;
};

export type CronUpsertWithWhereUniqueWithoutClientInput = {
  create: CronCreateWithoutClientInput;
  update: CronUpdateWithoutClientInput;
  where: CronWhereUniqueInput;
};

export type CronValidityInput = {
  campaign_id: Scalars['String']['input'];
  notifications: Array<Scalars['JSONObject']['input']>;
  offerValidity: Scalars['DateTimeISO']['input'];
};

export type CronWhereInput = {
  AND?: InputMaybe<Array<CronWhereInput>>;
  NOT?: InputMaybe<Array<CronWhereInput>>;
  OR?: InputMaybe<Array<CronWhereInput>>;
  bounced?: InputMaybe<DateTimeNullableFilter>;
  campaign?: InputMaybe<CampaignRelationFilter>;
  campaign_id?: InputMaybe<UuidFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  complaint?: InputMaybe<DateTimeNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  delayed?: InputMaybe<DateTimeNullableFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  delivered?: InputMaybe<DateTimeNullableFilter>;
  execute_cron_id?: InputMaybe<UuidNullableFilter>;
  execution_counter?: InputMaybe<IntFilter>;
  execution_date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  job?: InputMaybe<Enumcron_JobFilter>;
  mail_message_id?: InputMaybe<StringNullableFilter>;
  opened?: InputMaybe<DateTimeNullableFilter>;
  rejected?: InputMaybe<DateTimeNullableFilter>;
  sent?: InputMaybe<DateTimeNullableFilter>;
  state?: InputMaybe<Enumcron_StateFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type CronWhereUniqueInput = {
  AND?: InputMaybe<Array<CronWhereInput>>;
  NOT?: InputMaybe<Array<CronWhereInput>>;
  OR?: InputMaybe<Array<CronWhereInput>>;
  bounced?: InputMaybe<DateTimeNullableFilter>;
  campaign?: InputMaybe<CampaignRelationFilter>;
  campaign_id?: InputMaybe<UuidFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  complaint?: InputMaybe<DateTimeNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  delayed?: InputMaybe<DateTimeNullableFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  delivered?: InputMaybe<DateTimeNullableFilter>;
  execute_cron_id?: InputMaybe<UuidNullableFilter>;
  execution_counter?: InputMaybe<IntFilter>;
  execution_date?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  job?: InputMaybe<Enumcron_JobFilter>;
  mail_message_id?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<DateTimeNullableFilter>;
  rejected?: InputMaybe<DateTimeNullableFilter>;
  sent?: InputMaybe<DateTimeNullableFilter>;
  state?: InputMaybe<Enumcron_StateFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type ETerminInput = {
  client_id: Scalars['String']['input'];
};

export type ETerminOutput = {
  __typename?: 'ETerminOutput';
  calendar: Array<Scalars['JSONObject']['output']>;
  calendarService: Array<Scalars['JSONObject']['output']>;
  service: Array<Scalars['JSONObject']['output']>;
  serviceGroup: Array<Scalars['JSONObject']['output']>;
};

/** Edge Type */
export type Edge = {
  __typename?: 'Edge';
  node: Folder;
};

export type Enumcampaign_Action_Main_StateFieldUpdateOperationsInput = {
  set?: InputMaybe<Campaign_Action_Main_State>;
};

export type Enumcampaign_Action_Main_StateFilter = {
  equals?: InputMaybe<Campaign_Action_Main_State>;
  in?: InputMaybe<Array<Campaign_Action_Main_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Main_StateFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Main_State>>;
};

export type Enumcampaign_Action_Main_StateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcampaign_Action_Main_StateFilter>;
  _min?: InputMaybe<NestedEnumcampaign_Action_Main_StateFilter>;
  equals?: InputMaybe<Campaign_Action_Main_State>;
  in?: InputMaybe<Array<Campaign_Action_Main_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Main_StateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Main_State>>;
};

export type Enumcampaign_Action_Sub_StateFieldUpdateOperationsInput = {
  set?: InputMaybe<Campaign_Action_Sub_State>;
};

export type Enumcampaign_Action_Sub_StateFilter = {
  equals?: InputMaybe<Campaign_Action_Sub_State>;
  in?: InputMaybe<Array<Campaign_Action_Sub_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Sub_StateFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Sub_State>>;
};

export type Enumcampaign_Action_Sub_StateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcampaign_Action_Sub_StateFilter>;
  _min?: InputMaybe<NestedEnumcampaign_Action_Sub_StateFilter>;
  equals?: InputMaybe<Campaign_Action_Sub_State>;
  in?: InputMaybe<Array<Campaign_Action_Sub_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Sub_StateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Sub_State>>;
};

export type Enumcampaign_TypeNullableFilter = {
  equals?: InputMaybe<Campaign_Type>;
  in?: InputMaybe<Array<Campaign_Type>>;
  not?: InputMaybe<NestedEnumcampaign_TypeNullableFilter>;
  notIn?: InputMaybe<Array<Campaign_Type>>;
};

export type Enumcampaign_TypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumcampaign_TypeNullableFilter>;
  _min?: InputMaybe<NestedEnumcampaign_TypeNullableFilter>;
  equals?: InputMaybe<Campaign_Type>;
  in?: InputMaybe<Array<Campaign_Type>>;
  not?: InputMaybe<NestedEnumcampaign_TypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Campaign_Type>>;
};

export type Enumconfig_NameFieldUpdateOperationsInput = {
  set?: InputMaybe<Config_Name>;
};

export type Enumconfig_NameFilter = {
  equals?: InputMaybe<Config_Name>;
  in?: InputMaybe<Array<Config_Name>>;
  not?: InputMaybe<NestedEnumconfig_NameFilter>;
  notIn?: InputMaybe<Array<Config_Name>>;
};

export type Enumconfig_NameWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumconfig_NameFilter>;
  _min?: InputMaybe<NestedEnumconfig_NameFilter>;
  equals?: InputMaybe<Config_Name>;
  in?: InputMaybe<Array<Config_Name>>;
  not?: InputMaybe<NestedEnumconfig_NameWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Config_Name>>;
};

export type Enumcron_JobFieldUpdateOperationsInput = {
  set?: InputMaybe<Cron_Job>;
};

export type Enumcron_JobFilter = {
  equals?: InputMaybe<Cron_Job>;
  in?: InputMaybe<Array<Cron_Job>>;
  not?: InputMaybe<NestedEnumcron_JobFilter>;
  notIn?: InputMaybe<Array<Cron_Job>>;
};

export type Enumcron_JobWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcron_JobFilter>;
  _min?: InputMaybe<NestedEnumcron_JobFilter>;
  equals?: InputMaybe<Cron_Job>;
  in?: InputMaybe<Array<Cron_Job>>;
  not?: InputMaybe<NestedEnumcron_JobWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Cron_Job>>;
};

export type Enumcron_StateFieldUpdateOperationsInput = {
  set?: InputMaybe<Cron_State>;
};

export type Enumcron_StateFilter = {
  equals?: InputMaybe<Cron_State>;
  in?: InputMaybe<Array<Cron_State>>;
  not?: InputMaybe<NestedEnumcron_StateFilter>;
  notIn?: InputMaybe<Array<Cron_State>>;
};

export type Enumcron_StateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcron_StateFilter>;
  _min?: InputMaybe<NestedEnumcron_StateFilter>;
  equals?: InputMaybe<Cron_State>;
  in?: InputMaybe<Array<Cron_State>>;
  not?: InputMaybe<NestedEnumcron_StateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Cron_State>>;
};

export type EnumformalNullableFilter = {
  equals?: InputMaybe<Formal>;
  in?: InputMaybe<Array<Formal>>;
  not?: InputMaybe<NestedEnumformalNullableFilter>;
  notIn?: InputMaybe<Array<Formal>>;
};

export type EnumformalNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumformalNullableFilter>;
  _min?: InputMaybe<NestedEnumformalNullableFilter>;
  equals?: InputMaybe<Formal>;
  in?: InputMaybe<Array<Formal>>;
  not?: InputMaybe<NestedEnumformalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Formal>>;
};

export type EnumlistTypeNullableFilter = {
  equals?: InputMaybe<ListType>;
  in?: InputMaybe<Array<ListType>>;
  not?: InputMaybe<NestedEnumlistTypeNullableFilter>;
  notIn?: InputMaybe<Array<ListType>>;
};

export type EnumlistTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumlistTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumlistTypeNullableFilter>;
  equals?: InputMaybe<ListType>;
  in?: InputMaybe<Array<ListType>>;
  not?: InputMaybe<NestedEnumlistTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ListType>>;
};

export type Enummail_ScenarioFieldUpdateOperationsInput = {
  set?: InputMaybe<Mail_Scenario>;
};

export type Enummail_ScenarioFilter = {
  equals?: InputMaybe<Mail_Scenario>;
  in?: InputMaybe<Array<Mail_Scenario>>;
  not?: InputMaybe<NestedEnummail_ScenarioFilter>;
  notIn?: InputMaybe<Array<Mail_Scenario>>;
};

export type Enummail_ScenarioWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnummail_ScenarioFilter>;
  _min?: InputMaybe<NestedEnummail_ScenarioFilter>;
  equals?: InputMaybe<Mail_Scenario>;
  in?: InputMaybe<Array<Mail_Scenario>>;
  not?: InputMaybe<NestedEnummail_ScenarioWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Mail_Scenario>>;
};

export type Enummail_Template_NameNullableFilter = {
  equals?: InputMaybe<Mail_Template_Name>;
  in?: InputMaybe<Array<Mail_Template_Name>>;
  not?: InputMaybe<NestedEnummail_Template_NameNullableFilter>;
  notIn?: InputMaybe<Array<Mail_Template_Name>>;
};

export type Enummail_Template_NameNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnummail_Template_NameNullableFilter>;
  _min?: InputMaybe<NestedEnummail_Template_NameNullableFilter>;
  equals?: InputMaybe<Mail_Template_Name>;
  in?: InputMaybe<Array<Mail_Template_Name>>;
  not?: InputMaybe<NestedEnummail_Template_NameNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Mail_Template_Name>>;
};

export type Enumparameter_KeyFieldUpdateOperationsInput = {
  set?: InputMaybe<Parameter_Key>;
};

export type Enumparameter_KeyFilter = {
  equals?: InputMaybe<Parameter_Key>;
  in?: InputMaybe<Array<Parameter_Key>>;
  not?: InputMaybe<NestedEnumparameter_KeyFilter>;
  notIn?: InputMaybe<Array<Parameter_Key>>;
};

export type Enumparameter_KeyWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumparameter_KeyFilter>;
  _min?: InputMaybe<NestedEnumparameter_KeyFilter>;
  equals?: InputMaybe<Parameter_Key>;
  in?: InputMaybe<Array<Parameter_Key>>;
  not?: InputMaybe<NestedEnumparameter_KeyWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Parameter_Key>>;
};

export type Enumparameter_ScenarioFieldUpdateOperationsInput = {
  set?: InputMaybe<Parameter_Scenario>;
};

export type Enumparameter_ScenarioFilter = {
  equals?: InputMaybe<Parameter_Scenario>;
  in?: InputMaybe<Array<Parameter_Scenario>>;
  not?: InputMaybe<NestedEnumparameter_ScenarioFilter>;
  notIn?: InputMaybe<Array<Parameter_Scenario>>;
};

export type Enumparameter_ScenarioWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumparameter_ScenarioFilter>;
  _min?: InputMaybe<NestedEnumparameter_ScenarioFilter>;
  equals?: InputMaybe<Parameter_Scenario>;
  in?: InputMaybe<Array<Parameter_Scenario>>;
  not?: InputMaybe<NestedEnumparameter_ScenarioWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Parameter_Scenario>>;
};

export type Enumparameter_TypeNullableFilter = {
  equals?: InputMaybe<Parameter_Type>;
  in?: InputMaybe<Array<Parameter_Type>>;
  not?: InputMaybe<NestedEnumparameter_TypeNullableFilter>;
  notIn?: InputMaybe<Array<Parameter_Type>>;
};

export type Enumparameter_TypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumparameter_TypeNullableFilter>;
  _min?: InputMaybe<NestedEnumparameter_TypeNullableFilter>;
  equals?: InputMaybe<Parameter_Type>;
  in?: InputMaybe<Array<Parameter_Type>>;
  not?: InputMaybe<NestedEnumparameter_TypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Parameter_Type>>;
};

export type EnumsalutationFieldUpdateOperationsInput = {
  set?: InputMaybe<Salutation>;
};

export type EnumsalutationFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type EnumsalutationNullableFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationNullableFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type EnumsalutationNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumsalutationNullableFilter>;
  _min?: InputMaybe<NestedEnumsalutationNullableFilter>;
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type EnumsalutationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumsalutationFilter>;
  _min?: InputMaybe<NestedEnumsalutationFilter>;
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type Enumtag_FileNullableListFilter = {
  equals?: InputMaybe<Array<Tag_File>>;
  has?: InputMaybe<Tag_File>;
  hasEvery?: InputMaybe<Array<Tag_File>>;
  hasSome?: InputMaybe<Array<Tag_File>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Enumuser_RoleFieldUpdateOperationsInput = {
  set?: InputMaybe<User_Role>;
};

export type Enumuser_RoleFilter = {
  equals?: InputMaybe<User_Role>;
  in?: InputMaybe<Array<User_Role>>;
  not?: InputMaybe<NestedEnumuser_RoleFilter>;
  notIn?: InputMaybe<Array<User_Role>>;
};

export type Enumuser_RoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumuser_RoleFilter>;
  _min?: InputMaybe<NestedEnumuser_RoleFilter>;
  equals?: InputMaybe<User_Role>;
  in?: InputMaybe<Array<User_Role>>;
  not?: InputMaybe<NestedEnumuser_RoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<User_Role>>;
};

export type File = {
  __typename?: 'File';
  _count?: Maybe<FileCount>;
  campaign?: Maybe<Campaign>;
  campaign_action: Array<Campaign_Action>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  contact?: Maybe<Contact>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  folder?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_file?: Maybe<File>;
  main_file_id?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['String']['output']>;
  s3key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  sub_files: Array<File>;
  sync_date?: Maybe<Scalars['DateTimeISO']['output']>;
  sync_id?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag_File>;
  thumbnail_s3key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type FileCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type FileCampaign_ActionArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type FileContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


export type FileMain_FileArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type FileProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type FileSub_FilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};

export type FileAvgAggregate = {
  __typename?: 'FileAvgAggregate';
  size?: Maybe<Scalars['Float']['output']>;
};

export type FileAvgOrderByAggregateInput = {
  size?: InputMaybe<SortOrder>;
};

export type FileCount = {
  __typename?: 'FileCount';
  campaign_action: Scalars['Int']['output'];
  sub_files: Scalars['Int']['output'];
};


export type FileCountCampaign_ActionArgs = {
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type FileCountSub_FilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};

export type FileCountAggregate = {
  __typename?: 'FileCountAggregate';
  _all: Scalars['Int']['output'];
  campaign_id: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  contact_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  folder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  main_file_id: Scalars['Int']['output'];
  mime: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  project_id: Scalars['Int']['output'];
  s3key: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  sync_date: Scalars['Int']['output'];
  sync_id: Scalars['Int']['output'];
  tags: Scalars['Int']['output'];
  thumbnail_s3key: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
};

export type FileCountOrderByAggregateInput = {
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  folder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  main_file_id?: InputMaybe<SortOrder>;
  mime?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  project_id?: InputMaybe<SortOrder>;
  s3key?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  sync_date?: InputMaybe<SortOrder>;
  sync_id?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  thumbnail_s3key?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type FileCreateInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyCampaignInput = {
  client_id: Scalars['String']['input'];
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file_id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyCampaignInputEnvelope = {
  data: Array<FileCreateManyCampaignInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCreateManyClientInput = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file_id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyClientInputEnvelope = {
  data: Array<FileCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCreateManyContactInput = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file_id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyContactInputEnvelope = {
  data: Array<FileCreateManyContactInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCreateManyInput = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file_id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyMain_FileInput = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project_id?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyMain_FileInputEnvelope = {
  data: Array<FileCreateManyMain_FileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCreateManyProjectInput = {
  campaign_id?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  contact_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file_id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateManyProjectInputEnvelope = {
  data: Array<FileCreateManyProjectInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCreateNestedManyWithoutCampaignInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<FileCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<FileCreateManyCampaignInputEnvelope>;
};

export type FileCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<FileCreateWithoutClientInput>>;
  createMany?: InputMaybe<FileCreateManyClientInputEnvelope>;
};

export type FileCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<FileCreateWithoutContactInput>>;
  createMany?: InputMaybe<FileCreateManyContactInputEnvelope>;
};

export type FileCreateNestedManyWithoutMain_FileInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutMain_FileInput>>;
  create?: InputMaybe<Array<FileCreateWithoutMain_FileInput>>;
  createMany?: InputMaybe<FileCreateManyMain_FileInputEnvelope>;
};

export type FileCreateNestedManyWithoutProjectInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<FileCreateWithoutProjectInput>>;
  createMany?: InputMaybe<FileCreateManyProjectInputEnvelope>;
};

export type FileCreateNestedOneWithoutCampaign_ActionInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<FileCreateWithoutCampaign_ActionInput>;
};

export type FileCreateNestedOneWithoutSub_FilesInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutSub_FilesInput>;
  create?: InputMaybe<FileCreateWithoutSub_FilesInput>;
};

export type FileCreateOrConnectWithoutCampaignInput = {
  create: FileCreateWithoutCampaignInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutCampaign_ActionInput = {
  create: FileCreateWithoutCampaign_ActionInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutClientInput = {
  create: FileCreateWithoutClientInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutContactInput = {
  create: FileCreateWithoutContactInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutMain_FileInput = {
  create: FileCreateWithoutMain_FileInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutProjectInput = {
  create: FileCreateWithoutProjectInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutSub_FilesInput = {
  create: FileCreateWithoutSub_FilesInput;
  where: FileWhereUniqueInput;
};

export type FileCreateWithoutCampaignInput = {
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateWithoutCampaign_ActionInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateWithoutClientInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateWithoutContactInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateWithoutMain_FileInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateWithoutProjectInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sub_files?: InputMaybe<FileCreateNestedManyWithoutMain_FileInput>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreateWithoutSub_FilesInput = {
  campaign?: InputMaybe<CampaignCreateNestedOneWithoutFilesInput>;
  campaign_action?: InputMaybe<Campaign_ActionCreateNestedManyWithoutFileInput>;
  client: ClientCreateNestedOneWithoutFilesInput;
  contact?: InputMaybe<ContactCreateNestedOneWithoutFilesInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  folder?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileCreateNestedOneWithoutSub_FilesInput>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<ProjectCreateNestedOneWithoutFilesInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sync_date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  sync_id?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<FileCreatetagsInput>;
  thumbnail_s3key?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FileCreatetagsInput = {
  set: Array<Tag_File>;
};

export type FileGroupBy = {
  __typename?: 'FileGroupBy';
  _avg?: Maybe<FileAvgAggregate>;
  _count?: Maybe<FileCountAggregate>;
  _max?: Maybe<FileMaxAggregate>;
  _min?: Maybe<FileMinAggregate>;
  _sum?: Maybe<FileSumAggregate>;
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id: Scalars['String']['output'];
  contact_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  folder?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_file_id?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  s3key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  sync_date?: Maybe<Scalars['DateTimeISO']['output']>;
  sync_id?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag_File>>;
  thumbnail_s3key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileListRelationFilter = {
  every?: InputMaybe<FileWhereInput>;
  none?: InputMaybe<FileWhereInput>;
  some?: InputMaybe<FileWhereInput>;
};

export type FileMaxAggregate = {
  __typename?: 'FileMaxAggregate';
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  folder?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  main_file_id?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  s3key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  sync_date?: Maybe<Scalars['DateTimeISO']['output']>;
  sync_id?: Maybe<Scalars['String']['output']>;
  thumbnail_s3key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileMaxOrderByAggregateInput = {
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  folder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  main_file_id?: InputMaybe<SortOrder>;
  mime?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  project_id?: InputMaybe<SortOrder>;
  s3key?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  sync_date?: InputMaybe<SortOrder>;
  sync_id?: InputMaybe<SortOrder>;
  thumbnail_s3key?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type FileMinAggregate = {
  __typename?: 'FileMinAggregate';
  campaign_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  folder?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  main_file_id?: Maybe<Scalars['String']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  project_id?: Maybe<Scalars['String']['output']>;
  s3key?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  sync_date?: Maybe<Scalars['DateTimeISO']['output']>;
  sync_id?: Maybe<Scalars['String']['output']>;
  thumbnail_s3key?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type FileMinOrderByAggregateInput = {
  campaign_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  folder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  main_file_id?: InputMaybe<SortOrder>;
  mime?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  project_id?: InputMaybe<SortOrder>;
  s3key?: InputMaybe<SortOrder>;
  size?: InputMaybe<SortOrder>;
  sync_date?: InputMaybe<SortOrder>;
  sync_id?: InputMaybe<SortOrder>;
  thumbnail_s3key?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type FileNullableRelationFilter = {
  is?: InputMaybe<FileWhereInput>;
  isNot?: InputMaybe<FileWhereInput>;
};

export type FileOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FileOrderByWithAggregationInput = {
  _avg?: InputMaybe<FileAvgOrderByAggregateInput>;
  _count?: InputMaybe<FileCountOrderByAggregateInput>;
  _max?: InputMaybe<FileMaxOrderByAggregateInput>;
  _min?: InputMaybe<FileMinOrderByAggregateInput>;
  _sum?: InputMaybe<FileSumOrderByAggregateInput>;
  campaign_id?: InputMaybe<SortOrderInput>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  folder?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  main_file_id?: InputMaybe<SortOrderInput>;
  mime?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  project_id?: InputMaybe<SortOrderInput>;
  s3key?: InputMaybe<SortOrderInput>;
  size?: InputMaybe<SortOrderInput>;
  sync_date?: InputMaybe<SortOrderInput>;
  sync_id?: InputMaybe<SortOrderInput>;
  tags?: InputMaybe<SortOrder>;
  thumbnail_s3key?: InputMaybe<SortOrderInput>;
  url?: InputMaybe<SortOrderInput>;
};

export type FileOrderByWithRelationInput = {
  campaign?: InputMaybe<CampaignOrderByWithRelationInput>;
  campaign_action?: InputMaybe<Campaign_ActionOrderByRelationAggregateInput>;
  campaign_id?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  contact?: InputMaybe<ContactOrderByWithRelationInput>;
  contact_id?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  folder?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  main_file?: InputMaybe<FileOrderByWithRelationInput>;
  main_file_id?: InputMaybe<SortOrderInput>;
  mime?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrderInput>;
  project?: InputMaybe<ProjectOrderByWithRelationInput>;
  project_id?: InputMaybe<SortOrderInput>;
  s3key?: InputMaybe<SortOrderInput>;
  size?: InputMaybe<SortOrderInput>;
  sub_files?: InputMaybe<FileOrderByRelationAggregateInput>;
  sync_date?: InputMaybe<SortOrderInput>;
  sync_id?: InputMaybe<SortOrderInput>;
  tags?: InputMaybe<SortOrder>;
  thumbnail_s3key?: InputMaybe<SortOrderInput>;
  url?: InputMaybe<SortOrderInput>;
};

export enum FileScalarFieldEnum {
  CampaignId = 'campaign_id',
  ClientId = 'client_id',
  ContactId = 'contact_id',
  Created = 'created',
  Deleted = 'deleted',
  Folder = 'folder',
  Id = 'id',
  MainFileId = 'main_file_id',
  Mime = 'mime',
  Name = 'name',
  ProjectId = 'project_id',
  S3key = 's3key',
  Size = 'size',
  SyncDate = 'sync_date',
  SyncId = 'sync_id',
  Tags = 'tags',
  ThumbnailS3key = 'thumbnail_s3key',
  Url = 'url'
}

export type FileScalarWhereInput = {
  AND?: InputMaybe<Array<FileScalarWhereInput>>;
  NOT?: InputMaybe<Array<FileScalarWhereInput>>;
  OR?: InputMaybe<Array<FileScalarWhereInput>>;
  campaign_id?: InputMaybe<UuidNullableFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact_id?: InputMaybe<UuidNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  folder?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  main_file_id?: InputMaybe<UuidNullableFilter>;
  mime?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  project_id?: InputMaybe<UuidNullableFilter>;
  s3key?: InputMaybe<StringNullableFilter>;
  size?: InputMaybe<IntNullableFilter>;
  sync_date?: InputMaybe<DateTimeNullableFilter>;
  sync_id?: InputMaybe<StringNullableFilter>;
  tags?: InputMaybe<Enumtag_FileNullableListFilter>;
  thumbnail_s3key?: InputMaybe<StringNullableFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type FileScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<FileScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<FileScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<FileScalarWhereWithAggregatesInput>>;
  campaign_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  contact_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  folder?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  main_file_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  mime?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  project_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  s3key?: InputMaybe<StringNullableWithAggregatesFilter>;
  size?: InputMaybe<IntNullableWithAggregatesFilter>;
  sync_date?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  sync_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  tags?: InputMaybe<Enumtag_FileNullableListFilter>;
  thumbnail_s3key?: InputMaybe<StringNullableWithAggregatesFilter>;
  url?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type FileSumAggregate = {
  __typename?: 'FileSumAggregate';
  size?: Maybe<Scalars['Int']['output']>;
};

export type FileSumOrderByAggregateInput = {
  size?: InputMaybe<SortOrder>;
};

/** The basic directions */
export enum FileSyncApiName {
  Craftnote = 'Craftnote',
  Hero = 'Hero',
  Memomeister = 'Memomeister'
}

export type FileUpdateInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateManyWithWhereWithoutCampaignInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutClientInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutContactInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutMain_FileInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutProjectInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithoutCampaignNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutCampaignInput>>;
  create?: InputMaybe<Array<FileCreateWithoutCampaignInput>>;
  createMany?: InputMaybe<FileCreateManyCampaignInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutCampaignInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutCampaignInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutCampaignInput>>;
};

export type FileUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<FileCreateWithoutClientInput>>;
  createMany?: InputMaybe<FileCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutClientInput>>;
};

export type FileUpdateManyWithoutContactNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<FileCreateWithoutContactInput>>;
  createMany?: InputMaybe<FileCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutContactInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutContactInput>>;
};

export type FileUpdateManyWithoutMain_FileNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutMain_FileInput>>;
  create?: InputMaybe<Array<FileCreateWithoutMain_FileInput>>;
  createMany?: InputMaybe<FileCreateManyMain_FileInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutMain_FileInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutMain_FileInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutMain_FileInput>>;
};

export type FileUpdateManyWithoutProjectNestedInput = {
  connect?: InputMaybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FileCreateOrConnectWithoutProjectInput>>;
  create?: InputMaybe<Array<FileCreateWithoutProjectInput>>;
  createMany?: InputMaybe<FileCreateManyProjectInputEnvelope>;
  delete?: InputMaybe<Array<FileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FileWhereUniqueInput>>;
  set?: InputMaybe<Array<FileWhereUniqueInput>>;
  update?: InputMaybe<Array<FileUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: InputMaybe<Array<FileUpdateManyWithWhereWithoutProjectInput>>;
  upsert?: InputMaybe<Array<FileUpsertWithWhereUniqueWithoutProjectInput>>;
};

export type FileUpdateOneWithoutCampaign_ActionNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutCampaign_ActionInput>;
  create?: InputMaybe<FileCreateWithoutCampaign_ActionInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutCampaign_ActionInput>;
  upsert?: InputMaybe<FileUpsertWithoutCampaign_ActionInput>;
};

export type FileUpdateOneWithoutSub_FilesNestedInput = {
  connect?: InputMaybe<FileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FileCreateOrConnectWithoutSub_FilesInput>;
  create?: InputMaybe<FileCreateWithoutSub_FilesInput>;
  delete?: InputMaybe<FileWhereInput>;
  disconnect?: InputMaybe<FileWhereInput>;
  update?: InputMaybe<FileUpdateToOneWithWhereWithoutSub_FilesInput>;
  upsert?: InputMaybe<FileUpsertWithoutSub_FilesInput>;
};

export type FileUpdateToOneWithWhereWithoutCampaign_ActionInput = {
  data: FileUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateToOneWithWhereWithoutSub_FilesInput = {
  data: FileUpdateWithoutSub_FilesInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpdateWithWhereUniqueWithoutCampaignInput = {
  data: FileUpdateWithoutCampaignInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutClientInput = {
  data: FileUpdateWithoutClientInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutContactInput = {
  data: FileUpdateWithoutContactInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutMain_FileInput = {
  data: FileUpdateWithoutMain_FileInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutProjectInput = {
  data: FileUpdateWithoutProjectInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithoutCampaignInput = {
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutCampaign_ActionInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutClientInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutContactInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutMain_FileInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutProjectInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sub_files?: InputMaybe<FileUpdateManyWithoutMain_FileNestedInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdateWithoutSub_FilesInput = {
  campaign?: InputMaybe<CampaignUpdateOneWithoutFilesNestedInput>;
  campaign_action?: InputMaybe<Campaign_ActionUpdateManyWithoutFileNestedInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutFilesNestedInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutFilesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  folder?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  main_file?: InputMaybe<FileUpdateOneWithoutSub_FilesNestedInput>;
  mime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  project?: InputMaybe<ProjectUpdateOneWithoutFilesNestedInput>;
  s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  size?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  sync_date?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sync_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<FileUpdatetagsInput>;
  thumbnail_s3key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FileUpdatetagsInput = {
  push?: InputMaybe<Array<Tag_File>>;
  set?: InputMaybe<Array<Tag_File>>;
};

export type FileUpsertWithWhereUniqueWithoutCampaignInput = {
  create: FileCreateWithoutCampaignInput;
  update: FileUpdateWithoutCampaignInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutClientInput = {
  create: FileCreateWithoutClientInput;
  update: FileUpdateWithoutClientInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutContactInput = {
  create: FileCreateWithoutContactInput;
  update: FileUpdateWithoutContactInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutMain_FileInput = {
  create: FileCreateWithoutMain_FileInput;
  update: FileUpdateWithoutMain_FileInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutProjectInput = {
  create: FileCreateWithoutProjectInput;
  update: FileUpdateWithoutProjectInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithoutCampaign_ActionInput = {
  create: FileCreateWithoutCampaign_ActionInput;
  update: FileUpdateWithoutCampaign_ActionInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileUpsertWithoutSub_FilesInput = {
  create: FileCreateWithoutSub_FilesInput;
  update: FileUpdateWithoutSub_FilesInput;
  where?: InputMaybe<FileWhereInput>;
};

export type FileWhereInput = {
  AND?: InputMaybe<Array<FileWhereInput>>;
  NOT?: InputMaybe<Array<FileWhereInput>>;
  OR?: InputMaybe<Array<FileWhereInput>>;
  campaign?: InputMaybe<CampaignNullableRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  campaign_id?: InputMaybe<UuidNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact?: InputMaybe<ContactNullableRelationFilter>;
  contact_id?: InputMaybe<UuidNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  folder?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  main_file?: InputMaybe<FileNullableRelationFilter>;
  main_file_id?: InputMaybe<UuidNullableFilter>;
  mime?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectNullableRelationFilter>;
  project_id?: InputMaybe<UuidNullableFilter>;
  s3key?: InputMaybe<StringNullableFilter>;
  size?: InputMaybe<IntNullableFilter>;
  sub_files?: InputMaybe<FileListRelationFilter>;
  sync_date?: InputMaybe<DateTimeNullableFilter>;
  sync_id?: InputMaybe<StringNullableFilter>;
  tags?: InputMaybe<Enumtag_FileNullableListFilter>;
  thumbnail_s3key?: InputMaybe<StringNullableFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type FileWhereUniqueInput = {
  AND?: InputMaybe<Array<FileWhereInput>>;
  NOT?: InputMaybe<Array<FileWhereInput>>;
  OR?: InputMaybe<Array<FileWhereInput>>;
  campaign?: InputMaybe<CampaignNullableRelationFilter>;
  campaign_action?: InputMaybe<Campaign_ActionListRelationFilter>;
  campaign_id?: InputMaybe<UuidNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact?: InputMaybe<ContactNullableRelationFilter>;
  contact_id?: InputMaybe<UuidNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  folder?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  main_file?: InputMaybe<FileNullableRelationFilter>;
  main_file_id?: InputMaybe<UuidNullableFilter>;
  mime?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  project?: InputMaybe<ProjectNullableRelationFilter>;
  project_id?: InputMaybe<UuidNullableFilter>;
  s3key?: InputMaybe<StringNullableFilter>;
  size?: InputMaybe<IntNullableFilter>;
  sub_files?: InputMaybe<FileListRelationFilter>;
  sync_date?: InputMaybe<DateTimeNullableFilter>;
  sync_id?: InputMaybe<StringNullableFilter>;
  tags?: InputMaybe<Enumtag_FileNullableListFilter>;
  thumbnail_s3key?: InputMaybe<StringNullableFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

/** Folder Type */
export type Folder = {
  __typename?: 'Folder';
  containsTemplates: Scalars['Boolean']['output'];
  documentCountWithSubfolder: Scalars['Float']['output'];
  emoteIcon: Scalars['String']['output'];
  hasAccessOnDetails: Scalars['Boolean']['output'];
  hasSubfolders: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  isArchived: Scalars['Boolean']['output'];
  isArchivedDirectly: Scalars['Boolean']['output'];
  isCreatedByCurrentUser: Scalars['Boolean']['output'];
  isCurrentUserFavorite: Scalars['Boolean']['output'];
  isRemoved: Scalars['Boolean']['output'];
  isShared: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

/** Folder Filter Args */
export type FolderListFilter = {
  first: Scalars['Float']['input'];
  parentId: Scalars['String']['input'];
  sortDirection: ProjectSyncFolderSortDirection;
  sortOrderBy: ProjectSyncFolderSortOrderBy;
};

/** Folder Template Object */
export type FolderTemplate = {
  __typename?: 'FolderTemplate';
  description: Scalars['String']['output'];
  folderId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  labels: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissionsGroups: Array<GroupType>;
};

/** Folder Templates */
export type FolderTemplates = {
  __typename?: 'FolderTemplates';
  folderTemplates: Array<FolderTemplate>;
};

export type GetFileInput = {
  key: Scalars['String']['input'];
};

/** Signed Url Get Response */
export type GetFileOutput = {
  __typename?: 'GetFileOutput';
  mime: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetFilePartInput = {
  key: Scalars['String']['input'];
  part_number: Scalars['Float']['input'];
  upload_id: Scalars['String']['input'];
};

/** Folder Template Group Type Object */
export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['String']['output'];
  isSystem?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

/** Groups Type */
export type Groups = {
  __typename?: 'Groups';
  id: Scalars['String']['output'];
  isSystem?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type InsertActionResponse = {
  __typename?: 'InsertActionResponse';
  campaign_id: Scalars['String']['output'];
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  ext_file_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  main_state: Campaign_Action_Main_State;
  message?: Maybe<Scalars['String']['output']>;
  sub_state: Campaign_Action_Sub_State;
  url?: Maybe<Scalars['String']['output']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type LabelsReplacement = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type LinkFilesToProjectInput = {
  file_id: Scalars['String']['input'];
  project_id: Scalars['String']['input'];
};

export type List = {
  __typename?: 'List';
  client: Client;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<ListType>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ListCountAggregate = {
  __typename?: 'ListCountAggregate';
  _all: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  data: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type ListCountOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type ListCreateInput = {
  client: ClientCreateNestedOneWithoutListsInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ListType>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ListCreateManyClientInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ListType>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ListCreateManyClientInputEnvelope = {
  data: Array<ListCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListCreateManyInput = {
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ListType>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ListCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ListWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ListCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ListCreateWithoutClientInput>>;
  createMany?: InputMaybe<ListCreateManyClientInputEnvelope>;
};

export type ListCreateOrConnectWithoutClientInput = {
  create: ListCreateWithoutClientInput;
  where: ListWhereUniqueInput;
};

export type ListCreateWithoutClientInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type?: InputMaybe<ListType>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type ListGroupBy = {
  __typename?: 'ListGroupBy';
  _count?: Maybe<ListCountAggregate>;
  _max?: Maybe<ListMaxAggregate>;
  _min?: Maybe<ListMinAggregate>;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<ListType>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ListListRelationFilter = {
  every?: InputMaybe<ListWhereInput>;
  none?: InputMaybe<ListWhereInput>;
  some?: InputMaybe<ListWhereInput>;
};

export type ListMaxAggregate = {
  __typename?: 'ListMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ListType>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ListMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type ListMinAggregate = {
  __typename?: 'ListMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ListType>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ListMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type ListOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ListOrderByWithAggregationInput = {
  _count?: InputMaybe<ListCountOrderByAggregateInput>;
  _max?: InputMaybe<ListMaxOrderByAggregateInput>;
  _min?: InputMaybe<ListMinOrderByAggregateInput>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export type ListOrderByWithRelationInput = {
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export enum ListScalarFieldEnum {
  ClientId = 'client_id',
  Created = 'created',
  Data = 'data',
  Deleted = 'deleted',
  Id = 'id',
  Name = 'name',
  Title = 'title',
  Type = 'type',
  Updated = 'updated'
}

export type ListScalarWhereInput = {
  AND?: InputMaybe<Array<ListScalarWhereInput>>;
  NOT?: InputMaybe<Array<ListScalarWhereInput>>;
  OR?: InputMaybe<Array<ListScalarWhereInput>>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumlistTypeNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type ListScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ListScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ListScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ListScalarWhereWithAggregatesInput>>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  data?: InputMaybe<JsonNullableWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<EnumlistTypeNullableWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type ListUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutListsNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumlistTypeFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ListUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumlistTypeFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ListUpdateManyWithWhereWithoutClientInput = {
  data: ListUpdateManyMutationInput;
  where: ListScalarWhereInput;
};

export type ListUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ListWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ListCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ListCreateWithoutClientInput>>;
  createMany?: InputMaybe<ListCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ListWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ListScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ListWhereUniqueInput>>;
  set?: InputMaybe<Array<ListWhereUniqueInput>>;
  update?: InputMaybe<Array<ListUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ListUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ListUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ListUpdateWithWhereUniqueWithoutClientInput = {
  data: ListUpdateWithoutClientInput;
  where: ListWhereUniqueInput;
};

export type ListUpdateWithoutClientInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumlistTypeFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type ListUpsertWithWhereUniqueWithoutClientInput = {
  create: ListCreateWithoutClientInput;
  update: ListUpdateWithoutClientInput;
  where: ListWhereUniqueInput;
};

export type ListWhereInput = {
  AND?: InputMaybe<Array<ListWhereInput>>;
  NOT?: InputMaybe<Array<ListWhereInput>>;
  OR?: InputMaybe<Array<ListWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumlistTypeNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type ListWhereUniqueInput = {
  AND?: InputMaybe<Array<ListWhereInput>>;
  NOT?: InputMaybe<Array<ListWhereInput>>;
  OR?: InputMaybe<Array<ListWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumlistTypeNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type Mail_Template = {
  __typename?: 'Mail_template';
  client: Client;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  scenario_name: Mail_Scenario;
  template_name?: Maybe<Mail_Template_Name>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Mail_TemplateCountAggregate = {
  __typename?: 'Mail_templateCountAggregate';
  _all: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  scenario_name: Scalars['Int']['output'];
  template_name: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type Mail_TemplateCountOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  template_name?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type Mail_TemplateCreateInput = {
  client: ClientCreateNestedOneWithoutMail_TemplatesInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scenario_name: Mail_Scenario;
  template_name?: InputMaybe<Mail_Template_Name>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Mail_TemplateCreateManyClientInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scenario_name: Mail_Scenario;
  template_name?: InputMaybe<Mail_Template_Name>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Mail_TemplateCreateManyClientInputEnvelope = {
  data: Array<Mail_TemplateCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mail_TemplateCreateManyInput = {
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scenario_name: Mail_Scenario;
  template_name?: InputMaybe<Mail_Template_Name>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Mail_TemplateCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<Mail_TemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Mail_TemplateCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Mail_TemplateCreateWithoutClientInput>>;
  createMany?: InputMaybe<Mail_TemplateCreateManyClientInputEnvelope>;
};

export type Mail_TemplateCreateOrConnectWithoutClientInput = {
  create: Mail_TemplateCreateWithoutClientInput;
  where: Mail_TemplateWhereUniqueInput;
};

export type Mail_TemplateCreateWithoutClientInput = {
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  scenario_name: Mail_Scenario;
  template_name?: InputMaybe<Mail_Template_Name>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Mail_TemplateGroupBy = {
  __typename?: 'Mail_templateGroupBy';
  _count?: Maybe<Mail_TemplateCountAggregate>;
  _max?: Maybe<Mail_TemplateMaxAggregate>;
  _min?: Maybe<Mail_TemplateMinAggregate>;
  client_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  scenario_name: Mail_Scenario;
  template_name?: Maybe<Mail_Template_Name>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Mail_TemplateListRelationFilter = {
  every?: InputMaybe<Mail_TemplateWhereInput>;
  none?: InputMaybe<Mail_TemplateWhereInput>;
  some?: InputMaybe<Mail_TemplateWhereInput>;
};

export type Mail_TemplateMaxAggregate = {
  __typename?: 'Mail_templateMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  scenario_name?: Maybe<Mail_Scenario>;
  template_name?: Maybe<Mail_Template_Name>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Mail_TemplateMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  template_name?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type Mail_TemplateMinAggregate = {
  __typename?: 'Mail_templateMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  scenario_name?: Maybe<Mail_Scenario>;
  template_name?: Maybe<Mail_Template_Name>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Mail_TemplateMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  template_name?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type Mail_TemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Mail_TemplateOrderByWithAggregationInput = {
  _count?: InputMaybe<Mail_TemplateCountOrderByAggregateInput>;
  _max?: InputMaybe<Mail_TemplateMaxOrderByAggregateInput>;
  _min?: InputMaybe<Mail_TemplateMinOrderByAggregateInput>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  template_name?: InputMaybe<SortOrderInput>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrderInput>;
};

export type Mail_TemplateOrderByWithRelationInput = {
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  template_name?: InputMaybe<SortOrderInput>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrderInput>;
};

export enum Mail_TemplateScalarFieldEnum {
  ClientId = 'client_id',
  Created = 'created',
  Description = 'description',
  Id = 'id',
  ScenarioName = 'scenario_name',
  TemplateName = 'template_name',
  Text = 'text',
  Title = 'title',
  Updated = 'updated'
}

export type Mail_TemplateScalarWhereInput = {
  AND?: InputMaybe<Array<Mail_TemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<Mail_TemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<Mail_TemplateScalarWhereInput>>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  scenario_name?: InputMaybe<Enummail_ScenarioFilter>;
  template_name?: InputMaybe<Enummail_Template_NameNullableFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type Mail_TemplateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Mail_TemplateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Mail_TemplateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Mail_TemplateScalarWhereWithAggregatesInput>>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  scenario_name?: InputMaybe<Enummail_ScenarioWithAggregatesFilter>;
  template_name?: InputMaybe<Enummail_Template_NameNullableWithAggregatesFilter>;
  text?: InputMaybe<StringWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type Mail_TemplateUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutMail_TemplatesNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enummail_ScenarioFieldUpdateOperationsInput>;
  template_name?: InputMaybe<NullableEnummail_Template_NameFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Mail_TemplateUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enummail_ScenarioFieldUpdateOperationsInput>;
  template_name?: InputMaybe<NullableEnummail_Template_NameFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Mail_TemplateUpdateManyWithWhereWithoutClientInput = {
  data: Mail_TemplateUpdateManyMutationInput;
  where: Mail_TemplateScalarWhereInput;
};

export type Mail_TemplateUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<Mail_TemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Mail_TemplateCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Mail_TemplateCreateWithoutClientInput>>;
  createMany?: InputMaybe<Mail_TemplateCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<Mail_TemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Mail_TemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Mail_TemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<Mail_TemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<Mail_TemplateUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<Mail_TemplateUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<Mail_TemplateUpsertWithWhereUniqueWithoutClientInput>>;
};

export type Mail_TemplateUpdateWithWhereUniqueWithoutClientInput = {
  data: Mail_TemplateUpdateWithoutClientInput;
  where: Mail_TemplateWhereUniqueInput;
};

export type Mail_TemplateUpdateWithoutClientInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enummail_ScenarioFieldUpdateOperationsInput>;
  template_name?: InputMaybe<NullableEnummail_Template_NameFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Mail_TemplateUpsertWithWhereUniqueWithoutClientInput = {
  create: Mail_TemplateCreateWithoutClientInput;
  update: Mail_TemplateUpdateWithoutClientInput;
  where: Mail_TemplateWhereUniqueInput;
};

export type Mail_TemplateWhereInput = {
  AND?: InputMaybe<Array<Mail_TemplateWhereInput>>;
  NOT?: InputMaybe<Array<Mail_TemplateWhereInput>>;
  OR?: InputMaybe<Array<Mail_TemplateWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  scenario_name?: InputMaybe<Enummail_ScenarioFilter>;
  template_name?: InputMaybe<Enummail_Template_NameNullableFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type Mail_TemplateWhereUniqueInput = {
  AND?: InputMaybe<Array<Mail_TemplateWhereInput>>;
  NOT?: InputMaybe<Array<Mail_TemplateWhereInput>>;
  OR?: InputMaybe<Array<Mail_TemplateWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  created?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  scenario_name?: InputMaybe<Enummail_ScenarioFilter>;
  template_name?: InputMaybe<Enummail_Template_NameNullableFilter>;
  text?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

/** Main Config Object */
export type MainConfig = {
  __typename?: 'MainConfig';
  bucket: Scalars['String']['output'];
  client_id: Scalars['String']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  domain: Scalars['String']['output'];
  identity_pool_id: Scalars['String']['output'];
  logo: Scalars['String']['output'];
  styles: Scalars['JSONObject']['output'];
  user_pool_id: Scalars['String']['output'];
};

export type MainConfigInput = {
  domain: Scalars['String']['input'];
};

export type Message = {
  __typename?: 'Message';
  agents: Array<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  read?: Maybe<Scalars['JSON']['output']>;
  subject: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MessageCountAggregate = {
  __typename?: 'MessageCountAggregate';
  _all: Scalars['Int']['output'];
  agents: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  read: Scalars['Int']['output'];
  subject: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
};

export type MessageCountOrderByAggregateInput = {
  agents?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  read?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export type MessageCreateInput = {
  agents?: InputMaybe<MessageCreateagentsInput>;
  client: ClientCreateNestedOneWithoutMessageInput;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type MessageCreateManyClientInput = {
  agents?: InputMaybe<MessageCreateagentsInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type MessageCreateManyClientInputEnvelope = {
  data: Array<MessageCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageCreateManyInput = {
  agents?: InputMaybe<MessageCreateagentsInput>;
  client_id: Scalars['String']['input'];
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type MessageCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutClientInput>>;
  createMany?: InputMaybe<MessageCreateManyClientInputEnvelope>;
};

export type MessageCreateOrConnectWithoutClientInput = {
  create: MessageCreateWithoutClientInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateWithoutClientInput = {
  agents?: InputMaybe<MessageCreateagentsInput>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type MessageCreateagentsInput = {
  set: Array<Scalars['String']['input']>;
};

export type MessageGroupBy = {
  __typename?: 'MessageGroupBy';
  _count?: Maybe<MessageCountAggregate>;
  _max?: Maybe<MessageMaxAggregate>;
  _min?: Maybe<MessageMinAggregate>;
  agents?: Maybe<Array<Scalars['String']['output']>>;
  client_id: Scalars['String']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  read?: Maybe<Scalars['JSON']['output']>;
  subject: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type MessageListRelationFilter = {
  every?: InputMaybe<MessageWhereInput>;
  none?: InputMaybe<MessageWhereInput>;
  some?: InputMaybe<MessageWhereInput>;
};

export type MessageMaxAggregate = {
  __typename?: 'MessageMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type MessageMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export type MessageMinAggregate = {
  __typename?: 'MessageMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type MessageMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  subject?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export type MessageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MessageOrderByWithAggregationInput = {
  _count?: InputMaybe<MessageCountOrderByAggregateInput>;
  _max?: InputMaybe<MessageMaxOrderByAggregateInput>;
  _min?: InputMaybe<MessageMinOrderByAggregateInput>;
  agents?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  read?: InputMaybe<SortOrderInput>;
  subject?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export type MessageOrderByWithRelationInput = {
  agents?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  read?: InputMaybe<SortOrderInput>;
  subject?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export enum MessageScalarFieldEnum {
  Agents = 'agents',
  ClientId = 'client_id',
  Deleted = 'deleted',
  Id = 'id',
  Read = 'read',
  Subject = 'subject',
  Text = 'text'
}

export type MessageScalarWhereInput = {
  AND?: InputMaybe<Array<MessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<MessageScalarWhereInput>>;
  OR?: InputMaybe<Array<MessageScalarWhereInput>>;
  agents?: InputMaybe<StringNullableListFilter>;
  client_id?: InputMaybe<StringFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  read?: InputMaybe<JsonNullableFilter>;
  subject?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type MessageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MessageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MessageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MessageScalarWhereWithAggregatesInput>>;
  agents?: InputMaybe<StringNullableListFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  read?: InputMaybe<JsonNullableWithAggregatesFilter>;
  subject?: InputMaybe<StringWithAggregatesFilter>;
  text?: InputMaybe<StringWithAggregatesFilter>;
};

export type MessageUpdateInput = {
  agents?: InputMaybe<MessageUpdateagentsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutMessageNestedInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MessageUpdateManyMutationInput = {
  agents?: InputMaybe<MessageUpdateagentsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MessageUpdateManyWithWhereWithoutClientInput = {
  data: MessageUpdateManyMutationInput;
  where: MessageScalarWhereInput;
};

export type MessageUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutClientInput>>;
  createMany?: InputMaybe<MessageCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<MessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<MessageUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<MessageUpsertWithWhereUniqueWithoutClientInput>>;
};

export type MessageUpdateWithWhereUniqueWithoutClientInput = {
  data: MessageUpdateWithoutClientInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpdateWithoutClientInput = {
  agents?: InputMaybe<MessageUpdateagentsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  read?: InputMaybe<Scalars['JSON']['input']>;
  subject?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type MessageUpdateagentsInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MessageUpsertWithWhereUniqueWithoutClientInput = {
  create: MessageCreateWithoutClientInput;
  update: MessageUpdateWithoutClientInput;
  where: MessageWhereUniqueInput;
};

export type MessageWhereInput = {
  AND?: InputMaybe<Array<MessageWhereInput>>;
  NOT?: InputMaybe<Array<MessageWhereInput>>;
  OR?: InputMaybe<Array<MessageWhereInput>>;
  agents?: InputMaybe<StringNullableListFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  read?: InputMaybe<JsonNullableFilter>;
  subject?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type MessageWhereUniqueInput = {
  AND?: InputMaybe<Array<MessageWhereInput>>;
  NOT?: InputMaybe<Array<MessageWhereInput>>;
  OR?: InputMaybe<Array<MessageWhereInput>>;
  agents?: InputMaybe<StringNullableListFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<JsonNullableFilter>;
  subject?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  abortMultipartUpload: Scalars['Boolean']['output'];
  adminCreateAgent: AdminUserResponse;
  adminCreateClient: AdminCreateClientResponse;
  adminDeleteAgent: AdminDeleteAgentResponse;
  adminResendPassword: AdminResendPasswordResponse;
  adminUpdateAgent: AdminUserResponse;
  adminUpdateClient: AdminUpdateClientResponse;
  changeValidity: Scalars['Boolean']['output'];
  completeMultipartUpload: Scalars['Boolean']['output'];
  createManyAgent: AffectedRowsOutput;
  createManyAndReturnAgent: Array<CreateManyAndReturnAgent>;
  createManyAndReturnCampaign: Array<CreateManyAndReturnCampaign>;
  createManyAndReturnCampaign_action: Array<CreateManyAndReturnCampaign_Action>;
  createManyAndReturnClient: Array<CreateManyAndReturnClient>;
  createManyAndReturnConfig_campaign_client: Array<CreateManyAndReturnConfig_Campaign_Client>;
  createManyAndReturnConfig_global: Array<CreateManyAndReturnConfig_Global>;
  createManyAndReturnContact: Array<CreateManyAndReturnContact>;
  createManyAndReturnCountry: Array<CreateManyAndReturnCountry>;
  createManyAndReturnCron: Array<CreateManyAndReturnCron>;
  createManyAndReturnFile: Array<CreateManyAndReturnFile>;
  createManyAndReturnList: Array<CreateManyAndReturnList>;
  createManyAndReturnMail_template: Array<CreateManyAndReturnMail_Template>;
  createManyAndReturnMessage: Array<CreateManyAndReturnMessage>;
  createManyAndReturnParameter: Array<CreateManyAndReturnParameter>;
  createManyAndReturnProject: Array<CreateManyAndReturnProject>;
  createManyAndReturnSurvey_published: Array<CreateManyAndReturnSurvey_Published>;
  createManyAndReturnSurvey_result: Array<CreateManyAndReturnSurvey_Result>;
  createManyAndReturnSurvey_schema: Array<CreateManyAndReturnSurvey_Schema>;
  createManyCampaign: AffectedRowsOutput;
  createManyCampaign_action: AffectedRowsOutput;
  createManyClient: AffectedRowsOutput;
  createManyConfig_campaign_client: AffectedRowsOutput;
  createManyConfig_global: AffectedRowsOutput;
  createManyContact: AffectedRowsOutput;
  createManyCountry: AffectedRowsOutput;
  createManyCron: AffectedRowsOutput;
  createManyFile: AffectedRowsOutput;
  createManyList: AffectedRowsOutput;
  createManyMail_template: AffectedRowsOutput;
  createManyMessage: AffectedRowsOutput;
  createManyParameter: AffectedRowsOutput;
  createManyProject: AffectedRowsOutput;
  createManySurvey_published: AffectedRowsOutput;
  createManySurvey_result: AffectedRowsOutput;
  createManySurvey_schema: AffectedRowsOutput;
  createMultipartUpload: StartMultipartUploadOutput;
  createOneAgent: Agent;
  createOneCampaign: Campaign;
  createOneCampaign_action: Campaign_Action;
  createOneClient: Client;
  createOneConfig_campaign_client: Config_Campaign_Client;
  createOneConfig_global: Config_Global;
  createOneContact: Contact;
  createOneCountry: Country;
  createOneCron: Cron;
  /** Create a new file with Sync */
  createOneFile: File;
  createOneList: List;
  createOneMail_template: Mail_Template;
  createOneMessage: Message;
  createOneParameter: Parameter;
  createOneProject: Project;
  createOneSurvey_published: Survey_Published;
  createOneSurvey_result: Survey_Result;
  createOneSurvey_schema: Survey_Schema;
  deleteManyAgent: AffectedRowsOutput;
  deleteManyCampaign: AffectedRowsOutput;
  deleteManyCampaign_action: AffectedRowsOutput;
  deleteManyClient: AffectedRowsOutput;
  deleteManyConfig_campaign_client: AffectedRowsOutput;
  deleteManyConfig_global: AffectedRowsOutput;
  deleteManyContact: AffectedRowsOutput;
  deleteManyCountry: AffectedRowsOutput;
  deleteManyCron: AffectedRowsOutput;
  deleteManyFile: AffectedRowsOutput;
  deleteManyList: AffectedRowsOutput;
  deleteManyMail_template: AffectedRowsOutput;
  deleteManyMessage: AffectedRowsOutput;
  deleteManyParameter: AffectedRowsOutput;
  deleteManyProject: AffectedRowsOutput;
  deleteManySurvey_published: AffectedRowsOutput;
  deleteManySurvey_result: AffectedRowsOutput;
  deleteManySurvey_schema: AffectedRowsOutput;
  deleteOneAgent?: Maybe<Agent>;
  deleteOneCampaign?: Maybe<Campaign>;
  deleteOneCampaign_action?: Maybe<Campaign_Action>;
  deleteOneClient?: Maybe<Client>;
  deleteOneConfig_campaign_client?: Maybe<Config_Campaign_Client>;
  deleteOneConfig_global?: Maybe<Config_Global>;
  deleteOneContact?: Maybe<Contact>;
  deleteOneCountry?: Maybe<Country>;
  deleteOneCron?: Maybe<Cron>;
  deleteOneFile?: Maybe<File>;
  deleteOneList?: Maybe<List>;
  deleteOneMail_template?: Maybe<Mail_Template>;
  deleteOneMessage?: Maybe<Message>;
  deleteOneParameter?: Maybe<Parameter>;
  deleteOneProject?: Maybe<Project>;
  deleteOneSurvey_published?: Maybe<Survey_Published>;
  deleteOneSurvey_result?: Maybe<Survey_Result>;
  deleteOneSurvey_schema?: Maybe<Survey_Schema>;
  endCampaign: InsertActionResponse;
  insertActionDocumentSigned: InsertActionResponse;
  insertActionVideoPlaybackCompleted: InsertActionResponse;
  insertActionVideoRecorded: InsertActionResponse;
  insertFeedback: InsertActionResponse;
  insertGoogleRating: InsertActionResponse;
  insertParameterCrypt: ParameterOutput;
  insertRating: InsertActionResponse;
  linkFilesToProject: Scalars['Boolean']['output'];
  offerCampaignAction: Array<OfferCampaignActionResponse>;
  signedUrlFileGet: GetFileOutput;
  signedUrlFilePartGet: GetFileOutput;
  signedUrlFilePost: PostFileOutput;
  signedUrlFilePut: Scalars['String']['output'];
  startAppointmentCampaign: StartCampaignResponse;
  startOfferCampaign: StartCampaignResponse;
  startRatingCampaign: StartCampaignResponse;
  updateManyAgent: AffectedRowsOutput;
  updateManyCampaign: AffectedRowsOutput;
  updateManyCampaign_action: AffectedRowsOutput;
  updateManyClient: AffectedRowsOutput;
  updateManyConfig_campaign_client: AffectedRowsOutput;
  updateManyConfig_global: AffectedRowsOutput;
  updateManyContact: AffectedRowsOutput;
  updateManyCountry: AffectedRowsOutput;
  updateManyCron: AffectedRowsOutput;
  updateManyFile: AffectedRowsOutput;
  updateManyList: AffectedRowsOutput;
  updateManyMail_template: AffectedRowsOutput;
  updateManyMessage: AffectedRowsOutput;
  updateManyParameter: AffectedRowsOutput;
  updateManyProject: AffectedRowsOutput;
  updateManySurvey_published: AffectedRowsOutput;
  updateManySurvey_result: AffectedRowsOutput;
  updateManySurvey_schema: AffectedRowsOutput;
  updateOneAgent?: Maybe<Agent>;
  updateOneCampaign?: Maybe<Campaign>;
  updateOneCampaign_action?: Maybe<Campaign_Action>;
  updateOneClient?: Maybe<Client>;
  updateOneConfig_campaign_client?: Maybe<Config_Campaign_Client>;
  updateOneConfig_global?: Maybe<Config_Global>;
  updateOneContact?: Maybe<Contact>;
  updateOneCountry?: Maybe<Country>;
  updateOneCron?: Maybe<Cron>;
  updateOneFile?: Maybe<File>;
  updateOneList?: Maybe<List>;
  updateOneMail_template?: Maybe<Mail_Template>;
  updateOneMessage?: Maybe<Message>;
  updateOneParameter?: Maybe<Parameter>;
  updateOneProject?: Maybe<Project>;
  updateOneSurvey_published?: Maybe<Survey_Published>;
  updateOneSurvey_result?: Maybe<Survey_Result>;
  updateOneSurvey_schema?: Maybe<Survey_Schema>;
  updateParameterCrypt: ParameterOutput;
  upsertOneAgent: Agent;
  upsertOneCampaign: Campaign;
  upsertOneCampaign_action: Campaign_Action;
  upsertOneClient: Client;
  upsertOneConfig_campaign_client: Config_Campaign_Client;
  upsertOneConfig_global: Config_Global;
  upsertOneContact: Contact;
  upsertOneCountry: Country;
  upsertOneCron: Cron;
  upsertOneFile: File;
  upsertOneList: List;
  upsertOneMail_template: Mail_Template;
  upsertOneMessage: Message;
  upsertOneParameter: Parameter;
  upsertOneProject: Project;
  upsertOneSurvey_published: Survey_Published;
  upsertOneSurvey_result: Survey_Result;
  upsertOneSurvey_schema: Survey_Schema;
};


export type MutationAbortMultipartUploadArgs = {
  input: AbortMultipartUploadInput;
};


export type MutationAdminCreateAgentArgs = {
  agent_data: AdminCreateAgentInput;
};


export type MutationAdminCreateClientArgs = {
  client_data: AdminCreateClientInput;
};


export type MutationAdminDeleteAgentArgs = {
  input: AdminDeleteAgentInput;
};


export type MutationAdminResendPasswordArgs = {
  input: AdminResendPasswordInput;
};


export type MutationAdminUpdateAgentArgs = {
  agent_data: AdminUpdateAgentInput;
};


export type MutationAdminUpdateClientArgs = {
  update_data: AdminUpdateClientInput;
};


export type MutationChangeValidityArgs = {
  input: CronValidityInput;
};


export type MutationCompleteMultipartUploadArgs = {
  input: CompleteMultipartUploadInput;
};


export type MutationCreateManyAgentArgs = {
  data: Array<AgentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnAgentArgs = {
  data: Array<AgentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnCampaignArgs = {
  data: Array<CampaignCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnCampaign_ActionArgs = {
  data: Array<Campaign_ActionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnClientArgs = {
  data: Array<ClientCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnConfig_Campaign_ClientArgs = {
  data: Array<Config_Campaign_ClientCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnConfig_GlobalArgs = {
  data: Array<Config_GlobalCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnContactArgs = {
  data: Array<ContactCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnCountryArgs = {
  data: Array<CountryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnCronArgs = {
  data: Array<CronCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnFileArgs = {
  data: Array<FileCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnListArgs = {
  data: Array<ListCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnMail_TemplateArgs = {
  data: Array<Mail_TemplateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnMessageArgs = {
  data: Array<MessageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnParameterArgs = {
  data: Array<ParameterCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnProjectArgs = {
  data: Array<ProjectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnSurvey_PublishedArgs = {
  data: Array<Survey_PublishedCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnSurvey_ResultArgs = {
  data: Array<Survey_ResultCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyAndReturnSurvey_SchemaArgs = {
  data: Array<Survey_SchemaCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCampaignArgs = {
  data: Array<CampaignCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCampaign_ActionArgs = {
  data: Array<Campaign_ActionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyClientArgs = {
  data: Array<ClientCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyConfig_Campaign_ClientArgs = {
  data: Array<Config_Campaign_ClientCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyConfig_GlobalArgs = {
  data: Array<Config_GlobalCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyContactArgs = {
  data: Array<ContactCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCountryArgs = {
  data: Array<CountryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyCronArgs = {
  data: Array<CronCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyFileArgs = {
  data: Array<FileCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyListArgs = {
  data: Array<ListCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMail_TemplateArgs = {
  data: Array<Mail_TemplateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyMessageArgs = {
  data: Array<MessageCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyParameterArgs = {
  data: Array<ParameterCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyProjectArgs = {
  data: Array<ProjectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySurvey_PublishedArgs = {
  data: Array<Survey_PublishedCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySurvey_ResultArgs = {
  data: Array<Survey_ResultCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManySurvey_SchemaArgs = {
  data: Array<Survey_SchemaCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateMultipartUploadArgs = {
  input: StartMultipartUploadInput;
};


export type MutationCreateOneAgentArgs = {
  data: AgentCreateInput;
};


export type MutationCreateOneCampaignArgs = {
  data: CampaignCreateInput;
};


export type MutationCreateOneCampaign_ActionArgs = {
  data: Campaign_ActionCreateInput;
};


export type MutationCreateOneClientArgs = {
  data: ClientCreateInput;
};


export type MutationCreateOneConfig_Campaign_ClientArgs = {
  data: Config_Campaign_ClientCreateInput;
};


export type MutationCreateOneConfig_GlobalArgs = {
  data: Config_GlobalCreateInput;
};


export type MutationCreateOneContactArgs = {
  data: ContactCreateInput;
};


export type MutationCreateOneCountryArgs = {
  data: CountryCreateInput;
};


export type MutationCreateOneCronArgs = {
  data: CronCreateInput;
};


export type MutationCreateOneFileArgs = {
  data: FileCreateInput;
};


export type MutationCreateOneListArgs = {
  data: ListCreateInput;
};


export type MutationCreateOneMail_TemplateArgs = {
  data: Mail_TemplateCreateInput;
};


export type MutationCreateOneMessageArgs = {
  data: MessageCreateInput;
};


export type MutationCreateOneParameterArgs = {
  data: ParameterCreateInput;
};


export type MutationCreateOneProjectArgs = {
  project: ProjectCreateSyncInput;
};


export type MutationCreateOneSurvey_PublishedArgs = {
  data: Survey_PublishedCreateInput;
};


export type MutationCreateOneSurvey_ResultArgs = {
  data: Survey_ResultCreateInput;
};


export type MutationCreateOneSurvey_SchemaArgs = {
  data: Survey_SchemaCreateInput;
};


export type MutationDeleteManyAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type MutationDeleteManyCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type MutationDeleteManyCampaign_ActionArgs = {
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type MutationDeleteManyClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};


export type MutationDeleteManyConfig_Campaign_ClientArgs = {
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type MutationDeleteManyConfig_GlobalArgs = {
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type MutationDeleteManyContactArgs = {
  where?: InputMaybe<ContactWhereInput>;
};


export type MutationDeleteManyCountryArgs = {
  where?: InputMaybe<CountryWhereInput>;
};


export type MutationDeleteManyCronArgs = {
  where?: InputMaybe<CronWhereInput>;
};


export type MutationDeleteManyFileArgs = {
  where?: InputMaybe<FileWhereInput>;
};


export type MutationDeleteManyListArgs = {
  where?: InputMaybe<ListWhereInput>;
};


export type MutationDeleteManyMail_TemplateArgs = {
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type MutationDeleteManyMessageArgs = {
  where?: InputMaybe<MessageWhereInput>;
};


export type MutationDeleteManyParameterArgs = {
  where?: InputMaybe<ParameterWhereInput>;
};


export type MutationDeleteManyProjectArgs = {
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationDeleteManySurvey_PublishedArgs = {
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type MutationDeleteManySurvey_ResultArgs = {
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type MutationDeleteManySurvey_SchemaArgs = {
  where?: InputMaybe<Survey_SchemaWhereInput>;
};


export type MutationDeleteOneAgentArgs = {
  where: AgentWhereUniqueInput;
};


export type MutationDeleteOneCampaignArgs = {
  where: CampaignWhereUniqueInput;
};


export type MutationDeleteOneCampaign_ActionArgs = {
  where: Campaign_ActionWhereUniqueInput;
};


export type MutationDeleteOneClientArgs = {
  where: ClientWhereUniqueInput;
};


export type MutationDeleteOneConfig_Campaign_ClientArgs = {
  where: Config_Campaign_ClientWhereUniqueInput;
};


export type MutationDeleteOneConfig_GlobalArgs = {
  where: Config_GlobalWhereUniqueInput;
};


export type MutationDeleteOneContactArgs = {
  where: ContactWhereUniqueInput;
};


export type MutationDeleteOneCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type MutationDeleteOneCronArgs = {
  where: CronWhereUniqueInput;
};


export type MutationDeleteOneFileArgs = {
  where: FileWhereUniqueInput;
};


export type MutationDeleteOneListArgs = {
  where: ListWhereUniqueInput;
};


export type MutationDeleteOneMail_TemplateArgs = {
  where: Mail_TemplateWhereUniqueInput;
};


export type MutationDeleteOneMessageArgs = {
  where: MessageWhereUniqueInput;
};


export type MutationDeleteOneParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type MutationDeleteOneProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationDeleteOneSurvey_PublishedArgs = {
  where: Survey_PublishedWhereUniqueInput;
};


export type MutationDeleteOneSurvey_ResultArgs = {
  where: Survey_ResultWhereUniqueInput;
};


export type MutationDeleteOneSurvey_SchemaArgs = {
  where: Survey_SchemaWhereUniqueInput;
};


export type MutationEndCampaignArgs = {
  campaign_id: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInsertActionDocumentSignedArgs = {
  input: ActionDocumentSignedInput;
};


export type MutationInsertActionVideoPlaybackCompletedArgs = {
  input: ActionVideoPlaybackCompletedInput;
};


export type MutationInsertActionVideoRecordedArgs = {
  input: ActionVideoRecordedInput;
};


export type MutationInsertFeedbackArgs = {
  input: RatingInput;
};


export type MutationInsertGoogleRatingArgs = {
  input: RatingInput;
};


export type MutationInsertParameterCryptArgs = {
  where: ParameterCryptInsertInput;
};


export type MutationInsertRatingArgs = {
  input: RatingInput;
};


export type MutationLinkFilesToProjectArgs = {
  input: LinkFilesToProjectInput;
};


export type MutationOfferCampaignActionArgs = {
  input: OfferCampaignActionInput;
};


export type MutationSignedUrlFileGetArgs = {
  input: GetFileInput;
};


export type MutationSignedUrlFilePartGetArgs = {
  input: GetFilePartInput;
};


export type MutationSignedUrlFilePostArgs = {
  input: PostFileInput;
};


export type MutationSignedUrlFilePutArgs = {
  input: PutFileInput;
};


export type MutationStartAppointmentCampaignArgs = {
  campaign_input: AppointmentCampaignInput;
};


export type MutationStartOfferCampaignArgs = {
  campaign_input: OfferCampaignInput;
};


export type MutationStartRatingCampaignArgs = {
  campaign_input: CampaignInput;
};


export type MutationUpdateManyAgentArgs = {
  data: AgentUpdateManyMutationInput;
  where?: InputMaybe<AgentWhereInput>;
};


export type MutationUpdateManyCampaignArgs = {
  data: CampaignUpdateManyMutationInput;
  where?: InputMaybe<CampaignWhereInput>;
};


export type MutationUpdateManyCampaign_ActionArgs = {
  data: Campaign_ActionUpdateManyMutationInput;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type MutationUpdateManyClientArgs = {
  data: ClientUpdateManyMutationInput;
  where?: InputMaybe<ClientWhereInput>;
};


export type MutationUpdateManyConfig_Campaign_ClientArgs = {
  data: Config_Campaign_ClientUpdateManyMutationInput;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type MutationUpdateManyConfig_GlobalArgs = {
  data: Config_GlobalUpdateManyMutationInput;
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type MutationUpdateManyContactArgs = {
  data: ContactUpdateManyMutationInput;
  where?: InputMaybe<ContactWhereInput>;
};


export type MutationUpdateManyCountryArgs = {
  data: CountryUpdateManyMutationInput;
  where?: InputMaybe<CountryWhereInput>;
};


export type MutationUpdateManyCronArgs = {
  data: CronUpdateManyMutationInput;
  where?: InputMaybe<CronWhereInput>;
};


export type MutationUpdateManyFileArgs = {
  data: FileUpdateManyMutationInput;
  where?: InputMaybe<FileWhereInput>;
};


export type MutationUpdateManyListArgs = {
  data: ListUpdateManyMutationInput;
  where?: InputMaybe<ListWhereInput>;
};


export type MutationUpdateManyMail_TemplateArgs = {
  data: Mail_TemplateUpdateManyMutationInput;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type MutationUpdateManyMessageArgs = {
  data: MessageUpdateManyMutationInput;
  where?: InputMaybe<MessageWhereInput>;
};


export type MutationUpdateManyParameterArgs = {
  data: ParameterUpdateManyMutationInput;
  where?: InputMaybe<ParameterWhereInput>;
};


export type MutationUpdateManyProjectArgs = {
  data: ProjectUpdateManyMutationInput;
  where?: InputMaybe<ProjectWhereInput>;
};


export type MutationUpdateManySurvey_PublishedArgs = {
  data: Survey_PublishedUpdateManyMutationInput;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type MutationUpdateManySurvey_ResultArgs = {
  data: Survey_ResultUpdateManyMutationInput;
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type MutationUpdateManySurvey_SchemaArgs = {
  data: Survey_SchemaUpdateManyMutationInput;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};


export type MutationUpdateOneAgentArgs = {
  data: AgentUpdateInput;
  where: AgentWhereUniqueInput;
};


export type MutationUpdateOneCampaignArgs = {
  data: CampaignUpdateInput;
  where: CampaignWhereUniqueInput;
};


export type MutationUpdateOneCampaign_ActionArgs = {
  data: Campaign_ActionUpdateInput;
  where: Campaign_ActionWhereUniqueInput;
};


export type MutationUpdateOneClientArgs = {
  data: ClientUpdateInput;
  where: ClientWhereUniqueInput;
};


export type MutationUpdateOneConfig_Campaign_ClientArgs = {
  data: Config_Campaign_ClientUpdateInput;
  where: Config_Campaign_ClientWhereUniqueInput;
};


export type MutationUpdateOneConfig_GlobalArgs = {
  data: Config_GlobalUpdateInput;
  where: Config_GlobalWhereUniqueInput;
};


export type MutationUpdateOneContactArgs = {
  data: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpdateOneCountryArgs = {
  data: CountryUpdateInput;
  where: CountryWhereUniqueInput;
};


export type MutationUpdateOneCronArgs = {
  data: CronUpdateInput;
  where: CronWhereUniqueInput;
};


export type MutationUpdateOneFileArgs = {
  data: FileUpdateInput;
  where: FileWhereUniqueInput;
};


export type MutationUpdateOneListArgs = {
  data: ListUpdateInput;
  where: ListWhereUniqueInput;
};


export type MutationUpdateOneMail_TemplateArgs = {
  data: Mail_TemplateUpdateInput;
  where: Mail_TemplateWhereUniqueInput;
};


export type MutationUpdateOneMessageArgs = {
  data: MessageUpdateInput;
  where: MessageWhereUniqueInput;
};


export type MutationUpdateOneParameterArgs = {
  data: ParameterUpdateInput;
  where: ParameterWhereUniqueInput;
};


export type MutationUpdateOneProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOneSurvey_PublishedArgs = {
  data: Survey_PublishedUpdateInput;
  where: Survey_PublishedWhereUniqueInput;
};


export type MutationUpdateOneSurvey_ResultArgs = {
  data: Survey_ResultUpdateInput;
  where: Survey_ResultWhereUniqueInput;
};


export type MutationUpdateOneSurvey_SchemaArgs = {
  data: Survey_SchemaUpdateInput;
  where: Survey_SchemaWhereUniqueInput;
};


export type MutationUpdateParameterCryptArgs = {
  where: ParameterCryptUpdateInput;
};


export type MutationUpsertOneAgentArgs = {
  create: AgentCreateInput;
  update: AgentUpdateInput;
  where: AgentWhereUniqueInput;
};


export type MutationUpsertOneCampaignArgs = {
  create: CampaignCreateInput;
  update: CampaignUpdateInput;
  where: CampaignWhereUniqueInput;
};


export type MutationUpsertOneCampaign_ActionArgs = {
  create: Campaign_ActionCreateInput;
  update: Campaign_ActionUpdateInput;
  where: Campaign_ActionWhereUniqueInput;
};


export type MutationUpsertOneClientArgs = {
  create: ClientCreateInput;
  update: ClientUpdateInput;
  where: ClientWhereUniqueInput;
};


export type MutationUpsertOneConfig_Campaign_ClientArgs = {
  create: Config_Campaign_ClientCreateInput;
  update: Config_Campaign_ClientUpdateInput;
  where: Config_Campaign_ClientWhereUniqueInput;
};


export type MutationUpsertOneConfig_GlobalArgs = {
  create: Config_GlobalCreateInput;
  update: Config_GlobalUpdateInput;
  where: Config_GlobalWhereUniqueInput;
};


export type MutationUpsertOneContactArgs = {
  create: ContactCreateInput;
  update: ContactUpdateInput;
  where: ContactWhereUniqueInput;
};


export type MutationUpsertOneCountryArgs = {
  create: CountryCreateInput;
  update: CountryUpdateInput;
  where: CountryWhereUniqueInput;
};


export type MutationUpsertOneCronArgs = {
  create: CronCreateInput;
  update: CronUpdateInput;
  where: CronWhereUniqueInput;
};


export type MutationUpsertOneFileArgs = {
  create: FileCreateInput;
  update: FileUpdateInput;
  where: FileWhereUniqueInput;
};


export type MutationUpsertOneListArgs = {
  create: ListCreateInput;
  update: ListUpdateInput;
  where: ListWhereUniqueInput;
};


export type MutationUpsertOneMail_TemplateArgs = {
  create: Mail_TemplateCreateInput;
  update: Mail_TemplateUpdateInput;
  where: Mail_TemplateWhereUniqueInput;
};


export type MutationUpsertOneMessageArgs = {
  create: MessageCreateInput;
  update: MessageUpdateInput;
  where: MessageWhereUniqueInput;
};


export type MutationUpsertOneParameterArgs = {
  create: ParameterCreateInput;
  update: ParameterUpdateInput;
  where: ParameterWhereUniqueInput;
};


export type MutationUpsertOneProjectArgs = {
  create: ProjectCreateInput;
  update: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationUpsertOneSurvey_PublishedArgs = {
  create: Survey_PublishedCreateInput;
  update: Survey_PublishedUpdateInput;
  where: Survey_PublishedWhereUniqueInput;
};


export type MutationUpsertOneSurvey_ResultArgs = {
  create: Survey_ResultCreateInput;
  update: Survey_ResultUpdateInput;
  where: Survey_ResultWhereUniqueInput;
};


export type MutationUpsertOneSurvey_SchemaArgs = {
  create: Survey_SchemaCreateInput;
  update: Survey_SchemaUpdateInput;
  where: Survey_SchemaWhereUniqueInput;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  gte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  in?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
  lt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  lte?: InputMaybe<Scalars['DateTimeISO']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTimeISO']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumcampaign_Action_Main_StateFilter = {
  equals?: InputMaybe<Campaign_Action_Main_State>;
  in?: InputMaybe<Array<Campaign_Action_Main_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Main_StateFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Main_State>>;
};

export type NestedEnumcampaign_Action_Main_StateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcampaign_Action_Main_StateFilter>;
  _min?: InputMaybe<NestedEnumcampaign_Action_Main_StateFilter>;
  equals?: InputMaybe<Campaign_Action_Main_State>;
  in?: InputMaybe<Array<Campaign_Action_Main_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Main_StateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Main_State>>;
};

export type NestedEnumcampaign_Action_Sub_StateFilter = {
  equals?: InputMaybe<Campaign_Action_Sub_State>;
  in?: InputMaybe<Array<Campaign_Action_Sub_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Sub_StateFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Sub_State>>;
};

export type NestedEnumcampaign_Action_Sub_StateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcampaign_Action_Sub_StateFilter>;
  _min?: InputMaybe<NestedEnumcampaign_Action_Sub_StateFilter>;
  equals?: InputMaybe<Campaign_Action_Sub_State>;
  in?: InputMaybe<Array<Campaign_Action_Sub_State>>;
  not?: InputMaybe<NestedEnumcampaign_Action_Sub_StateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Campaign_Action_Sub_State>>;
};

export type NestedEnumcampaign_TypeNullableFilter = {
  equals?: InputMaybe<Campaign_Type>;
  in?: InputMaybe<Array<Campaign_Type>>;
  not?: InputMaybe<NestedEnumcampaign_TypeNullableFilter>;
  notIn?: InputMaybe<Array<Campaign_Type>>;
};

export type NestedEnumcampaign_TypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumcampaign_TypeNullableFilter>;
  _min?: InputMaybe<NestedEnumcampaign_TypeNullableFilter>;
  equals?: InputMaybe<Campaign_Type>;
  in?: InputMaybe<Array<Campaign_Type>>;
  not?: InputMaybe<NestedEnumcampaign_TypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Campaign_Type>>;
};

export type NestedEnumconfig_NameFilter = {
  equals?: InputMaybe<Config_Name>;
  in?: InputMaybe<Array<Config_Name>>;
  not?: InputMaybe<NestedEnumconfig_NameFilter>;
  notIn?: InputMaybe<Array<Config_Name>>;
};

export type NestedEnumconfig_NameWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumconfig_NameFilter>;
  _min?: InputMaybe<NestedEnumconfig_NameFilter>;
  equals?: InputMaybe<Config_Name>;
  in?: InputMaybe<Array<Config_Name>>;
  not?: InputMaybe<NestedEnumconfig_NameWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Config_Name>>;
};

export type NestedEnumcron_JobFilter = {
  equals?: InputMaybe<Cron_Job>;
  in?: InputMaybe<Array<Cron_Job>>;
  not?: InputMaybe<NestedEnumcron_JobFilter>;
  notIn?: InputMaybe<Array<Cron_Job>>;
};

export type NestedEnumcron_JobWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcron_JobFilter>;
  _min?: InputMaybe<NestedEnumcron_JobFilter>;
  equals?: InputMaybe<Cron_Job>;
  in?: InputMaybe<Array<Cron_Job>>;
  not?: InputMaybe<NestedEnumcron_JobWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Cron_Job>>;
};

export type NestedEnumcron_StateFilter = {
  equals?: InputMaybe<Cron_State>;
  in?: InputMaybe<Array<Cron_State>>;
  not?: InputMaybe<NestedEnumcron_StateFilter>;
  notIn?: InputMaybe<Array<Cron_State>>;
};

export type NestedEnumcron_StateWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumcron_StateFilter>;
  _min?: InputMaybe<NestedEnumcron_StateFilter>;
  equals?: InputMaybe<Cron_State>;
  in?: InputMaybe<Array<Cron_State>>;
  not?: InputMaybe<NestedEnumcron_StateWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Cron_State>>;
};

export type NestedEnumformalNullableFilter = {
  equals?: InputMaybe<Formal>;
  in?: InputMaybe<Array<Formal>>;
  not?: InputMaybe<NestedEnumformalNullableFilter>;
  notIn?: InputMaybe<Array<Formal>>;
};

export type NestedEnumformalNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumformalNullableFilter>;
  _min?: InputMaybe<NestedEnumformalNullableFilter>;
  equals?: InputMaybe<Formal>;
  in?: InputMaybe<Array<Formal>>;
  not?: InputMaybe<NestedEnumformalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Formal>>;
};

export type NestedEnumlistTypeNullableFilter = {
  equals?: InputMaybe<ListType>;
  in?: InputMaybe<Array<ListType>>;
  not?: InputMaybe<NestedEnumlistTypeNullableFilter>;
  notIn?: InputMaybe<Array<ListType>>;
};

export type NestedEnumlistTypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumlistTypeNullableFilter>;
  _min?: InputMaybe<NestedEnumlistTypeNullableFilter>;
  equals?: InputMaybe<ListType>;
  in?: InputMaybe<Array<ListType>>;
  not?: InputMaybe<NestedEnumlistTypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<ListType>>;
};

export type NestedEnummail_ScenarioFilter = {
  equals?: InputMaybe<Mail_Scenario>;
  in?: InputMaybe<Array<Mail_Scenario>>;
  not?: InputMaybe<NestedEnummail_ScenarioFilter>;
  notIn?: InputMaybe<Array<Mail_Scenario>>;
};

export type NestedEnummail_ScenarioWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnummail_ScenarioFilter>;
  _min?: InputMaybe<NestedEnummail_ScenarioFilter>;
  equals?: InputMaybe<Mail_Scenario>;
  in?: InputMaybe<Array<Mail_Scenario>>;
  not?: InputMaybe<NestedEnummail_ScenarioWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Mail_Scenario>>;
};

export type NestedEnummail_Template_NameNullableFilter = {
  equals?: InputMaybe<Mail_Template_Name>;
  in?: InputMaybe<Array<Mail_Template_Name>>;
  not?: InputMaybe<NestedEnummail_Template_NameNullableFilter>;
  notIn?: InputMaybe<Array<Mail_Template_Name>>;
};

export type NestedEnummail_Template_NameNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnummail_Template_NameNullableFilter>;
  _min?: InputMaybe<NestedEnummail_Template_NameNullableFilter>;
  equals?: InputMaybe<Mail_Template_Name>;
  in?: InputMaybe<Array<Mail_Template_Name>>;
  not?: InputMaybe<NestedEnummail_Template_NameNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Mail_Template_Name>>;
};

export type NestedEnumparameter_KeyFilter = {
  equals?: InputMaybe<Parameter_Key>;
  in?: InputMaybe<Array<Parameter_Key>>;
  not?: InputMaybe<NestedEnumparameter_KeyFilter>;
  notIn?: InputMaybe<Array<Parameter_Key>>;
};

export type NestedEnumparameter_KeyWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumparameter_KeyFilter>;
  _min?: InputMaybe<NestedEnumparameter_KeyFilter>;
  equals?: InputMaybe<Parameter_Key>;
  in?: InputMaybe<Array<Parameter_Key>>;
  not?: InputMaybe<NestedEnumparameter_KeyWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Parameter_Key>>;
};

export type NestedEnumparameter_ScenarioFilter = {
  equals?: InputMaybe<Parameter_Scenario>;
  in?: InputMaybe<Array<Parameter_Scenario>>;
  not?: InputMaybe<NestedEnumparameter_ScenarioFilter>;
  notIn?: InputMaybe<Array<Parameter_Scenario>>;
};

export type NestedEnumparameter_ScenarioWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumparameter_ScenarioFilter>;
  _min?: InputMaybe<NestedEnumparameter_ScenarioFilter>;
  equals?: InputMaybe<Parameter_Scenario>;
  in?: InputMaybe<Array<Parameter_Scenario>>;
  not?: InputMaybe<NestedEnumparameter_ScenarioWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Parameter_Scenario>>;
};

export type NestedEnumparameter_TypeNullableFilter = {
  equals?: InputMaybe<Parameter_Type>;
  in?: InputMaybe<Array<Parameter_Type>>;
  not?: InputMaybe<NestedEnumparameter_TypeNullableFilter>;
  notIn?: InputMaybe<Array<Parameter_Type>>;
};

export type NestedEnumparameter_TypeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumparameter_TypeNullableFilter>;
  _min?: InputMaybe<NestedEnumparameter_TypeNullableFilter>;
  equals?: InputMaybe<Parameter_Type>;
  in?: InputMaybe<Array<Parameter_Type>>;
  not?: InputMaybe<NestedEnumparameter_TypeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Parameter_Type>>;
};

export type NestedEnumsalutationFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type NestedEnumsalutationNullableFilter = {
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationNullableFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type NestedEnumsalutationNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedEnumsalutationNullableFilter>;
  _min?: InputMaybe<NestedEnumsalutationNullableFilter>;
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type NestedEnumsalutationWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumsalutationFilter>;
  _min?: InputMaybe<NestedEnumsalutationFilter>;
  equals?: InputMaybe<Salutation>;
  in?: InputMaybe<Array<Salutation>>;
  not?: InputMaybe<NestedEnumsalutationWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Salutation>>;
};

export type NestedEnumuser_RoleFilter = {
  equals?: InputMaybe<User_Role>;
  in?: InputMaybe<Array<User_Role>>;
  not?: InputMaybe<NestedEnumuser_RoleFilter>;
  notIn?: InputMaybe<Array<User_Role>>;
};

export type NestedEnumuser_RoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumuser_RoleFilter>;
  _min?: InputMaybe<NestedEnumuser_RoleFilter>;
  equals?: InputMaybe<User_Role>;
  in?: InputMaybe<Array<User_Role>>;
  not?: InputMaybe<NestedEnumuser_RoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<User_Role>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type NullableDecimalFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Decimal']['input']>;
  divide?: InputMaybe<Scalars['Decimal']['input']>;
  increment?: InputMaybe<Scalars['Decimal']['input']>;
  multiply?: InputMaybe<Scalars['Decimal']['input']>;
  set?: InputMaybe<Scalars['Decimal']['input']>;
};

export type NullableEnumcampaign_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Campaign_Type>;
};

export type NullableEnumformalFieldUpdateOperationsInput = {
  set?: InputMaybe<Formal>;
};

export type NullableEnumlistTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ListType>;
};

export type NullableEnummail_Template_NameFieldUpdateOperationsInput = {
  set?: InputMaybe<Mail_Template_Name>;
};

export type NullableEnumparameter_TypeFieldUpdateOperationsInput = {
  set?: InputMaybe<Parameter_Type>;
};

export type NullableEnumsalutationFieldUpdateOperationsInput = {
  set?: InputMaybe<Salutation>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OfferCampaignActionInput = {
  action: Scalars['String']['input'];
  campaign_id: Scalars['String']['input'];
  cron_id: Scalars['String']['input'];
  cron_job: Cron_Job;
};

export type OfferCampaignActionNotification = {
  __typename?: 'OfferCampaignActionNotification';
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
};

export type OfferCampaignActionResponse = {
  __typename?: 'OfferCampaignActionResponse';
  execution_date?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  notifications?: Maybe<Array<OfferCampaignActionNotification>>;
  state: Cron_State;
};

export type OfferCampaignInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  campaign_type: Campaign_Type;
  contact_id: Scalars['String']['input'];
  delivery: Scalars['String']['input'];
  file_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  number?: InputMaybe<Scalars['String']['input']>;
  offer: Array<Scalars['JSONObject']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  project_id: Scalars['String']['input'];
  statement?: InputMaybe<Scalars['String']['input']>;
};

/** Page Info Type */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type Parameter = {
  __typename?: 'Parameter';
  agent?: Maybe<Agent>;
  agent_id?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Client>;
  client_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: Maybe<Parameter_Type>;
  /** [ParameterValue] */
  value: Scalars['JSON']['output'];
};


export type ParameterAgentArgs = {
  where?: InputMaybe<AgentWhereInput>;
};


export type ParameterClientArgs = {
  where?: InputMaybe<ClientWhereInput>;
};

export type ParameterAgentListOutput = {
  __typename?: 'ParameterAgentListOutput';
  agent_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  value: Scalars['JSONObject']['output'];
};

export type ParameterCountAggregate = {
  __typename?: 'ParameterCountAggregate';
  _all: Scalars['Int']['output'];
  agent_id: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  key_name: Scalars['Int']['output'];
  scenario_name: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type ParameterCountOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key_name?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type ParameterCreateInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutParameterInput>;
  client?: InputMaybe<ClientCreateNestedOneWithoutParameterInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: InputMaybe<Parameter_Type>;
  value: Scalars['JSON']['input'];
};

export type ParameterCreateManyAgentInput = {
  client_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: InputMaybe<Parameter_Type>;
  value: Scalars['JSON']['input'];
};

export type ParameterCreateManyAgentInputEnvelope = {
  data: Array<ParameterCreateManyAgentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ParameterCreateManyClientInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: InputMaybe<Parameter_Type>;
  value: Scalars['JSON']['input'];
};

export type ParameterCreateManyClientInputEnvelope = {
  data: Array<ParameterCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ParameterCreateManyInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  client_id?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: InputMaybe<Parameter_Type>;
  value: Scalars['JSON']['input'];
};

export type ParameterCreateNestedManyWithoutAgentInput = {
  connect?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ParameterCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<ParameterCreateWithoutAgentInput>>;
  createMany?: InputMaybe<ParameterCreateManyAgentInputEnvelope>;
};

export type ParameterCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ParameterCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ParameterCreateWithoutClientInput>>;
  createMany?: InputMaybe<ParameterCreateManyClientInputEnvelope>;
};

export type ParameterCreateOrConnectWithoutAgentInput = {
  create: ParameterCreateWithoutAgentInput;
  where: ParameterWhereUniqueInput;
};

export type ParameterCreateOrConnectWithoutClientInput = {
  create: ParameterCreateWithoutClientInput;
  where: ParameterWhereUniqueInput;
};

export type ParameterCreateWithoutAgentInput = {
  client?: InputMaybe<ClientCreateNestedOneWithoutParameterInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: InputMaybe<Parameter_Type>;
  value: Scalars['JSON']['input'];
};

export type ParameterCreateWithoutClientInput = {
  agent?: InputMaybe<AgentCreateNestedOneWithoutParameterInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: InputMaybe<Parameter_Type>;
  value: Scalars['JSON']['input'];
};

export type ParameterCryptInsertInput = {
  agent_id?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  value: Scalars['JSONObject']['input'];
};

export type ParameterCryptUpdateInput = {
  id: Scalars['String']['input'];
  value: Scalars['JSONObject']['input'];
};

export type ParameterGroupBy = {
  __typename?: 'ParameterGroupBy';
  _count?: Maybe<ParameterCountAggregate>;
  _max?: Maybe<ParameterMaxAggregate>;
  _min?: Maybe<ParameterMinAggregate>;
  agent_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: Maybe<Parameter_Type>;
  value: Scalars['JSON']['output'];
};

export type ParameterInput = {
  id: Scalars['String']['input'];
};

export type ParameterListOutput = {
  __typename?: 'ParameterListOutput';
  agent_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isGlobal: Scalars['Boolean']['output'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  type?: Maybe<Parameter_Type>;
  value: Scalars['JSONObject']['output'];
};

export type ParameterListRelationFilter = {
  every?: InputMaybe<ParameterWhereInput>;
  none?: InputMaybe<ParameterWhereInput>;
  some?: InputMaybe<ParameterWhereInput>;
};

export type ParameterMaxAggregate = {
  __typename?: 'ParameterMaxAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key_name?: Maybe<Parameter_Key>;
  scenario_name?: Maybe<Parameter_Scenario>;
  type?: Maybe<Parameter_Type>;
};

export type ParameterMaxOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key_name?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type ParameterMinAggregate = {
  __typename?: 'ParameterMinAggregate';
  agent_id?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  key_name?: Maybe<Parameter_Key>;
  scenario_name?: Maybe<Parameter_Scenario>;
  type?: Maybe<Parameter_Type>;
};

export type ParameterMinOrderByAggregateInput = {
  agent_id?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key_name?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type ParameterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ParameterOrderByWithAggregationInput = {
  _count?: InputMaybe<ParameterCountOrderByAggregateInput>;
  _max?: InputMaybe<ParameterMaxOrderByAggregateInput>;
  _min?: InputMaybe<ParameterMinOrderByAggregateInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  client_id?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key_name?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  value?: InputMaybe<SortOrder>;
};

export type ParameterOrderByWithRelationInput = {
  agent?: InputMaybe<AgentOrderByWithRelationInput>;
  agent_id?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key_name?: InputMaybe<SortOrder>;
  scenario_name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrderInput>;
  value?: InputMaybe<SortOrder>;
};

export type ParameterOutput = {
  __typename?: 'ParameterOutput';
  client_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
  value: Scalars['JSONObject']['output'];
};

export enum ParameterScalarFieldEnum {
  AgentId = 'agent_id',
  ClientId = 'client_id',
  Created = 'created',
  Id = 'id',
  KeyName = 'key_name',
  ScenarioName = 'scenario_name',
  Type = 'type',
  Value = 'value'
}

export type ParameterScalarWhereInput = {
  AND?: InputMaybe<Array<ParameterScalarWhereInput>>;
  NOT?: InputMaybe<Array<ParameterScalarWhereInput>>;
  OR?: InputMaybe<Array<ParameterScalarWhereInput>>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  client_id?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  key_name?: InputMaybe<Enumparameter_KeyFilter>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFilter>;
  type?: InputMaybe<Enumparameter_TypeNullableFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type ParameterScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ParameterScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ParameterScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ParameterScalarWhereWithAggregatesInput>>;
  agent_id?: InputMaybe<UuidNullableWithAggregatesFilter>;
  client_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  key_name?: InputMaybe<Enumparameter_KeyWithAggregatesFilter>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioWithAggregatesFilter>;
  type?: InputMaybe<Enumparameter_TypeNullableWithAggregatesFilter>;
  value?: InputMaybe<JsonWithAggregatesFilter>;
};

export type ParameterUpdateInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutParameterNestedInput>;
  client?: InputMaybe<ClientUpdateOneWithoutParameterNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key_name?: InputMaybe<Enumparameter_KeyFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumparameter_TypeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type ParameterUpdateManyMutationInput = {
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key_name?: InputMaybe<Enumparameter_KeyFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumparameter_TypeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type ParameterUpdateManyWithWhereWithoutAgentInput = {
  data: ParameterUpdateManyMutationInput;
  where: ParameterScalarWhereInput;
};

export type ParameterUpdateManyWithWhereWithoutClientInput = {
  data: ParameterUpdateManyMutationInput;
  where: ParameterScalarWhereInput;
};

export type ParameterUpdateManyWithoutAgentNestedInput = {
  connect?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ParameterCreateOrConnectWithoutAgentInput>>;
  create?: InputMaybe<Array<ParameterCreateWithoutAgentInput>>;
  createMany?: InputMaybe<ParameterCreateManyAgentInputEnvelope>;
  delete?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ParameterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  set?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  update?: InputMaybe<Array<ParameterUpdateWithWhereUniqueWithoutAgentInput>>;
  updateMany?: InputMaybe<Array<ParameterUpdateManyWithWhereWithoutAgentInput>>;
  upsert?: InputMaybe<Array<ParameterUpsertWithWhereUniqueWithoutAgentInput>>;
};

export type ParameterUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ParameterCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ParameterCreateWithoutClientInput>>;
  createMany?: InputMaybe<ParameterCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ParameterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  set?: InputMaybe<Array<ParameterWhereUniqueInput>>;
  update?: InputMaybe<Array<ParameterUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ParameterUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ParameterUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ParameterUpdateWithWhereUniqueWithoutAgentInput = {
  data: ParameterUpdateWithoutAgentInput;
  where: ParameterWhereUniqueInput;
};

export type ParameterUpdateWithWhereUniqueWithoutClientInput = {
  data: ParameterUpdateWithoutClientInput;
  where: ParameterWhereUniqueInput;
};

export type ParameterUpdateWithoutAgentInput = {
  client?: InputMaybe<ClientUpdateOneWithoutParameterNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key_name?: InputMaybe<Enumparameter_KeyFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumparameter_TypeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type ParameterUpdateWithoutClientInput = {
  agent?: InputMaybe<AgentUpdateOneWithoutParameterNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  key_name?: InputMaybe<Enumparameter_KeyFieldUpdateOperationsInput>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableEnumparameter_TypeFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type ParameterUpsertWithWhereUniqueWithoutAgentInput = {
  create: ParameterCreateWithoutAgentInput;
  update: ParameterUpdateWithoutAgentInput;
  where: ParameterWhereUniqueInput;
};

export type ParameterUpsertWithWhereUniqueWithoutClientInput = {
  create: ParameterCreateWithoutClientInput;
  update: ParameterUpdateWithoutClientInput;
  where: ParameterWhereUniqueInput;
};

export type ParameterWhereInput = {
  AND?: InputMaybe<Array<ParameterWhereInput>>;
  NOT?: InputMaybe<Array<ParameterWhereInput>>;
  OR?: InputMaybe<Array<ParameterWhereInput>>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  client_id?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  key_name?: InputMaybe<Enumparameter_KeyFilter>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFilter>;
  type?: InputMaybe<Enumparameter_TypeNullableFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type ParameterWhereUniqueInput = {
  AND?: InputMaybe<Array<ParameterWhereInput>>;
  NOT?: InputMaybe<Array<ParameterWhereInput>>;
  OR?: InputMaybe<Array<ParameterWhereInput>>;
  agent?: InputMaybe<AgentNullableRelationFilter>;
  agent_id?: InputMaybe<UuidNullableFilter>;
  client?: InputMaybe<ClientNullableRelationFilter>;
  client_id?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  key_name?: InputMaybe<Enumparameter_KeyFilter>;
  scenario_name?: InputMaybe<Enumparameter_ScenarioFilter>;
  scenario_name_key_name_client_id_agent_id?: InputMaybe<ParameterScenario_NameKey_NameClient_IdAgent_IdCompoundUniqueInput>;
  type?: InputMaybe<Enumparameter_TypeNullableFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type PartInput = {
  ETag: Scalars['String']['input'];
  PartNumber: Scalars['Float']['input'];
};

export type PostFileInput = {
  acl: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

/** Signed Url Post Response */
export type PostFileOutput = {
  __typename?: 'PostFileOutput';
  fields: Scalars['JSONObject']['output'];
  url: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  _count?: Maybe<ProjectCount>;
  campaign: Array<Campaign>;
  city?: Maybe<Scalars['String']['output']>;
  client: Client;
  client_id: Scalars['String']['output'];
  contact: Contact;
  contact_id: Scalars['String']['output'];
  country?: Maybe<Country>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  id: Scalars['String']['output'];
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type ProjectCampaignArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type ProjectCountryArgs = {
  where?: InputMaybe<CountryWhereInput>;
};


export type ProjectFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};

export type ProjectCount = {
  __typename?: 'ProjectCount';
  campaign: Scalars['Int']['output'];
  files: Scalars['Int']['output'];
};


export type ProjectCountCampaignArgs = {
  where?: InputMaybe<CampaignWhereInput>;
};


export type ProjectCountFilesArgs = {
  where?: InputMaybe<FileWhereInput>;
};

export type ProjectCountAggregate = {
  __typename?: 'ProjectCountAggregate';
  _all: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  contact_id: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  external_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  iso_3166_1: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  note: Scalars['Int']['output'];
  service: Scalars['Int']['output'];
  street: Scalars['Int']['output'];
  zip: Scalars['Int']['output'];
};

export type ProjectCountOrderByAggregateInput = {
  city?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  external_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  service?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ProjectCreateInput = {
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutProjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutProjectInput;
  contact: ContactCreateNestedOneWithoutProjectInput;
  country?: InputMaybe<CountryCreateNestedOneWithoutProjectInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateManyClientInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateManyClientInputEnvelope = {
  data: Array<ProjectCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectCreateManyContactInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateManyContactInputEnvelope = {
  data: Array<ProjectCreateManyContactInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectCreateManyCountryInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateManyCountryInputEnvelope = {
  data: Array<ProjectCreateManyCountryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProjectCreateManyInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
  contact_id: Scalars['String']['input'];
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutClientInput>>;
  createMany?: InputMaybe<ProjectCreateManyClientInputEnvelope>;
};

export type ProjectCreateNestedManyWithoutContactInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutContactInput>>;
  createMany?: InputMaybe<ProjectCreateManyContactInputEnvelope>;
};

export type ProjectCreateNestedManyWithoutCountryInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ProjectCreateManyCountryInputEnvelope>;
};

export type ProjectCreateNestedOneWithoutCampaignInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<ProjectCreateWithoutCampaignInput>;
};

export type ProjectCreateNestedOneWithoutFilesInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ProjectCreateWithoutFilesInput>;
};

export type ProjectCreateOrConnectWithoutCampaignInput = {
  create: ProjectCreateWithoutCampaignInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutClientInput = {
  create: ProjectCreateWithoutClientInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutContactInput = {
  create: ProjectCreateWithoutContactInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutCountryInput = {
  create: ProjectCreateWithoutCountryInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateOrConnectWithoutFilesInput = {
  create: ProjectCreateWithoutFilesInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectCreateSyncInput = {
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutProjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutProjectInput;
  contact: ContactCreateNestedOneWithoutProjectInput;
  country?: InputMaybe<CountryCreateNestedOneWithoutProjectInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  labelsReplacement?: InputMaybe<Array<LabelsReplacement>>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  permissionsGroups?: InputMaybe<Array<Scalars['String']['input']>>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateWithoutCampaignInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutProjectInput;
  contact: ContactCreateNestedOneWithoutProjectInput;
  country?: InputMaybe<CountryCreateNestedOneWithoutProjectInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateWithoutClientInput = {
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutProjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  contact: ContactCreateNestedOneWithoutProjectInput;
  country?: InputMaybe<CountryCreateNestedOneWithoutProjectInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateWithoutContactInput = {
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutProjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutProjectInput;
  country?: InputMaybe<CountryCreateNestedOneWithoutProjectInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateWithoutCountryInput = {
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutProjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutProjectInput;
  contact: ContactCreateNestedOneWithoutProjectInput;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<FileCreateNestedManyWithoutProjectInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectCreateWithoutFilesInput = {
  campaign?: InputMaybe<CampaignCreateNestedManyWithoutProjectInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  client: ClientCreateNestedOneWithoutProjectInput;
  contact: ContactCreateNestedOneWithoutProjectInput;
  country?: InputMaybe<CountryCreateNestedOneWithoutProjectInput>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectGroupBy = {
  __typename?: 'ProjectGroupBy';
  _count?: Maybe<ProjectCountAggregate>;
  _max?: Maybe<ProjectMaxAggregate>;
  _min?: Maybe<ProjectMinAggregate>;
  city?: Maybe<Scalars['String']['output']>;
  client_id: Scalars['String']['output'];
  contact_id: Scalars['String']['output'];
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ProjectListRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectMaxAggregate = {
  __typename?: 'ProjectMaxAggregate';
  city?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ProjectMaxOrderByAggregateInput = {
  city?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  external_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  service?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ProjectMinAggregate = {
  __typename?: 'ProjectMinAggregate';
  city?: Maybe<Scalars['String']['output']>;
  client_id?: Maybe<Scalars['String']['output']>;
  contact_id?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  iso_3166_1?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type ProjectMinOrderByAggregateInput = {
  city?: InputMaybe<SortOrder>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  external_id?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrder>;
  service?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  zip?: InputMaybe<SortOrder>;
};

export type ProjectNullableRelationFilter = {
  is?: InputMaybe<ProjectWhereInput>;
  isNot?: InputMaybe<ProjectWhereInput>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProjectOrderByWithAggregationInput = {
  _count?: InputMaybe<ProjectCountOrderByAggregateInput>;
  _max?: InputMaybe<ProjectMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProjectMinOrderByAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  client_id?: InputMaybe<SortOrder>;
  contact_id?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  external_id?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<SortOrderInput>;
  street?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type ProjectOrderByWithRelationInput = {
  campaign?: InputMaybe<CampaignOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  contact?: InputMaybe<ContactOrderByWithRelationInput>;
  contact_id?: InputMaybe<SortOrder>;
  country?: InputMaybe<CountryOrderByWithRelationInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  external_id?: InputMaybe<SortOrderInput>;
  files?: InputMaybe<FileOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  iso_3166_1?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  note?: InputMaybe<SortOrderInput>;
  service?: InputMaybe<SortOrderInput>;
  street?: InputMaybe<SortOrderInput>;
  zip?: InputMaybe<SortOrderInput>;
};

export type ProjectRelationFilter = {
  is?: InputMaybe<ProjectWhereInput>;
  isNot?: InputMaybe<ProjectWhereInput>;
};

export enum ProjectScalarFieldEnum {
  City = 'city',
  ClientId = 'client_id',
  ContactId = 'contact_id',
  Created = 'created',
  Deleted = 'deleted',
  ExternalId = 'external_id',
  Id = 'id',
  Iso_3166_1 = 'iso_3166_1',
  Name = 'name',
  Note = 'note',
  Service = 'service',
  Street = 'street',
  Zip = 'zip'
}

export type ProjectScalarWhereInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereInput>>;
  city?: InputMaybe<StringNullableFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact_id?: InputMaybe<UuidFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  external_id?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  iso_3166_1?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  note?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<StringNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ProjectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProjectScalarWhereWithAggregatesInput>>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  contact_id?: InputMaybe<UuidWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  external_id?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  iso_3166_1?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  note?: InputMaybe<StringNullableWithAggregatesFilter>;
  service?: InputMaybe<StringNullableWithAggregatesFilter>;
  street?: InputMaybe<StringNullableWithAggregatesFilter>;
  zip?: InputMaybe<StringNullableWithAggregatesFilter>;
};

/** File Sync Data Res */
export type ProjectSyncDataOutput = {
  __typename?: 'ProjectSyncDataOutput';
  api_name: FileSyncApiName;
  companyUserGroups?: Maybe<CompanyUserGroup>;
  templates?: Maybe<FolderTemplates>;
};

/** Folder List Type */
export type ProjectSyncFolderList = {
  __typename?: 'ProjectSyncFolderList';
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

/** The basic directions */
export enum ProjectSyncFolderSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** The basic directions */
export enum ProjectSyncFolderSortOrderBy {
  Alphabetically = 'ALPHABETICALLY'
}

/** Folder Sync Labels */
export type ProjectSyncLabels = {
  __typename?: 'ProjectSyncLabels';
  labels: Array<Scalars['String']['output']>;
};

export type ProjectUpdateInput = {
  campaign?: InputMaybe<CampaignUpdateManyWithoutProjectNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProjectNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutProjectNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutProjectNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyMutationInput = {
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyWithWhereWithoutClientInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithWhereWithoutContactInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithWhereWithoutCountryInput = {
  data: ProjectUpdateManyMutationInput;
  where: ProjectScalarWhereInput;
};

export type ProjectUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutClientInput>>;
  createMany?: InputMaybe<ProjectCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutClientInput>>;
};

export type ProjectUpdateManyWithoutContactNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutContactInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutContactInput>>;
  createMany?: InputMaybe<ProjectCreateManyContactInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutContactInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutContactInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutContactInput>>;
};

export type ProjectUpdateManyWithoutCountryNestedInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProjectCreateOrConnectWithoutCountryInput>>;
  create?: InputMaybe<Array<ProjectCreateWithoutCountryInput>>;
  createMany?: InputMaybe<ProjectCreateManyCountryInputEnvelope>;
  delete?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProjectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  update?: InputMaybe<Array<ProjectUpdateWithWhereUniqueWithoutCountryInput>>;
  updateMany?: InputMaybe<Array<ProjectUpdateManyWithWhereWithoutCountryInput>>;
  upsert?: InputMaybe<Array<ProjectUpsertWithWhereUniqueWithoutCountryInput>>;
};

export type ProjectUpdateOneRequiredWithoutCampaignNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutCampaignInput>;
  create?: InputMaybe<ProjectCreateWithoutCampaignInput>;
  update?: InputMaybe<ProjectUpdateToOneWithWhereWithoutCampaignInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutCampaignInput>;
};

export type ProjectUpdateOneWithoutFilesNestedInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProjectCreateOrConnectWithoutFilesInput>;
  create?: InputMaybe<ProjectCreateWithoutFilesInput>;
  delete?: InputMaybe<ProjectWhereInput>;
  disconnect?: InputMaybe<ProjectWhereInput>;
  update?: InputMaybe<ProjectUpdateToOneWithWhereWithoutFilesInput>;
  upsert?: InputMaybe<ProjectUpsertWithoutFilesInput>;
};

export type ProjectUpdateToOneWithWhereWithoutCampaignInput = {
  data: ProjectUpdateWithoutCampaignInput;
  where?: InputMaybe<ProjectWhereInput>;
};

export type ProjectUpdateToOneWithWhereWithoutFilesInput = {
  data: ProjectUpdateWithoutFilesInput;
  where?: InputMaybe<ProjectWhereInput>;
};

export type ProjectUpdateWithWhereUniqueWithoutClientInput = {
  data: ProjectUpdateWithoutClientInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithWhereUniqueWithoutContactInput = {
  data: ProjectUpdateWithoutContactInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithWhereUniqueWithoutCountryInput = {
  data: ProjectUpdateWithoutCountryInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateWithoutCampaignInput = {
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProjectNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutProjectNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutProjectNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutClientInput = {
  campaign?: InputMaybe<CampaignUpdateManyWithoutProjectNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutProjectNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutProjectNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutContactInput = {
  campaign?: InputMaybe<CampaignUpdateManyWithoutProjectNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProjectNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutProjectNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutCountryInput = {
  campaign?: InputMaybe<CampaignUpdateManyWithoutProjectNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProjectNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutProjectNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  files?: InputMaybe<FileUpdateManyWithoutProjectNestedInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutFilesInput = {
  campaign?: InputMaybe<CampaignUpdateManyWithoutProjectNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutProjectNestedInput>;
  contact?: InputMaybe<ContactUpdateOneRequiredWithoutProjectNestedInput>;
  country?: InputMaybe<CountryUpdateOneWithoutProjectNestedInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  external_id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  note?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  service?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  street?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  zip?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProjectUpsertWithWhereUniqueWithoutClientInput = {
  create: ProjectCreateWithoutClientInput;
  update: ProjectUpdateWithoutClientInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithWhereUniqueWithoutContactInput = {
  create: ProjectCreateWithoutContactInput;
  update: ProjectUpdateWithoutContactInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithWhereUniqueWithoutCountryInput = {
  create: ProjectCreateWithoutCountryInput;
  update: ProjectUpdateWithoutCountryInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpsertWithoutCampaignInput = {
  create: ProjectCreateWithoutCampaignInput;
  update: ProjectUpdateWithoutCampaignInput;
  where?: InputMaybe<ProjectWhereInput>;
};

export type ProjectUpsertWithoutFilesInput = {
  create: ProjectCreateWithoutFilesInput;
  update: ProjectUpdateWithoutFilesInput;
  where?: InputMaybe<ProjectWhereInput>;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact?: InputMaybe<ContactRelationFilter>;
  contact_id?: InputMaybe<UuidFilter>;
  country?: InputMaybe<CountryNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  external_id?: InputMaybe<StringNullableFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<UuidFilter>;
  iso_3166_1?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  note?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<StringNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type ProjectWhereUniqueInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  campaign?: InputMaybe<CampaignListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  contact?: InputMaybe<ContactRelationFilter>;
  contact_id?: InputMaybe<UuidFilter>;
  country?: InputMaybe<CountryNullableRelationFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  external_id?: InputMaybe<StringNullableFilter>;
  files?: InputMaybe<FileListRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  iso_3166_1?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  note?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<StringNullableFilter>;
  street?: InputMaybe<StringNullableFilter>;
  zip?: InputMaybe<StringNullableFilter>;
};

export type PutFileInput = {
  key: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  agent?: Maybe<Agent>;
  agents: Array<Agent>;
  aggregateAgent: AggregateAgent;
  aggregateCampaign: AggregateCampaign;
  aggregateCampaign_action: AggregateCampaign_Action;
  aggregateClient: AggregateClient;
  aggregateConfig_campaign_client: AggregateConfig_Campaign_Client;
  aggregateConfig_global: AggregateConfig_Global;
  aggregateContact: AggregateContact;
  aggregateCountry: AggregateCountry;
  aggregateCron: AggregateCron;
  aggregateFile: AggregateFile;
  aggregateList: AggregateList;
  aggregateMail_template: AggregateMail_Template;
  aggregateMessage: AggregateMessage;
  aggregateParameter: AggregateParameter;
  aggregateProject: AggregateProject;
  aggregateSurvey_published: AggregateSurvey_Published;
  aggregateSurvey_result: AggregateSurvey_Result;
  aggregateSurvey_schema: AggregateSurvey_Schema;
  availableCampaigns: AvailableCampaigns;
  campaign?: Maybe<Campaign>;
  campaign_action?: Maybe<Campaign_Action>;
  campaign_actions: Array<Campaign_Action>;
  campaigns: Array<Campaign>;
  canContactDelete: Scalars['Boolean']['output'];
  canProjectDelete: Scalars['Boolean']['output'];
  checkRating: CheckRatingResult;
  client?: Maybe<Client>;
  clients: Array<Client>;
  config_campaign_client?: Maybe<Config_Campaign_Client>;
  config_campaign_clients: Array<Config_Campaign_Client>;
  config_global?: Maybe<Config_Global>;
  config_globals: Array<Config_Global>;
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  cron?: Maybe<Cron>;
  crons: Array<Cron>;
  dkimStatus: AdminDkimStatus;
  /** Check if an agent with the given email exists */
  existAgentWithMail: Scalars['Boolean']['output'];
  existsContactWithMail: Scalars['Boolean']['output'];
  file?: Maybe<File>;
  files: Array<File>;
  findFirstAgent?: Maybe<Agent>;
  findFirstAgentOrThrow?: Maybe<Agent>;
  findFirstCampaign?: Maybe<Campaign>;
  findFirstCampaignOrThrow?: Maybe<Campaign>;
  findFirstCampaign_action?: Maybe<Campaign_Action>;
  findFirstCampaign_actionOrThrow?: Maybe<Campaign_Action>;
  findFirstClient?: Maybe<Client>;
  findFirstClientOrThrow?: Maybe<Client>;
  findFirstConfig_campaign_client?: Maybe<Config_Campaign_Client>;
  findFirstConfig_campaign_clientOrThrow?: Maybe<Config_Campaign_Client>;
  findFirstConfig_global?: Maybe<Config_Global>;
  findFirstConfig_globalOrThrow?: Maybe<Config_Global>;
  findFirstContact?: Maybe<Contact>;
  findFirstContactOrThrow?: Maybe<Contact>;
  findFirstCountry?: Maybe<Country>;
  findFirstCountryOrThrow?: Maybe<Country>;
  findFirstCron?: Maybe<Cron>;
  findFirstCronOrThrow?: Maybe<Cron>;
  findFirstFile?: Maybe<File>;
  findFirstFileOrThrow?: Maybe<File>;
  findFirstList?: Maybe<List>;
  findFirstListOrThrow?: Maybe<List>;
  findFirstMail_template?: Maybe<Mail_Template>;
  findFirstMail_templateOrThrow?: Maybe<Mail_Template>;
  findFirstMessage?: Maybe<Message>;
  findFirstMessageOrThrow?: Maybe<Message>;
  findFirstParameter?: Maybe<Parameter>;
  findFirstParameterOrThrow?: Maybe<Parameter>;
  findFirstProject?: Maybe<Project>;
  findFirstProjectOrThrow?: Maybe<Project>;
  findFirstSurvey_published?: Maybe<Survey_Published>;
  findFirstSurvey_publishedOrThrow?: Maybe<Survey_Published>;
  findFirstSurvey_result?: Maybe<Survey_Result>;
  findFirstSurvey_resultOrThrow?: Maybe<Survey_Result>;
  findFirstSurvey_schema?: Maybe<Survey_Schema>;
  findFirstSurvey_schemaOrThrow?: Maybe<Survey_Schema>;
  getAgent?: Maybe<Agent>;
  getCampaign?: Maybe<Campaign>;
  getCampaign_action?: Maybe<Campaign_Action>;
  getClient?: Maybe<Client>;
  getConfig_campaign_client?: Maybe<Config_Campaign_Client>;
  getConfig_global?: Maybe<Config_Global>;
  getContact?: Maybe<Contact>;
  getCountry?: Maybe<Country>;
  getCron?: Maybe<Cron>;
  getETermin: ETerminOutput;
  getFile?: Maybe<File>;
  getList?: Maybe<List>;
  getMail_template?: Maybe<Mail_Template>;
  getMainConfig: MainConfig;
  getMessage?: Maybe<Message>;
  getParameter?: Maybe<Parameter>;
  getParameterById: ParameterOutput;
  getProject?: Maybe<Project>;
  getProjectSyncData?: Maybe<ProjectSyncDataOutput>;
  getProjectSyncFolders?: Maybe<ProjectSyncFolderList>;
  getProjectSyncLabels?: Maybe<ProjectSyncLabels>;
  getSurvey_published?: Maybe<Survey_Published>;
  getSurvey_result?: Maybe<Survey_Result>;
  getSurvey_schema?: Maybe<Survey_Schema>;
  groupByAgent: Array<AgentGroupBy>;
  groupByCampaign: Array<CampaignGroupBy>;
  groupByCampaign_action: Array<Campaign_ActionGroupBy>;
  groupByClient: Array<ClientGroupBy>;
  groupByConfig_campaign_client: Array<Config_Campaign_ClientGroupBy>;
  groupByConfig_global: Array<Config_GlobalGroupBy>;
  groupByContact: Array<ContactGroupBy>;
  groupByCountry: Array<CountryGroupBy>;
  groupByCron: Array<CronGroupBy>;
  groupByFile: Array<FileGroupBy>;
  groupByList: Array<ListGroupBy>;
  groupByMail_template: Array<Mail_TemplateGroupBy>;
  groupByMessage: Array<MessageGroupBy>;
  groupByParameter: Array<ParameterGroupBy>;
  groupByProject: Array<ProjectGroupBy>;
  groupBySurvey_published: Array<Survey_PublishedGroupBy>;
  groupBySurvey_result: Array<Survey_ResultGroupBy>;
  groupBySurvey_schema: Array<Survey_SchemaGroupBy>;
  list?: Maybe<List>;
  lists: Array<List>;
  mailTemplates: Array<QueryMailTemplatesResponse>;
  mail_template?: Maybe<Mail_Template>;
  mail_templates: Array<Mail_Template>;
  message?: Maybe<Message>;
  messages: Array<Message>;
  parameter?: Maybe<Parameter>;
  parameterAgentList: Array<ParameterAgentListOutput>;
  parameterList: Array<ParameterListOutput>;
  parameters: Array<Parameter>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  survey_published?: Maybe<Survey_Published>;
  survey_publisheds: Array<Survey_Published>;
  survey_result?: Maybe<Survey_Result>;
  survey_results: Array<Survey_Result>;
  survey_schema?: Maybe<Survey_Schema>;
  survey_schemas: Array<Survey_Schema>;
};


export type QueryAgentArgs = {
  where: AgentWhereUniqueInput;
};


export type QueryAgentsArgs = {
  cursor?: InputMaybe<AgentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AgentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AgentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgentWhereInput>;
};


export type QueryAggregateAgentArgs = {
  cursor?: InputMaybe<AgentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AgentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgentWhereInput>;
};


export type QueryAggregateCampaignArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type QueryAggregateCampaign_ActionArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type QueryAggregateClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryAggregateConfig_Campaign_ClientArgs = {
  cursor?: InputMaybe<Config_Campaign_ClientWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Config_Campaign_ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type QueryAggregateConfig_GlobalArgs = {
  cursor?: InputMaybe<Config_GlobalWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Config_GlobalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type QueryAggregateContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryAggregateCountryArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryAggregateCronArgs = {
  cursor?: InputMaybe<CronWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CronOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type QueryAggregateFileArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type QueryAggregateListArgs = {
  cursor?: InputMaybe<ListWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ListOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListWhereInput>;
};


export type QueryAggregateMail_TemplateArgs = {
  cursor?: InputMaybe<Mail_TemplateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Mail_TemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type QueryAggregateMessageArgs = {
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryAggregateParameterArgs = {
  cursor?: InputMaybe<ParameterWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};


export type QueryAggregateProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryAggregateSurvey_PublishedArgs = {
  cursor?: InputMaybe<Survey_PublishedWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type QueryAggregateSurvey_ResultArgs = {
  cursor?: InputMaybe<Survey_ResultWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Survey_ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type QueryAggregateSurvey_SchemaArgs = {
  cursor?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  orderBy?: InputMaybe<Array<Survey_SchemaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};


export type QueryCampaignArgs = {
  where: CampaignWhereUniqueInput;
};


export type QueryCampaign_ActionArgs = {
  where: Campaign_ActionWhereUniqueInput;
};


export type QueryCampaign_ActionsArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type QueryCampaignsArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type QueryCanContactDeleteArgs = {
  contact_id: Scalars['String']['input'];
};


export type QueryCanProjectDeleteArgs = {
  project_id: Scalars['String']['input'];
};


export type QueryCheckRatingArgs = {
  campaign_id: Scalars['String']['input'];
};


export type QueryClientArgs = {
  where: ClientWhereUniqueInput;
};


export type QueryClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryConfig_Campaign_ClientArgs = {
  where: Config_Campaign_ClientWhereUniqueInput;
};


export type QueryConfig_Campaign_ClientsArgs = {
  cursor?: InputMaybe<Config_Campaign_ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<Config_Campaign_ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Config_Campaign_ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type QueryConfig_GlobalArgs = {
  where: Config_GlobalWhereUniqueInput;
};


export type QueryConfig_GlobalsArgs = {
  cursor?: InputMaybe<Config_GlobalWhereUniqueInput>;
  distinct?: InputMaybe<Array<Config_GlobalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Config_GlobalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type QueryContactArgs = {
  where: ContactWhereUniqueInput;
};


export type QueryContactsArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryCountriesArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CountryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryCronArgs = {
  where: CronWhereUniqueInput;
};


export type QueryCronsArgs = {
  cursor?: InputMaybe<CronWhereUniqueInput>;
  distinct?: InputMaybe<Array<CronScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CronOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type QueryDkimStatusArgs = {
  address: Scalars['String']['input'];
};


export type QueryExistAgentWithMailArgs = {
  client_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type QueryExistsContactWithMailArgs = {
  client_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type QueryFileArgs = {
  where: FileWhereUniqueInput;
};


export type QueryFilesArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type QueryFindFirstAgentArgs = {
  cursor?: InputMaybe<AgentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AgentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AgentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgentWhereInput>;
};


export type QueryFindFirstAgentOrThrowArgs = {
  cursor?: InputMaybe<AgentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AgentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AgentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgentWhereInput>;
};


export type QueryFindFirstCampaignArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type QueryFindFirstCampaignOrThrowArgs = {
  cursor?: InputMaybe<CampaignWhereUniqueInput>;
  distinct?: InputMaybe<Array<CampaignScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type QueryFindFirstCampaign_ActionArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type QueryFindFirstCampaign_ActionOrThrowArgs = {
  cursor?: InputMaybe<Campaign_ActionWhereUniqueInput>;
  distinct?: InputMaybe<Array<Campaign_ActionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type QueryFindFirstClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryFindFirstClientOrThrowArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryFindFirstConfig_Campaign_ClientArgs = {
  cursor?: InputMaybe<Config_Campaign_ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<Config_Campaign_ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Config_Campaign_ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type QueryFindFirstConfig_Campaign_ClientOrThrowArgs = {
  cursor?: InputMaybe<Config_Campaign_ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<Config_Campaign_ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Config_Campaign_ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type QueryFindFirstConfig_GlobalArgs = {
  cursor?: InputMaybe<Config_GlobalWhereUniqueInput>;
  distinct?: InputMaybe<Array<Config_GlobalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Config_GlobalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type QueryFindFirstConfig_GlobalOrThrowArgs = {
  cursor?: InputMaybe<Config_GlobalWhereUniqueInput>;
  distinct?: InputMaybe<Array<Config_GlobalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Config_GlobalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type QueryFindFirstContactArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryFindFirstContactOrThrowArgs = {
  cursor?: InputMaybe<ContactWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContactScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContactOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryFindFirstCountryArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CountryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryFindFirstCountryOrThrowArgs = {
  cursor?: InputMaybe<CountryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CountryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CountryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryFindFirstCronArgs = {
  cursor?: InputMaybe<CronWhereUniqueInput>;
  distinct?: InputMaybe<Array<CronScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CronOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type QueryFindFirstCronOrThrowArgs = {
  cursor?: InputMaybe<CronWhereUniqueInput>;
  distinct?: InputMaybe<Array<CronScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CronOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type QueryFindFirstFileArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type QueryFindFirstFileOrThrowArgs = {
  cursor?: InputMaybe<FileWhereUniqueInput>;
  distinct?: InputMaybe<Array<FileScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FileOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type QueryFindFirstListArgs = {
  cursor?: InputMaybe<ListWhereUniqueInput>;
  distinct?: InputMaybe<Array<ListScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ListOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListWhereInput>;
};


export type QueryFindFirstListOrThrowArgs = {
  cursor?: InputMaybe<ListWhereUniqueInput>;
  distinct?: InputMaybe<Array<ListScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ListOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListWhereInput>;
};


export type QueryFindFirstMail_TemplateArgs = {
  cursor?: InputMaybe<Mail_TemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<Mail_TemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Mail_TemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type QueryFindFirstMail_TemplateOrThrowArgs = {
  cursor?: InputMaybe<Mail_TemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<Mail_TemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Mail_TemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type QueryFindFirstMessageArgs = {
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryFindFirstMessageOrThrowArgs = {
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryFindFirstParameterArgs = {
  cursor?: InputMaybe<ParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};


export type QueryFindFirstParameterOrThrowArgs = {
  cursor?: InputMaybe<ParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};


export type QueryFindFirstProjectArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstProjectOrThrowArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryFindFirstSurvey_PublishedArgs = {
  cursor?: InputMaybe<Survey_PublishedWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_PublishedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type QueryFindFirstSurvey_PublishedOrThrowArgs = {
  cursor?: InputMaybe<Survey_PublishedWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_PublishedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type QueryFindFirstSurvey_ResultArgs = {
  cursor?: InputMaybe<Survey_ResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_ResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type QueryFindFirstSurvey_ResultOrThrowArgs = {
  cursor?: InputMaybe<Survey_ResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_ResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type QueryFindFirstSurvey_SchemaArgs = {
  cursor?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_SchemaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_SchemaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};


export type QueryFindFirstSurvey_SchemaOrThrowArgs = {
  cursor?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_SchemaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_SchemaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};


export type QueryGetAgentArgs = {
  where: AgentWhereUniqueInput;
};


export type QueryGetCampaignArgs = {
  where: CampaignWhereUniqueInput;
};


export type QueryGetCampaign_ActionArgs = {
  where: Campaign_ActionWhereUniqueInput;
};


export type QueryGetClientArgs = {
  where: ClientWhereUniqueInput;
};


export type QueryGetConfig_Campaign_ClientArgs = {
  where: Config_Campaign_ClientWhereUniqueInput;
};


export type QueryGetConfig_GlobalArgs = {
  where: Config_GlobalWhereUniqueInput;
};


export type QueryGetContactArgs = {
  where: ContactWhereUniqueInput;
};


export type QueryGetCountryArgs = {
  where: CountryWhereUniqueInput;
};


export type QueryGetCronArgs = {
  where: CronWhereUniqueInput;
};


export type QueryGetETerminArgs = {
  where: ETerminInput;
};


export type QueryGetFileArgs = {
  where: FileWhereUniqueInput;
};


export type QueryGetListArgs = {
  where: ListWhereUniqueInput;
};


export type QueryGetMail_TemplateArgs = {
  where: Mail_TemplateWhereUniqueInput;
};


export type QueryGetMainConfigArgs = {
  where: MainConfigInput;
};


export type QueryGetMessageArgs = {
  where: MessageWhereUniqueInput;
};


export type QueryGetParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type QueryGetParameterByIdArgs = {
  where: ParameterInput;
};


export type QueryGetProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryGetProjectSyncFoldersArgs = {
  filter: FolderListFilter;
};


export type QueryGetProjectSyncLabelsArgs = {
  filter: Scalars['String']['input'];
};


export type QueryGetSurvey_PublishedArgs = {
  where: Survey_PublishedWhereUniqueInput;
};


export type QueryGetSurvey_ResultArgs = {
  where: Survey_ResultWhereUniqueInput;
};


export type QueryGetSurvey_SchemaArgs = {
  where: Survey_SchemaWhereUniqueInput;
};


export type QueryGroupByAgentArgs = {
  by: Array<AgentScalarFieldEnum>;
  having?: InputMaybe<AgentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AgentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgentWhereInput>;
};


export type QueryGroupByCampaignArgs = {
  by: Array<CampaignScalarFieldEnum>;
  having?: InputMaybe<CampaignScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CampaignOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CampaignWhereInput>;
};


export type QueryGroupByCampaign_ActionArgs = {
  by: Array<Campaign_ActionScalarFieldEnum>;
  having?: InputMaybe<Campaign_ActionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Campaign_ActionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Campaign_ActionWhereInput>;
};


export type QueryGroupByClientArgs = {
  by: Array<ClientScalarFieldEnum>;
  having?: InputMaybe<ClientScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ClientOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryGroupByConfig_Campaign_ClientArgs = {
  by: Array<Config_Campaign_ClientScalarFieldEnum>;
  having?: InputMaybe<Config_Campaign_ClientScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Config_Campaign_ClientOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_Campaign_ClientWhereInput>;
};


export type QueryGroupByConfig_GlobalArgs = {
  by: Array<Config_GlobalScalarFieldEnum>;
  having?: InputMaybe<Config_GlobalScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Config_GlobalOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Config_GlobalWhereInput>;
};


export type QueryGroupByContactArgs = {
  by: Array<ContactScalarFieldEnum>;
  having?: InputMaybe<ContactScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ContactOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactWhereInput>;
};


export type QueryGroupByCountryArgs = {
  by: Array<CountryScalarFieldEnum>;
  having?: InputMaybe<CountryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CountryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};


export type QueryGroupByCronArgs = {
  by: Array<CronScalarFieldEnum>;
  having?: InputMaybe<CronScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CronOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CronWhereInput>;
};


export type QueryGroupByFileArgs = {
  by: Array<FileScalarFieldEnum>;
  having?: InputMaybe<FileScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<FileOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};


export type QueryGroupByListArgs = {
  by: Array<ListScalarFieldEnum>;
  having?: InputMaybe<ListScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ListOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListWhereInput>;
};


export type QueryGroupByMail_TemplateArgs = {
  by: Array<Mail_TemplateScalarFieldEnum>;
  having?: InputMaybe<Mail_TemplateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Mail_TemplateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type QueryGroupByMessageArgs = {
  by: Array<MessageScalarFieldEnum>;
  having?: InputMaybe<MessageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MessageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryGroupByParameterArgs = {
  by: Array<ParameterScalarFieldEnum>;
  having?: InputMaybe<ParameterScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};


export type QueryGroupByProjectArgs = {
  by: Array<ProjectScalarFieldEnum>;
  having?: InputMaybe<ProjectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QueryGroupBySurvey_PublishedArgs = {
  by: Array<Survey_PublishedScalarFieldEnum>;
  having?: InputMaybe<Survey_PublishedScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type QueryGroupBySurvey_ResultArgs = {
  by: Array<Survey_ResultScalarFieldEnum>;
  having?: InputMaybe<Survey_ResultScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Survey_ResultOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type QueryGroupBySurvey_SchemaArgs = {
  by: Array<Survey_SchemaScalarFieldEnum>;
  having?: InputMaybe<Survey_SchemaScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<Survey_SchemaOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};


export type QueryListArgs = {
  where: ListWhereUniqueInput;
};


export type QueryListsArgs = {
  cursor?: InputMaybe<ListWhereUniqueInput>;
  distinct?: InputMaybe<Array<ListScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ListOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ListWhereInput>;
};


export type QueryMailTemplatesArgs = {
  client_id: Scalars['String']['input'];
};


export type QueryMail_TemplateArgs = {
  where: Mail_TemplateWhereUniqueInput;
};


export type QueryMail_TemplatesArgs = {
  cursor?: InputMaybe<Mail_TemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<Mail_TemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Mail_TemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Mail_TemplateWhereInput>;
};


export type QueryMessageArgs = {
  where: MessageWhereUniqueInput;
};


export type QueryMessagesArgs = {
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryParameterArgs = {
  where: ParameterWhereUniqueInput;
};


export type QueryParameterAgentListArgs = {
  agent_id: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
};


export type QueryParameterListArgs = {
  client_id: Scalars['String']['input'];
};


export type QueryParametersArgs = {
  cursor?: InputMaybe<ParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<ParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParameterWhereInput>;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryProjectsArgs = {
  cursor?: InputMaybe<ProjectWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProjectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProjectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProjectWhereInput>;
};


export type QuerySurvey_PublishedArgs = {
  where: Survey_PublishedWhereUniqueInput;
};


export type QuerySurvey_PublishedsArgs = {
  cursor?: InputMaybe<Survey_PublishedWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_PublishedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type QuerySurvey_ResultArgs = {
  where: Survey_ResultWhereUniqueInput;
};


export type QuerySurvey_ResultsArgs = {
  cursor?: InputMaybe<Survey_ResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_ResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_ResultWhereInput>;
};


export type QuerySurvey_SchemaArgs = {
  where: Survey_SchemaWhereUniqueInput;
};


export type QuerySurvey_SchemasArgs = {
  cursor?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_SchemaScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_SchemaOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type QueryMailTemplatesResponse = {
  __typename?: 'QueryMailTemplatesResponse';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isGlobal: Scalars['Boolean']['output'];
  scenario_name: Mail_Scenario;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RatingInput = {
  campaign_id: Scalars['String']['input'];
  evaluation: Scalars['String']['input'];
  stars: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StartCampaignResponse = {
  __typename?: 'StartCampaignResponse';
  campaign_action?: Maybe<Scalars['JSONObject']['output']>;
  campaign_type: Campaign_Type;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
};

export type StartMultipartUploadInput = {
  acl: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type StartMultipartUploadOutput = {
  __typename?: 'StartMultipartUploadOutput';
  uploadId: Scalars['String']['output'];
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Survey_Published = {
  __typename?: 'Survey_published';
  client: Client;
  client_id: Scalars['String']['output'];
  content_string?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  survey_schema: Survey_Schema;
  survey_schema_id: Scalars['String']['output'];
};

export type Survey_PublishedCountAggregate = {
  __typename?: 'Survey_publishedCountAggregate';
  _all: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  content_string: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  survey_schema_id: Scalars['Int']['output'];
};

export type Survey_PublishedCountOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_PublishedCreateInput = {
  client: ClientCreateNestedOneWithoutSurvey_PublishedInput;
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema: Survey_SchemaCreateNestedOneWithoutSurvey_PublishedInput;
};

export type Survey_PublishedCreateManyClientInput = {
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema_id: Scalars['String']['input'];
};

export type Survey_PublishedCreateManyClientInputEnvelope = {
  data: Array<Survey_PublishedCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Survey_PublishedCreateManyInput = {
  client_id: Scalars['String']['input'];
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema_id: Scalars['String']['input'];
};

export type Survey_PublishedCreateManySurvey_SchemaInput = {
  client_id: Scalars['String']['input'];
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Survey_PublishedCreateManySurvey_SchemaInputEnvelope = {
  data: Array<Survey_PublishedCreateManySurvey_SchemaInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Survey_PublishedCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_PublishedCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Survey_PublishedCreateWithoutClientInput>>;
  createMany?: InputMaybe<Survey_PublishedCreateManyClientInputEnvelope>;
};

export type Survey_PublishedCreateNestedManyWithoutSurvey_SchemaInput = {
  connect?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_PublishedCreateOrConnectWithoutSurvey_SchemaInput>>;
  create?: InputMaybe<Array<Survey_PublishedCreateWithoutSurvey_SchemaInput>>;
  createMany?: InputMaybe<Survey_PublishedCreateManySurvey_SchemaInputEnvelope>;
};

export type Survey_PublishedCreateOrConnectWithoutClientInput = {
  create: Survey_PublishedCreateWithoutClientInput;
  where: Survey_PublishedWhereUniqueInput;
};

export type Survey_PublishedCreateOrConnectWithoutSurvey_SchemaInput = {
  create: Survey_PublishedCreateWithoutSurvey_SchemaInput;
  where: Survey_PublishedWhereUniqueInput;
};

export type Survey_PublishedCreateWithoutClientInput = {
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema: Survey_SchemaCreateNestedOneWithoutSurvey_PublishedInput;
};

export type Survey_PublishedCreateWithoutSurvey_SchemaInput = {
  client: ClientCreateNestedOneWithoutSurvey_PublishedInput;
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Survey_PublishedGroupBy = {
  __typename?: 'Survey_publishedGroupBy';
  _count?: Maybe<Survey_PublishedCountAggregate>;
  _max?: Maybe<Survey_PublishedMaxAggregate>;
  _min?: Maybe<Survey_PublishedMinAggregate>;
  client_id: Scalars['String']['output'];
  content_string?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  survey_schema_id: Scalars['String']['output'];
};

export type Survey_PublishedListRelationFilter = {
  every?: InputMaybe<Survey_PublishedWhereInput>;
  none?: InputMaybe<Survey_PublishedWhereInput>;
  some?: InputMaybe<Survey_PublishedWhereInput>;
};

export type Survey_PublishedMaxAggregate = {
  __typename?: 'Survey_publishedMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  content_string?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  survey_schema_id?: Maybe<Scalars['String']['output']>;
};

export type Survey_PublishedMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_PublishedMinAggregate = {
  __typename?: 'Survey_publishedMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  content_string?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  survey_schema_id?: Maybe<Scalars['String']['output']>;
};

export type Survey_PublishedMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_PublishedOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Survey_PublishedOrderByWithAggregationInput = {
  _count?: InputMaybe<Survey_PublishedCountOrderByAggregateInput>;
  _max?: InputMaybe<Survey_PublishedMaxOrderByAggregateInput>;
  _min?: InputMaybe<Survey_PublishedMinOrderByAggregateInput>;
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_PublishedOrderByWithRelationInput = {
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  survey_schema?: InputMaybe<Survey_SchemaOrderByWithRelationInput>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export enum Survey_PublishedScalarFieldEnum {
  ClientId = 'client_id',
  ContentString = 'content_string',
  Created = 'created',
  Deleted = 'deleted',
  Id = 'id',
  SurveySchemaId = 'survey_schema_id'
}

export type Survey_PublishedScalarWhereInput = {
  AND?: InputMaybe<Array<Survey_PublishedScalarWhereInput>>;
  NOT?: InputMaybe<Array<Survey_PublishedScalarWhereInput>>;
  OR?: InputMaybe<Array<Survey_PublishedScalarWhereInput>>;
  client_id?: InputMaybe<StringFilter>;
  content_string?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  survey_schema_id?: InputMaybe<UuidFilter>;
};

export type Survey_PublishedScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Survey_PublishedScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Survey_PublishedScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Survey_PublishedScalarWhereWithAggregatesInput>>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  content_string?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  survey_schema_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type Survey_PublishedUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSurvey_PublishedNestedInput>;
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateOneRequiredWithoutSurvey_PublishedNestedInput>;
};

export type Survey_PublishedUpdateManyMutationInput = {
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Survey_PublishedUpdateManyWithWhereWithoutClientInput = {
  data: Survey_PublishedUpdateManyMutationInput;
  where: Survey_PublishedScalarWhereInput;
};

export type Survey_PublishedUpdateManyWithWhereWithoutSurvey_SchemaInput = {
  data: Survey_PublishedUpdateManyMutationInput;
  where: Survey_PublishedScalarWhereInput;
};

export type Survey_PublishedUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_PublishedCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Survey_PublishedCreateWithoutClientInput>>;
  createMany?: InputMaybe<Survey_PublishedCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Survey_PublishedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  set?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  update?: InputMaybe<Array<Survey_PublishedUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<Survey_PublishedUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<Survey_PublishedUpsertWithWhereUniqueWithoutClientInput>>;
};

export type Survey_PublishedUpdateManyWithoutSurvey_SchemaNestedInput = {
  connect?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_PublishedCreateOrConnectWithoutSurvey_SchemaInput>>;
  create?: InputMaybe<Array<Survey_PublishedCreateWithoutSurvey_SchemaInput>>;
  createMany?: InputMaybe<Survey_PublishedCreateManySurvey_SchemaInputEnvelope>;
  delete?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Survey_PublishedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  set?: InputMaybe<Array<Survey_PublishedWhereUniqueInput>>;
  update?: InputMaybe<Array<Survey_PublishedUpdateWithWhereUniqueWithoutSurvey_SchemaInput>>;
  updateMany?: InputMaybe<Array<Survey_PublishedUpdateManyWithWhereWithoutSurvey_SchemaInput>>;
  upsert?: InputMaybe<Array<Survey_PublishedUpsertWithWhereUniqueWithoutSurvey_SchemaInput>>;
};

export type Survey_PublishedUpdateWithWhereUniqueWithoutClientInput = {
  data: Survey_PublishedUpdateWithoutClientInput;
  where: Survey_PublishedWhereUniqueInput;
};

export type Survey_PublishedUpdateWithWhereUniqueWithoutSurvey_SchemaInput = {
  data: Survey_PublishedUpdateWithoutSurvey_SchemaInput;
  where: Survey_PublishedWhereUniqueInput;
};

export type Survey_PublishedUpdateWithoutClientInput = {
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateOneRequiredWithoutSurvey_PublishedNestedInput>;
};

export type Survey_PublishedUpdateWithoutSurvey_SchemaInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSurvey_PublishedNestedInput>;
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Survey_PublishedUpsertWithWhereUniqueWithoutClientInput = {
  create: Survey_PublishedCreateWithoutClientInput;
  update: Survey_PublishedUpdateWithoutClientInput;
  where: Survey_PublishedWhereUniqueInput;
};

export type Survey_PublishedUpsertWithWhereUniqueWithoutSurvey_SchemaInput = {
  create: Survey_PublishedCreateWithoutSurvey_SchemaInput;
  update: Survey_PublishedUpdateWithoutSurvey_SchemaInput;
  where: Survey_PublishedWhereUniqueInput;
};

export type Survey_PublishedWhereInput = {
  AND?: InputMaybe<Array<Survey_PublishedWhereInput>>;
  NOT?: InputMaybe<Array<Survey_PublishedWhereInput>>;
  OR?: InputMaybe<Array<Survey_PublishedWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  content_string?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  survey_schema?: InputMaybe<Survey_SchemaRelationFilter>;
  survey_schema_id?: InputMaybe<UuidFilter>;
};

export type Survey_PublishedWhereUniqueInput = {
  AND?: InputMaybe<Array<Survey_PublishedWhereInput>>;
  NOT?: InputMaybe<Array<Survey_PublishedWhereInput>>;
  OR?: InputMaybe<Array<Survey_PublishedWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  content_string?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema?: InputMaybe<Survey_SchemaRelationFilter>;
  survey_schema_id?: InputMaybe<UuidFilter>;
};

export type Survey_Result = {
  __typename?: 'Survey_result';
  content?: Maybe<Scalars['JSON']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  survey_schema: Survey_Schema;
  survey_schema_id: Scalars['String']['output'];
};

export type Survey_ResultCountAggregate = {
  __typename?: 'Survey_resultCountAggregate';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  survey_schema_id: Scalars['Int']['output'];
};

export type Survey_ResultCountOrderByAggregateInput = {
  content?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_ResultCreateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema: Survey_SchemaCreateNestedOneWithoutSurvey_ResultInput;
};

export type Survey_ResultCreateManyInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema_id: Scalars['String']['input'];
};

export type Survey_ResultCreateManySurvey_SchemaInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Survey_ResultCreateManySurvey_SchemaInputEnvelope = {
  data: Array<Survey_ResultCreateManySurvey_SchemaInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Survey_ResultCreateNestedManyWithoutSurvey_SchemaInput = {
  connect?: InputMaybe<Array<Survey_ResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_ResultCreateOrConnectWithoutSurvey_SchemaInput>>;
  create?: InputMaybe<Array<Survey_ResultCreateWithoutSurvey_SchemaInput>>;
  createMany?: InputMaybe<Survey_ResultCreateManySurvey_SchemaInputEnvelope>;
};

export type Survey_ResultCreateOrConnectWithoutSurvey_SchemaInput = {
  create: Survey_ResultCreateWithoutSurvey_SchemaInput;
  where: Survey_ResultWhereUniqueInput;
};

export type Survey_ResultCreateWithoutSurvey_SchemaInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Survey_ResultGroupBy = {
  __typename?: 'Survey_resultGroupBy';
  _count?: Maybe<Survey_ResultCountAggregate>;
  _max?: Maybe<Survey_ResultMaxAggregate>;
  _min?: Maybe<Survey_ResultMinAggregate>;
  content?: Maybe<Scalars['JSON']['output']>;
  created: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  survey_schema_id: Scalars['String']['output'];
};

export type Survey_ResultListRelationFilter = {
  every?: InputMaybe<Survey_ResultWhereInput>;
  none?: InputMaybe<Survey_ResultWhereInput>;
  some?: InputMaybe<Survey_ResultWhereInput>;
};

export type Survey_ResultMaxAggregate = {
  __typename?: 'Survey_resultMaxAggregate';
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  survey_schema_id?: Maybe<Scalars['String']['output']>;
};

export type Survey_ResultMaxOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_ResultMinAggregate = {
  __typename?: 'Survey_resultMinAggregate';
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  survey_schema_id?: Maybe<Scalars['String']['output']>;
};

export type Survey_ResultMinOrderByAggregateInput = {
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_ResultOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Survey_ResultOrderByWithAggregationInput = {
  _count?: InputMaybe<Survey_ResultCountOrderByAggregateInput>;
  _max?: InputMaybe<Survey_ResultMaxOrderByAggregateInput>;
  _min?: InputMaybe<Survey_ResultMinOrderByAggregateInput>;
  content?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export type Survey_ResultOrderByWithRelationInput = {
  content?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  survey_schema?: InputMaybe<Survey_SchemaOrderByWithRelationInput>;
  survey_schema_id?: InputMaybe<SortOrder>;
};

export enum Survey_ResultScalarFieldEnum {
  Content = 'content',
  Created = 'created',
  Id = 'id',
  SurveySchemaId = 'survey_schema_id'
}

export type Survey_ResultScalarWhereInput = {
  AND?: InputMaybe<Array<Survey_ResultScalarWhereInput>>;
  NOT?: InputMaybe<Array<Survey_ResultScalarWhereInput>>;
  OR?: InputMaybe<Array<Survey_ResultScalarWhereInput>>;
  content?: InputMaybe<JsonNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  survey_schema_id?: InputMaybe<UuidFilter>;
};

export type Survey_ResultScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Survey_ResultScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Survey_ResultScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Survey_ResultScalarWhereWithAggregatesInput>>;
  content?: InputMaybe<JsonNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  survey_schema_id?: InputMaybe<UuidWithAggregatesFilter>;
};

export type Survey_ResultUpdateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  survey_schema?: InputMaybe<Survey_SchemaUpdateOneRequiredWithoutSurvey_ResultNestedInput>;
};

export type Survey_ResultUpdateManyMutationInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Survey_ResultUpdateManyWithWhereWithoutSurvey_SchemaInput = {
  data: Survey_ResultUpdateManyMutationInput;
  where: Survey_ResultScalarWhereInput;
};

export type Survey_ResultUpdateManyWithoutSurvey_SchemaNestedInput = {
  connect?: InputMaybe<Array<Survey_ResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_ResultCreateOrConnectWithoutSurvey_SchemaInput>>;
  create?: InputMaybe<Array<Survey_ResultCreateWithoutSurvey_SchemaInput>>;
  createMany?: InputMaybe<Survey_ResultCreateManySurvey_SchemaInputEnvelope>;
  delete?: InputMaybe<Array<Survey_ResultWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Survey_ResultScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Survey_ResultWhereUniqueInput>>;
  set?: InputMaybe<Array<Survey_ResultWhereUniqueInput>>;
  update?: InputMaybe<Array<Survey_ResultUpdateWithWhereUniqueWithoutSurvey_SchemaInput>>;
  updateMany?: InputMaybe<Array<Survey_ResultUpdateManyWithWhereWithoutSurvey_SchemaInput>>;
  upsert?: InputMaybe<Array<Survey_ResultUpsertWithWhereUniqueWithoutSurvey_SchemaInput>>;
};

export type Survey_ResultUpdateWithWhereUniqueWithoutSurvey_SchemaInput = {
  data: Survey_ResultUpdateWithoutSurvey_SchemaInput;
  where: Survey_ResultWhereUniqueInput;
};

export type Survey_ResultUpdateWithoutSurvey_SchemaInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type Survey_ResultUpsertWithWhereUniqueWithoutSurvey_SchemaInput = {
  create: Survey_ResultCreateWithoutSurvey_SchemaInput;
  update: Survey_ResultUpdateWithoutSurvey_SchemaInput;
  where: Survey_ResultWhereUniqueInput;
};

export type Survey_ResultWhereInput = {
  AND?: InputMaybe<Array<Survey_ResultWhereInput>>;
  NOT?: InputMaybe<Array<Survey_ResultWhereInput>>;
  OR?: InputMaybe<Array<Survey_ResultWhereInput>>;
  content?: InputMaybe<JsonNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  survey_schema?: InputMaybe<Survey_SchemaRelationFilter>;
  survey_schema_id?: InputMaybe<UuidFilter>;
};

export type Survey_ResultWhereUniqueInput = {
  AND?: InputMaybe<Array<Survey_ResultWhereInput>>;
  NOT?: InputMaybe<Array<Survey_ResultWhereInput>>;
  OR?: InputMaybe<Array<Survey_ResultWhereInput>>;
  content?: InputMaybe<JsonNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  survey_schema?: InputMaybe<Survey_SchemaRelationFilter>;
  survey_schema_id?: InputMaybe<UuidFilter>;
};

export type Survey_Schema = {
  __typename?: 'Survey_schema';
  _count?: Maybe<Survey_SchemaCount>;
  client: Client;
  client_id: Scalars['String']['output'];
  content_string?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  survey_published: Array<Survey_Published>;
  survey_result: Array<Survey_Result>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};


export type Survey_SchemaSurvey_PublishedArgs = {
  cursor?: InputMaybe<Survey_PublishedWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_PublishedScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_PublishedOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type Survey_SchemaSurvey_ResultArgs = {
  cursor?: InputMaybe<Survey_ResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<Survey_ResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<Survey_ResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<Survey_ResultWhereInput>;
};

export type Survey_SchemaCount = {
  __typename?: 'Survey_schemaCount';
  survey_published: Scalars['Int']['output'];
  survey_result: Scalars['Int']['output'];
};


export type Survey_SchemaCountSurvey_PublishedArgs = {
  where?: InputMaybe<Survey_PublishedWhereInput>;
};


export type Survey_SchemaCountSurvey_ResultArgs = {
  where?: InputMaybe<Survey_ResultWhereInput>;
};

export type Survey_SchemaCountAggregate = {
  __typename?: 'Survey_schemaCountAggregate';
  _all: Scalars['Int']['output'];
  client_id: Scalars['Int']['output'];
  content_string: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  deleted: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updated: Scalars['Int']['output'];
};

export type Survey_SchemaCountOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type Survey_SchemaCreateInput = {
  client: ClientCreateNestedOneWithoutSurvey_SchemaInput;
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutSurvey_SchemaInput>;
  survey_result?: InputMaybe<Survey_ResultCreateNestedManyWithoutSurvey_SchemaInput>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Survey_SchemaCreateManyClientInput = {
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Survey_SchemaCreateManyClientInputEnvelope = {
  data: Array<Survey_SchemaCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Survey_SchemaCreateManyInput = {
  client_id: Scalars['String']['input'];
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Survey_SchemaCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<Survey_SchemaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_SchemaCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Survey_SchemaCreateWithoutClientInput>>;
  createMany?: InputMaybe<Survey_SchemaCreateManyClientInputEnvelope>;
};

export type Survey_SchemaCreateNestedOneWithoutSurvey_PublishedInput = {
  connect?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Survey_SchemaCreateOrConnectWithoutSurvey_PublishedInput>;
  create?: InputMaybe<Survey_SchemaCreateWithoutSurvey_PublishedInput>;
};

export type Survey_SchemaCreateNestedOneWithoutSurvey_ResultInput = {
  connect?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Survey_SchemaCreateOrConnectWithoutSurvey_ResultInput>;
  create?: InputMaybe<Survey_SchemaCreateWithoutSurvey_ResultInput>;
};

export type Survey_SchemaCreateOrConnectWithoutClientInput = {
  create: Survey_SchemaCreateWithoutClientInput;
  where: Survey_SchemaWhereUniqueInput;
};

export type Survey_SchemaCreateOrConnectWithoutSurvey_PublishedInput = {
  create: Survey_SchemaCreateWithoutSurvey_PublishedInput;
  where: Survey_SchemaWhereUniqueInput;
};

export type Survey_SchemaCreateOrConnectWithoutSurvey_ResultInput = {
  create: Survey_SchemaCreateWithoutSurvey_ResultInput;
  where: Survey_SchemaWhereUniqueInput;
};

export type Survey_SchemaCreateWithoutClientInput = {
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutSurvey_SchemaInput>;
  survey_result?: InputMaybe<Survey_ResultCreateNestedManyWithoutSurvey_SchemaInput>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Survey_SchemaCreateWithoutSurvey_PublishedInput = {
  client: ClientCreateNestedOneWithoutSurvey_SchemaInput;
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  survey_result?: InputMaybe<Survey_ResultCreateNestedManyWithoutSurvey_SchemaInput>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Survey_SchemaCreateWithoutSurvey_ResultInput = {
  client: ClientCreateNestedOneWithoutSurvey_SchemaInput;
  content_string?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deleted?: InputMaybe<Scalars['DateTimeISO']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  survey_published?: InputMaybe<Survey_PublishedCreateNestedManyWithoutSurvey_SchemaInput>;
  updated?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type Survey_SchemaGroupBy = {
  __typename?: 'Survey_schemaGroupBy';
  _count?: Maybe<Survey_SchemaCountAggregate>;
  _max?: Maybe<Survey_SchemaMaxAggregate>;
  _min?: Maybe<Survey_SchemaMinAggregate>;
  client_id: Scalars['String']['output'];
  content_string?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTimeISO']['output'];
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Survey_SchemaListRelationFilter = {
  every?: InputMaybe<Survey_SchemaWhereInput>;
  none?: InputMaybe<Survey_SchemaWhereInput>;
  some?: InputMaybe<Survey_SchemaWhereInput>;
};

export type Survey_SchemaMaxAggregate = {
  __typename?: 'Survey_schemaMaxAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  content_string?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Survey_SchemaMaxOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type Survey_SchemaMinAggregate = {
  __typename?: 'Survey_schemaMinAggregate';
  client_id?: Maybe<Scalars['String']['output']>;
  content_string?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTimeISO']['output']>;
  deleted?: Maybe<Scalars['DateTimeISO']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type Survey_SchemaMinOrderByAggregateInput = {
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrder>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updated?: InputMaybe<SortOrder>;
};

export type Survey_SchemaOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type Survey_SchemaOrderByWithAggregationInput = {
  _count?: InputMaybe<Survey_SchemaCountOrderByAggregateInput>;
  _max?: InputMaybe<Survey_SchemaMaxOrderByAggregateInput>;
  _min?: InputMaybe<Survey_SchemaMinOrderByAggregateInput>;
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export type Survey_SchemaOrderByWithRelationInput = {
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  client_id?: InputMaybe<SortOrder>;
  content_string?: InputMaybe<SortOrderInput>;
  created?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrderInput>;
  survey_published?: InputMaybe<Survey_PublishedOrderByRelationAggregateInput>;
  survey_result?: InputMaybe<Survey_ResultOrderByRelationAggregateInput>;
  updated?: InputMaybe<SortOrderInput>;
};

export type Survey_SchemaRelationFilter = {
  is?: InputMaybe<Survey_SchemaWhereInput>;
  isNot?: InputMaybe<Survey_SchemaWhereInput>;
};

export enum Survey_SchemaScalarFieldEnum {
  ClientId = 'client_id',
  ContentString = 'content_string',
  Created = 'created',
  Deleted = 'deleted',
  Id = 'id',
  Name = 'name',
  Updated = 'updated'
}

export type Survey_SchemaScalarWhereInput = {
  AND?: InputMaybe<Array<Survey_SchemaScalarWhereInput>>;
  NOT?: InputMaybe<Array<Survey_SchemaScalarWhereInput>>;
  OR?: InputMaybe<Array<Survey_SchemaScalarWhereInput>>;
  client_id?: InputMaybe<StringFilter>;
  content_string?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type Survey_SchemaScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<Survey_SchemaScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<Survey_SchemaScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<Survey_SchemaScalarWhereWithAggregatesInput>>;
  client_id?: InputMaybe<StringWithAggregatesFilter>;
  content_string?: InputMaybe<StringNullableWithAggregatesFilter>;
  created?: InputMaybe<DateTimeWithAggregatesFilter>;
  deleted?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<UuidWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  updated?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type Survey_SchemaUpdateInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSurvey_SchemaNestedInput>;
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutSurvey_SchemaNestedInput>;
  survey_result?: InputMaybe<Survey_ResultUpdateManyWithoutSurvey_SchemaNestedInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Survey_SchemaUpdateManyMutationInput = {
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Survey_SchemaUpdateManyWithWhereWithoutClientInput = {
  data: Survey_SchemaUpdateManyMutationInput;
  where: Survey_SchemaScalarWhereInput;
};

export type Survey_SchemaUpdateManyWithoutClientNestedInput = {
  connect?: InputMaybe<Array<Survey_SchemaWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<Survey_SchemaCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<Survey_SchemaCreateWithoutClientInput>>;
  createMany?: InputMaybe<Survey_SchemaCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<Survey_SchemaWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<Survey_SchemaScalarWhereInput>>;
  disconnect?: InputMaybe<Array<Survey_SchemaWhereUniqueInput>>;
  set?: InputMaybe<Array<Survey_SchemaWhereUniqueInput>>;
  update?: InputMaybe<Array<Survey_SchemaUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<Survey_SchemaUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<Survey_SchemaUpsertWithWhereUniqueWithoutClientInput>>;
};

export type Survey_SchemaUpdateOneRequiredWithoutSurvey_PublishedNestedInput = {
  connect?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Survey_SchemaCreateOrConnectWithoutSurvey_PublishedInput>;
  create?: InputMaybe<Survey_SchemaCreateWithoutSurvey_PublishedInput>;
  update?: InputMaybe<Survey_SchemaUpdateToOneWithWhereWithoutSurvey_PublishedInput>;
  upsert?: InputMaybe<Survey_SchemaUpsertWithoutSurvey_PublishedInput>;
};

export type Survey_SchemaUpdateOneRequiredWithoutSurvey_ResultNestedInput = {
  connect?: InputMaybe<Survey_SchemaWhereUniqueInput>;
  connectOrCreate?: InputMaybe<Survey_SchemaCreateOrConnectWithoutSurvey_ResultInput>;
  create?: InputMaybe<Survey_SchemaCreateWithoutSurvey_ResultInput>;
  update?: InputMaybe<Survey_SchemaUpdateToOneWithWhereWithoutSurvey_ResultInput>;
  upsert?: InputMaybe<Survey_SchemaUpsertWithoutSurvey_ResultInput>;
};

export type Survey_SchemaUpdateToOneWithWhereWithoutSurvey_PublishedInput = {
  data: Survey_SchemaUpdateWithoutSurvey_PublishedInput;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type Survey_SchemaUpdateToOneWithWhereWithoutSurvey_ResultInput = {
  data: Survey_SchemaUpdateWithoutSurvey_ResultInput;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type Survey_SchemaUpdateWithWhereUniqueWithoutClientInput = {
  data: Survey_SchemaUpdateWithoutClientInput;
  where: Survey_SchemaWhereUniqueInput;
};

export type Survey_SchemaUpdateWithoutClientInput = {
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutSurvey_SchemaNestedInput>;
  survey_result?: InputMaybe<Survey_ResultUpdateManyWithoutSurvey_SchemaNestedInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Survey_SchemaUpdateWithoutSurvey_PublishedInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSurvey_SchemaNestedInput>;
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survey_result?: InputMaybe<Survey_ResultUpdateManyWithoutSurvey_SchemaNestedInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Survey_SchemaUpdateWithoutSurvey_ResultInput = {
  client?: InputMaybe<ClientUpdateOneRequiredWithoutSurvey_SchemaNestedInput>;
  content_string?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  created?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleted?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  survey_published?: InputMaybe<Survey_PublishedUpdateManyWithoutSurvey_SchemaNestedInput>;
  updated?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
};

export type Survey_SchemaUpsertWithWhereUniqueWithoutClientInput = {
  create: Survey_SchemaCreateWithoutClientInput;
  update: Survey_SchemaUpdateWithoutClientInput;
  where: Survey_SchemaWhereUniqueInput;
};

export type Survey_SchemaUpsertWithoutSurvey_PublishedInput = {
  create: Survey_SchemaCreateWithoutSurvey_PublishedInput;
  update: Survey_SchemaUpdateWithoutSurvey_PublishedInput;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type Survey_SchemaUpsertWithoutSurvey_ResultInput = {
  create: Survey_SchemaCreateWithoutSurvey_ResultInput;
  update: Survey_SchemaUpdateWithoutSurvey_ResultInput;
  where?: InputMaybe<Survey_SchemaWhereInput>;
};

export type Survey_SchemaWhereInput = {
  AND?: InputMaybe<Array<Survey_SchemaWhereInput>>;
  NOT?: InputMaybe<Array<Survey_SchemaWhereInput>>;
  OR?: InputMaybe<Array<Survey_SchemaWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  content_string?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  name?: InputMaybe<StringNullableFilter>;
  survey_published?: InputMaybe<Survey_PublishedListRelationFilter>;
  survey_result?: InputMaybe<Survey_ResultListRelationFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

export type Survey_SchemaWhereUniqueInput = {
  AND?: InputMaybe<Array<Survey_SchemaWhereInput>>;
  NOT?: InputMaybe<Array<Survey_SchemaWhereInput>>;
  OR?: InputMaybe<Array<Survey_SchemaWhereInput>>;
  client?: InputMaybe<ClientRelationFilter>;
  client_id?: InputMaybe<StringFilter>;
  content_string?: InputMaybe<StringNullableFilter>;
  created?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringNullableFilter>;
  survey_published?: InputMaybe<Survey_PublishedListRelationFilter>;
  survey_result?: InputMaybe<Survey_ResultListRelationFilter>;
  updated?: InputMaybe<DateTimeNullableFilter>;
};

/** Users Type */
export type Users = {
  __typename?: 'Users';
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AgentEmailClient_IdCompoundUniqueInput = {
  client_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export enum Campaign_Action_Main_State {
  Appointment = 'appointment',
  Call = 'call',
  Campaign = 'campaign',
  File = 'file',
  Link = 'link',
  Mail = 'mail',
  Rating = 'rating',
  Signature = 'signature',
  Video = 'video'
}

export enum Campaign_Action_Sub_State {
  Booked = 'booked',
  Bounced = 'bounced',
  Clicked = 'clicked',
  Closed = 'closed',
  Complaint = 'complaint',
  Completed = 'completed',
  Created = 'created',
  Delivered = 'delivered',
  DeliveryDelay = 'delivery_delay',
  DidNotTakePlace = 'did_not_take_place',
  Done = 'done',
  Download = 'download',
  Ended = 'ended',
  Error = 'error',
  Feedback = 'feedback',
  Fifty = 'fifty',
  Five = 'five',
  Four = 'four',
  Google = 'google',
  NotBooked = 'not_booked',
  One = 'one',
  Opened = 'opened',
  Paused = 'paused',
  Played = 'played',
  Record = 'record',
  Recorded = 'recorded',
  Rejected = 'rejected',
  RenderingError = 'rendering_error',
  Resumed = 'resumed',
  Scheduled = 'scheduled',
  Sent = 'sent',
  Skipped = 'skipped',
  Started = 'started',
  Subscribed = 'subscribed',
  Three = 'three',
  Two = 'two',
  Unknown = 'unknown',
  Viewed = 'viewed'
}

export enum Campaign_Type {
  Appointment = 'appointment',
  Offer = 'offer',
  Rating = 'rating',
  Undefined = 'undefined'
}

export type ClientUser_Pool_IdClient_IdCompoundUniqueInput = {
  client_id: Scalars['String']['input'];
  user_pool_id: Scalars['String']['input'];
};

export enum Config_Name {
  Campaign = 'campaign',
  CampaignRating = 'campaign_rating',
  Main = 'main'
}

export type ContactEmailClient_IdCompoundUniqueInput = {
  client_id: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export enum Cron_Job {
  Appointment = 'appointment',
  AppointmentEnd = 'appointment_end',
  Offer = 'offer',
  OfferFollowUp_1 = 'offer_follow_up_1',
  OfferFollowUp_2 = 'offer_follow_up_2',
  OfferFollowUp_3 = 'offer_follow_up_3',
  OfferFollowUp_4 = 'offer_follow_up_4',
  OfferFollowUp_5 = 'offer_follow_up_5',
  OfferFollowUpAction_1 = 'offer_follow_up_action_1',
  OfferFollowUpAction_2 = 'offer_follow_up_action_2',
  OfferFollowUpAction_3 = 'offer_follow_up_action_3',
  OfferFollowUpAction_4 = 'offer_follow_up_action_4',
  OfferFollowUpAction_5 = 'offer_follow_up_action_5',
  Rating = 'rating',
  RatingEnd = 'rating_end',
  RatingFeedback = 'rating_feedback',
  RatingReminder = 'rating_reminder'
}

export enum Cron_State {
  Canceled = 'canceled',
  Done = 'done',
  Error = 'error',
  Running = 'running',
  Scheduled = 'scheduled',
  Waiting = 'waiting'
}

export enum Formal {
  CompanyFormal = 'company_formal',
  CompanyInformal = 'company_informal',
  Formal = 'formal',
  Informal = 'informal'
}

export enum ListType {
  Calculate = 'calculate',
  Lookup = 'lookup'
}

export enum Mail_Scenario {
  CampaignAppointment = 'campaign_appointment',
  CampaignOffer = 'campaign_offer',
  CampaignPreBid = 'campaign_pre_bid',
  CampaignRating = 'campaign_rating',
  Footer = 'footer',
  Header = 'header',
  Notification = 'notification',
  Signature = 'signature',
  WrapperFooter = 'wrapper_footer',
  WrapperHeader = 'wrapper_header'
}

export enum Mail_Template_Name {
  AppointmentCompanyFormal = 'appointment_company_formal',
  AppointmentCompanyInformal = 'appointment_company_informal',
  AppointmentFamilyFormal = 'appointment_family_formal',
  AppointmentFamilyInformal = 'appointment_family_informal',
  AppointmentFormal = 'appointment_formal',
  AppointmentInformal = 'appointment_informal',
  CognitoAdminCreateUser = 'cognito_adminCreateUser',
  CognitoAuthentication = 'cognito_authentication',
  CognitoForgetPassword = 'cognito_forgetPassword',
  CognitoResendCode = 'cognito_resendCode',
  CognitoSignup = 'cognito_signup',
  CognitoUpdateUserAttribute = 'cognito_updateUserAttribute',
  CognitoVerifyUserAttribute = 'cognito_verifyUserAttribute',
  Footer = 'footer',
  Header = 'header',
  Inquiry = 'inquiry',
  NotificationFooter = 'notification_footer',
  NotificationHeader = 'notification_header',
  NotificationOfferFollowup = 'notification_offer_followup',
  NotificationRatingFeedback = 'notification_rating_feedback',
  Offer = 'offer',
  OfferFormal = 'offer_formal',
  OfferFormalCompany = 'offer_formal_company',
  OfferFormalCompanyFollowup_1 = 'offer_formal_company_followup_1',
  OfferFormalCompanyFollowup_2 = 'offer_formal_company_followup_2',
  OfferFormalCompanyFollowup_3 = 'offer_formal_company_followup_3',
  OfferFormalCompanyFollowup_4 = 'offer_formal_company_followup_4',
  OfferFormalCompanyFollowup_5 = 'offer_formal_company_followup_5',
  OfferFormalFamily = 'offer_formal_family',
  OfferFormalFamilyFollowup_1 = 'offer_formal_family_followup_1',
  OfferFormalFamilyFollowup_2 = 'offer_formal_family_followup_2',
  OfferFormalFamilyFollowup_3 = 'offer_formal_family_followup_3',
  OfferFormalFamilyFollowup_4 = 'offer_formal_family_followup_4',
  OfferFormalFamilyFollowup_5 = 'offer_formal_family_followup_5',
  OfferFormalFollowup_1 = 'offer_formal_followup_1',
  OfferFormalFollowup_2 = 'offer_formal_followup_2',
  OfferFormalFollowup_3 = 'offer_formal_followup_3',
  OfferFormalFollowup_4 = 'offer_formal_followup_4',
  OfferFormalFollowup_5 = 'offer_formal_followup_5',
  OfferFormalVideo = 'offer_formal_video',
  OfferFormalVideoCompany = 'offer_formal_video_company',
  OfferFormalVideoFamily = 'offer_formal_video_family',
  OfferInformal = 'offer_informal',
  OfferInformalCompany = 'offer_informal_company',
  OfferInformalCompanyFollowup_1 = 'offer_informal_company_followup_1',
  OfferInformalCompanyFollowup_2 = 'offer_informal_company_followup_2',
  OfferInformalCompanyFollowup_3 = 'offer_informal_company_followup_3',
  OfferInformalCompanyFollowup_4 = 'offer_informal_company_followup_4',
  OfferInformalCompanyFollowup_5 = 'offer_informal_company_followup_5',
  OfferInformalFamily = 'offer_informal_family',
  OfferInformalFamilyFollowup_1 = 'offer_informal_family_followup_1',
  OfferInformalFamilyFollowup_2 = 'offer_informal_family_followup_2',
  OfferInformalFamilyFollowup_3 = 'offer_informal_family_followup_3',
  OfferInformalFamilyFollowup_4 = 'offer_informal_family_followup_4',
  OfferInformalFamilyFollowup_5 = 'offer_informal_family_followup_5',
  OfferInformalFollowup_1 = 'offer_informal_followup_1',
  OfferInformalFollowup_2 = 'offer_informal_followup_2',
  OfferInformalFollowup_3 = 'offer_informal_followup_3',
  OfferInformalFollowup_4 = 'offer_informal_followup_4',
  OfferInformalFollowup_5 = 'offer_informal_followup_5',
  OfferInformalVideo = 'offer_informal_video',
  OfferInformalVideoCompany = 'offer_informal_video_company',
  OfferInformalVideoFamily = 'offer_informal_video_family',
  RatingFormalCompanyDemand = 'rating_formal_company_demand',
  RatingFormalCompanySurvey = 'rating_formal_company_survey',
  RatingFormalDemand = 'rating_formal_demand',
  RatingFormalFamilyDemand = 'rating_formal_family_demand',
  RatingFormalFamilySurvey = 'rating_formal_family_survey',
  RatingFormalSurvey = 'rating_formal_survey',
  RatingInformalCompanyDemand = 'rating_informal_company_demand',
  RatingInformalCompanySurvey = 'rating_informal_company_survey',
  RatingInformalDemand = 'rating_informal_demand',
  RatingInformalFamilyDemand = 'rating_informal_family_demand',
  RatingInformalFamilySurvey = 'rating_informal_family_survey',
  RatingInformalSurvey = 'rating_informal_survey'
}

export type ParameterScenario_NameKey_NameClient_IdAgent_IdCompoundUniqueInput = {
  agent_id: Scalars['String']['input'];
  client_id: Scalars['String']['input'];
  key_name: Parameter_Key;
  scenario_name: Parameter_Scenario;
};

export enum Parameter_Key {
  BuDu = 'bu_du',
  BuSie = 'bu_sie',
  BufDu = 'buf_du',
  BufSie = 'buf_sie',
  Craftnote = 'craftnote',
  DisableOffer = 'disableOffer',
  DisableRating = 'disableRating',
  Etermin = 'etermin',
  Hero = 'hero',
  KlicktippCustomerKey = 'klicktipp_customer_key',
  Kunde = 'kunde',
  Mail = 'mail',
  Mails = 'mails',
  Memomeister = 'memomeister',
  PlaceholderList = 'placeholder_list',
  SmtpReader = 'smtp_reader',
  Sproof = 'sproof',
  UmfrageDu = 'umfrage_du',
  UmfrageSie = 'umfrage_sie',
  Unde = 'unde'
}

export enum Parameter_Scenario {
  CampaignAppointment = 'campaign_appointment',
  CampaignOffer = 'campaign_offer',
  CampaignPreBid = 'campaign_pre_bid',
  CampaignRating = 'campaign_rating',
  General = 'general',
  Global = 'global'
}

export enum Parameter_Type {
  Mail = 'mail',
  PlaceholderList = 'placeholder_list'
}

export enum Salutation {
  Cp = 'cp',
  Fm = 'fm',
  Mr = 'mr',
  Ms = 'ms',
  Mx = 'mx'
}

export enum Tag_File {
  Custom = 'custom',
  Inquiry = 'inquiry',
  InquiryFile = 'inquiry_file',
  Offer = 'offer',
  OfferFile = 'offer_file',
  OfferVideo = 'offer_video',
  ProjectVideo = 'project_video',
  Signed = 'signed',
  Viewed = 'viewed'
}

export enum User_Role {
  Admin = 'admin',
  Guest = 'guest',
  Moderator = 'moderator',
  User = 'user'
}
