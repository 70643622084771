import {
  DraggerProps as AntDraggerProps,
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/es/upload';
import { Component, FC } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Rule } from 'antd/es/form';
import { ColProps, Typography } from 'antd';
import FileHandler from '@/utils/s3';
import Dragger from 'antd/es/upload/Dragger';
import { ProForm } from '@ant-design/pro-components';
import { ProFormUploadDragger } from '@ant-design/pro-components';
import { useIntl } from '@umijs/max';

interface FormDraggerProps extends AntDraggerProps {
  title: string;
  tags?: Array<string>;
  rules?: Rule[];
  name: string;
  onChange?: (info: UploadChangeParam<UploadFile<RcFile>>) => void;
  wrapperCol?: ColProps;
  labelCol?: ColProps;
  folder: string;
  onFilesUploaded?: (files: Array<FileType>) => void;
}

const defaults: AntDraggerProps = {
  multiple: true,
  listType: 'picture',
};

type FileType = {
  name: string;
  mime: string;
  id?: string;
  size: number;
  url: string;
  s3key: string;
  tags?: Array<string>;
};

interface DraggerState {
  value: Array<FileType>;
}

export class FileDragger extends Component<FormDraggerProps, DraggerState> {
  constructor(props: FormDraggerProps) {
    super(props);
    this.state = { value: [] };
  }

  render() {
    return (
      <Dragger {...defaults} {...this.props}>
        <Typography.Title level={2}>{this.props.title}</Typography.Title>
        <Typography.Paragraph>
          <InboxOutlined style={{ fontSize: '60px' }} />
        </Typography.Paragraph>
        {this.props.maxCount === 1 ? (
          <>
            <Typography.Paragraph>
              Klicken oder Datei hier ablegen, um sie hochzuladen.
            </Typography.Paragraph>
            <Typography.Paragraph>Maximal 1 Datei erlaubt</Typography.Paragraph>
          </>
        ) : (
          <>
            <Typography.Paragraph>
              Klicken oder Dateien hier ablegen, um sie hochzuladen.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Maximal {this.props.maxCount} Dateien erlaubt
            </Typography.Paragraph>
          </>
        )}
      </Dragger>
    );
  }
}

export class FormDragger extends Component<FormDraggerProps, DraggerState> {
  constructor(props: FormDraggerProps) {
    super(props);
    this.state = { value: [] };
  }
  // TODO upload Anzeige besser machen, Temp Ordner

  onRemove = (file: UploadFile<RcFile>) => {
    const index = this.state.value.findIndex((f) => f.id === file.uid);
    const newvalue = this.state.value.slice();
    newvalue.splice(index, 1);
    this.props.onFilesUploaded?.(newvalue);
    this.setState({ value: newvalue });
  };

  beforeUpload = async (file: RcFile, files: Array<RcFile>): Promise<boolean> => {
    const bucket = `https://${window.localStorage.getItem('bucket')}.s3.eu-west-1.amazonaws.com/`;
    const upl = await FileHandler.init();
    const date = Math.floor(Date.now() / 1000);
    const path =
      this.props.folder.lastIndexOf('/') === this.props.folder.length - 1
        ? `${this.props.folder}${date}-${file.name}`
        : `${this.props.folder}/${date}-${file.name}`;

    await upl.upload({
      Key: path,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    });

    const newFiles: Array<FileType> = await Promise.all(
      files.map(async (f) => {
        const path =
          this.props.folder.lastIndexOf('/') === this.props.folder.length - 1
            ? `${this.props.folder}${date}-${f.name}`
            : `${this.props.folder}/${date}-${f.name}`;

        const index = this.state.value.findIndex((v) => v.id === f.uid);
        if (index > -1) return this.state.value[index];
        return {
          name: f.name,
          size: f.size,
          mime: f.type,
          url: bucket + path,
          s3key: path,
          tags: this.props.tags,
        };
      }),
    );

    if (newFiles) {
      if (this.props.onFilesUploaded) this.props.onFilesUploaded(newFiles);
      const state = new Promise((resolve) => {
        this.setState({ value: newFiles }, () => {
          resolve(true);
        });
      });
      await state;
      return Promise.resolve(false);
    } else return Promise.reject('no files');
  };

  getValueFromEvent = () => {
    return this.state.value;
  };

  onChange = (info: UploadChangeParam<UploadFile<RcFile>>) => {
    if (this.props.onChange) this.props.onChange(info);
  };

  render() {
    return (
      <ProForm.Item
        labelCol={this.props.labelCol}
        wrapperCol={this.props.wrapperCol}
        name={this.props.name}
        getValueFromEvent={this.getValueFromEvent}
        rules={this.props.rules}
      >
        <FileDragger
          onRemove={this.onRemove}
          beforeUpload={this.beforeUpload}
          {...this.props}
          style={{}}
        >
          {this.props.children}
        </FileDragger>
      </ProForm.Item>
    );
  }
}

type ProFormDraggerProps = {
  max?: number;
  folder: string;
  title: string;
  rules?: Rule[];
  accept?: string;
  multiple?: boolean;
  name: string;
  tags?: Array<string>;
};

export type FileResponse = {
  name: string;
  size: number;
  mime: string;
  url: string;
  s3key: string;
  tags?: Array<string>;
};

export const ProFormDragger: FC<ProFormDraggerProps> = (props) => {
  const intl = useIntl();
  return (
    <ProFormUploadDragger
      max={props.max}
      rules={props.rules}
      name={props.name}
      label={props.title}
      description={
        props.max ? intl.formatMessage({ id: 'dragger.file.description.max' }) + props.max : ''
      }
      title={intl.formatMessage({ id: 'dragger.file.title' })}
      fieldProps={{
        multiple: props.multiple,
        accept: props.accept,
        name: props.name,
        maxCount: props.max,
        customRequest: async (options) => {
          if (!options.file || options.file.constructor.name !== 'File') new Error('no file');
          const file = options.file as File;
          const fh = await FileHandler.init();
          const date = Math.floor(Date.now() / 1000);
          const name = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
          const path =
            props.folder.lastIndexOf('/') === props.folder.length - 1
              ? `${props.folder}${date}-${name}`
              : `${props.folder}/${date}-${name}`;
          const url = fh.getUrl(path);
          const up = await fh.upload({
            Key: path,
            Body: file,
            ContentType: file.type,
            ACL: 'public-read',
          });

          if (up) {
            const newFile: FileResponse = {
              name: file.name,
              size: file.size,
              mime: file.type,
              url,
              s3key: path,
              tags: props.tags,
            };
            options.onProgress?.({ percent: 100 });
            options.onSuccess?.(newFile);
          }
        },
      }}
    />
  );
};
