import { ObjectCannedACL, S3ClientConfig } from '@aws-sdk/client-s3';
import { VBDocument } from '@/services/vertriebsbutler/file';
import { CompleteMultipartUploadInput } from '@/generated/graphql';

interface UploadFileResult {
  success: boolean;
  err?: unknown;
  data?: File;
}

export interface UploadFileParams {
  Key: string;
  Body: File;
  ContentType: string;
  ACL: ObjectCannedACL;
}

export default class FileHandler {
  private bucket: string;
  private cfg: S3ClientConfig;

  private constructor(bucket: string, config: S3ClientConfig) {
    this.bucket = bucket;
    this.cfg = config;
  }

  static async init(dest_bucket?: string) {
    const region = 'eu-west-1';
    const bucket = dest_bucket || window.localStorage.getItem('bucket');

    const cfg = {
      region,
    };
    return new FileHandler(bucket || '', cfg);
  }

  upload = async (data: UploadFileParams) => {
    const res = await VBDocument.signedUrlPost({
      key: data.Key,
      acl: data.ACL,
    });
    if (!res) throw new Error('No signed url');
    const formData = new FormData();
    Object.entries(res.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', data.Body);
    const response = await fetch(res.url, {
      method: 'POST',
      body: formData,
    });
    return response;
  };

  getUrl = (key: string) => {
    return `https://${this.bucket}.s3.${this.cfg.region}.amazonaws.com/${key}`;
  };

  download = async (filename: string, key: string): Promise<UploadFileResult> => {
    const res = await VBDocument.signedUrlGet({ key });
    if (!res.data) throw new Error('No signed url');
    const res2 = await (await fetch(res.data.file.url)).blob();
    const file = new File([res2], filename, { type: res.data.file.mime });
    return { success: true, data: file };
  };

  createMultipartUpload = async (key: string) => {
    return await VBDocument.createMultipartUpload({
      key: key,
      contentType: 'video/mp4',
      acl: 'public-read',
    });
  };

  uploadPart = async (
    key: string,
    part_number: number,
    upload_id: string,
    body: Blob,
  ): Promise<{ ETag: string; PartNumber: number }> => {
    const { data } = await VBDocument.uploadPart({
      key,
      upload_id,
      part_number,
    });
    if (!data) throw new Error('No signed url');
    const res = await fetch(data.part.url, {
      method: 'PUT',
      body,
      credentials: 'same-origin',
    });
    if (!res.ok) {
      throw new Error(`Failed to upload part: ${res.statusText}`);
    }

    const ETag = res.headers.get('etag');
    if (!ETag) {
      console.warn('No ETag header found, this might cause issues when completing the upload.');
    }

    return { ETag: ETag || '', PartNumber: part_number };
  };

  completeMultipartUpload = async (input: CompleteMultipartUploadInput) => {
    return await VBDocument.completeMultipartUpload(input);
  };

  abortMultipartUpload = async (key: string, uploadId: string) => {
    return await VBDocument.abortMultipartUpload({
      key,
      uploadId,
    });
  };
}
