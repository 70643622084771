import { InitialState, UserState } from './types/initialState';

export default (initialState: InitialState) => {
  if (!initialState) return {};
  const { user, disabled } = initialState;

  const userStates = [UserState.user, UserState.moderator, UserState.admin, UserState.su];
  return {
    disabledRoutes: () => disabled,
    publicRoutes: () => !disabled,
    noUserRoutes: () => user.role === UserState.unknown && !disabled,
    userRoutes: () => userStates.includes(user.role) && !disabled,
    adminRoutes: () => user.role === UserState.admin && !disabled,
    suRoutes: () => user.role === UserState.su,
  };
};
