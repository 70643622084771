import { createContext } from 'react';
import { io } from 'socket.io-client';
const initSocket = (data: { client_id: string; user_pool_id: string; user_id?: string }) => {
  const socket = io(BACKEND_URL, { transports: ['websocket'] });
  socket.connect();

  socket.on('connect', () => {
    socket.emit('init', data);
  });
  return socket;
};

export const socket = initSocket({ client_id: 'client_id', user_pool_id: 'user_pool_id' });

export const SocketContext = createContext(socket);
