console.warn = () => {};
console.error = () => {};
import { useEffect, useRef, useState } from 'react';
import { useModel, useParams } from '@umijs/max';
import { Spin, message } from 'antd';
import { FileViewer } from '@digital-a-team/platform-file-viewer';
import FileHandler from '@/utils/s3';
import { Base64 } from 'js-base64';
import 'video-react/dist/video-react.css';
import '../project/campaign/CountDown.css';
import { VBDocument } from '@/services/vertriebsbutler/file';
import { FileCreateInput, Tag_File } from '@/generated/graphql';
import './campaign/CountDown.css';

interface FileWithUrl extends File {
  url?: string;
}

const ProjectRecorder = () => {
  const params = useParams<{
    contact_id: string;
    project_id: string;
    files: string;
    folder?: string;
  }>();
  const [files, setFiles] = useState<Array<FileWithUrl>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { initialState } = useModel('@@initialState');
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}-${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
  const fileName = `Video-${formattedDate}`;
  const parts = useRef<Array<{ ETag: string; PartNumber: number }>>([]);
  const keyRef = useRef<string>('');
  const uploadIdRef = useRef<string>('');
  const [fh, setFh] = useState<FileHandler>();

  useEffect(() => {
    const init = async () => {
      setFh(await FileHandler.init());
    };
    init();
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      if (!params.files) {
        setLoading(false);
        return;
      }
      const fileKeys = JSON.parse(Base64.decode(params.files));
      const fh = await FileHandler.init();
      const filesArray = await Promise.all(
        fileKeys
          .filter(
            (file: { name: string; path: string }) =>
              file.path && file.name?.indexOf('webm') === -1,
          )
          .map(async (item: { name: string; path: string }) => {
            const file = await fh.download(item.name, item.path);
            return file ? file.data : null;
          }),
      );

      const validFiles = filesArray.filter((file: unknown) => file) as Array<File>;
      setFiles(validFiles);
      setLoading(false);
    };

    fetchFiles().catch((err) => {
      message.error('Fehler beim Laden der Dateien');
      setLoading(false);
    });
  }, [params.files]);

  const uploadFile = async (filename: string, s3Key: string, size: number) => {
    try {
      const fileItem: FileCreateInput = {
        project: {
          connect: {
            id: params.project_id,
          },
        },
        contact: {
          connect: {
            id: params.contact_id,
          },
        },

        folder: params.folder || '',
        name: filename,
        s3key: s3Key,
        size: size,
        mime: 'video/mp4',
        client: {
          connect: {
            client_id: initialState?.client?.client_id || '',
          },
        },
        tags: {
          set: [Tag_File.ProjectVideo],
        },
      };
      await VBDocument.createOne(fileItem);
    } catch (error) {
      message.error('Fehler beim Hochladen der Datei');
      return {
        url: '',
        name: '',
      };
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!files || !files.length) return <Spin />;

  const onClose = () => {
    history.back();
  };

  return (
    <FileViewer
      files={files}
      defaultFileName={fileName}
      onStartMultipartUpload={async () => {
        const folder = params.folder ? `${params.folder}/` : '';
        const filename = `${fileName}.webm`;
        const s3key = `contact/${params.contact_id}/project/${params.project_id}/${folder}${filename}`;
        const res = await fh?.createMultipartUpload(s3key);
        if (res) {
          keyRef.current = s3key;
          uploadIdRef.current = res || '';
        }
      }}
      onUploadPart={async (part: number, body: Blob) => {
        const res = await fh?.uploadPart(keyRef.current, part, uploadIdRef.current, body);
        if (!res) throw new Error('Upload failed');
        parts.current.push(res);
      }}
      onCompleteMultipartUpload={async (
        filename: string,
        _: boolean,
        size: number,
        duration: number,
        thumbnail: Blob,
      ) => {
        const { contact_id, project_id, folder } = params;
        if (!contact_id || !project_id || !folder) return false;
        const newFilename = fileName.replace('.webm', '_thumb.jpg');
        const thumbnailKey = `contact/${contact_id}/project/${project_id}/${folder}/${newFilename}`;
        await fh?.upload({
          Key: thumbnailKey,
          Body: new File([thumbnail], newFilename),
          ContentType: 'image/jpeg',
          ACL: 'public-read',
        });
        await fh?.completeMultipartUpload({
          key: keyRef.current,
          uploadId: uploadIdRef.current,
          parts: parts.current.sort((a, b) => a.PartNumber - b.PartNumber),
          size,
          duration,
          tags: [Tag_File.ProjectVideo],
          contact_id,
          filename,
          folder,
          project_id,
          thumbnailKey,
        });

        return Promise.resolve(true);
      }}
      onAbortMultipartUpload={async () => {
        parts.current = [];
        await fh?.abortMultipartUpload(keyRef.current, uploadIdRef.current);
      }}
      onClose={onClose}
    />
  );
};

export default ProjectRecorder;
