import { ParameterAgentListOutput, User_Role } from '@/generated/graphql';
import { CognitoService } from '@/utils/auth';
import { CSSProperties } from 'react';

export enum UserState {
  unknown = 'unknown',
  guest = User_Role.Guest,
  user = User_Role.User,
  moderator = User_Role.Moderator,
  admin = User_Role.Admin,
  su = 'su',
}

export type CurrentUser = {
  role: UserState;
  parameter: Array<ParameterAgentListOutput>;
  cognito?: CognitoService;
  firstname?: string;
  lastname?: string;
  email?: string;
  id?: string;
  isGlobalUser?: boolean;
};

export type Client = {
  client_id: string;
  bucket: string;
  domain: string;
  logo: string;
  styles: {
    antd: {
      token: { [key: string]: string };
      components: { [key: string]: string };
    };
    surveyjs: { [key: string]: string };
  };
  iso_3166_1?: string;
  identity_pool_id: string;
  user_pool_id: string;
};

export type InitialState = {
  user: CurrentUser;
  client: Client;
  style: CSSProperties;
  logos: {
    collapsed: string;
    unCollapsed: string;
  };
  collapsed: boolean;
  theme: 'light' | 'realDark';
  disabled: boolean;
};
