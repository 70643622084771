import { getCognitoDataFromStorage } from '@/utils/auth';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client/core';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

if (process.env.REACT_APP_ENV === 'local-dev') {
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = createHttpLink({
  uri: `${BACKEND_URL}graphql`,
});

const createAuthLink = async () => {
  const cognito = await getCognitoDataFromStorage();
  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      token: cognito?.session?.getAccessToken().getJwtToken(),
      'client-id': cognito?.client_id,
      'user-pool-id': cognito?.user_pool_id,
      'auth-user-pool-id': cognito?.auth_user_pool_id,
      'auth-client-id': cognito?.auth_client_id,
      'user-id': cognito?.user_id,
    },
  }));
};

const setupApolloClient = async () => {
  const authLink = await createAuthLink();
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export const query = async <T>(
  data: QueryOptions<OperationVariables, T>,
): Promise<ApolloQueryResult<T>> => {
  try {
    const client = await setupApolloClient();
    return await client.query<T>(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const mutate = async <T>(
  data: MutationOptions<T, OperationVariables>,
): Promise<FetchResult<T>> => {
  try {
    const client = await setupApolloClient();
    return await client.mutate<T>(data);
  } catch (error) {
    console.error('An error occured');
    return Promise.reject(error);
  }
};
