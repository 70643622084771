import { gql } from '@apollo/client';
import { mutate, query } from '.';
import { VBBaseClass } from './generic';
import {
  AbortMultipartUploadInput,
  CompleteMultipartUploadInput,
  GetFileOutput,
  GetFilePartInput,
} from '../../generated/graphql';
import {
  File,
  FileCreateInput,
  GetFileInput,
  PostFileInput,
  PutFileInput,
  StartMultipartUploadInput,
} from '@/generated/graphql';

export type ListDocumentFilter = { project_id?: string; folder?: string; campaign_id?: string };

const DEFAULT_FIELDS = `
  created
  mime
  name
  s3key
  size
  tags
  url
  folder
  thumbnail_s3key
`;

export class VBDocument {
  private static baseClass = new VBBaseClass<File>('File', DEFAULT_FIELDS);

  static createOne(data: FileCreateInput) {
    const qry = gql`
      mutation Mutation($data: FileCreateInput!) {
        File: createOneFile(data: $data) {
          created
          mime
          name
          s3key
          size
          tags
          url
          folder
          __typename
        }
      }
    `;
    return mutate<{ File: File }>({
      mutation: qry,
      variables: {
        data,
      },
    });
  }

  static updateOne(data: File) {
    return this.baseClass.updateOne(data);
  }

  static deleteOne(id: string) {
    return this.baseClass.deleteOne(id);
  }

  static selectOne(id: string) {
    return this.baseClass.selectOne(id);
  }

  static selectMany(filter: ListDocumentFilter) {
    const QUERY_LIST_DOCUMENTS = gql`
      query Files(
        $folderWhere: FileWhereInput
        $filesWhere: FileWhereInput
        $orderBy: [FileOrderByWithRelationInput!]
        $distinct: [FileScalarFieldEnum!]
      ) {
        folder: files(where: $folderWhere, orderBy: $orderBy, distinct: $distinct) {
          created
          mime
          name
          s3key
          size
          tags
          url
          folder
          thumbnail_s3key
          campaign_id
        }
        files: files(where: $filesWhere, orderBy: $orderBy) {
          created
          mime
          name
          s3key
          size
          tags
          url
          folder
          thumbnail_s3key
          campaign_id
        }
      }
    `;

    if (!filter.project_id) new Error('project_id is required');
    const filterProjectId = filter.project_id
      ? {
          project_id: {
            equals: filter.project_id,
          },
        }
      : {};
    const filterFolderNameA = filter.folder
      ? {
          folder: {
            startsWith: filter.folder,
          },
        }
      : {
          folder: {
            not: null,
          },
        };
    const filterFolderNameB = filter.folder
      ? { folder: { equals: filter.folder } }
      : {
          folder: {
            equals: null,
          },
        };

    return query<{
      folder: Array<File>;
      files: Array<File>;
    }>({
      query: QUERY_LIST_DOCUMENTS,
      variables: {
        folderWhere: {
          deleted: {
            equals: null,
          },
          AND: [filterProjectId, filterFolderNameA],
        },
        filesWhere: {
          deleted: {
            equals: null,
          },

          AND: [filterProjectId, filterFolderNameB],
        },
        distinct: 'folder',
        orderBy: [
          {
            name: {
              sort: 'asc',
            },
          },
        ],
      },
    });
  }

  static signedUrlGet(data: GetFileInput) {
    const query = gql`
      mutation SignedUrlFileGet($input: GetFileInput!) {
        file: signedUrlFileGet(input: $input) {
          url
          mime
        }
      }
    `;
    return mutate<{ file: { url: string; mime: string } }>({
      mutation: query,
      variables: {
        input: data,
      },
    });
  }

  static signedUrlPut(data: PutFileInput) {
    const query = gql`
      mutation SignedUrlFilePut($input: PutFileInput!) {
        url: signedUrlFilePut(input: $input)
      }
    `;
    return mutate<{ url: string }>({
      mutation: query,
      variables: {
        input: data,
      },
    });
  }

  static async signedUrlPost(data: PostFileInput) {
    const query = gql`
      mutation SignedUrlFilePut($input: PostFileInput!) {
        post: signedUrlFilePost(input: $input) {
          fields
          url
        }
      }
    `;
    const res = await mutate<{ post: { url: string; fields: Record<string, string> } }>({
      mutation: query,
      variables: {
        input: data,
      },
    });
    return res.data?.post;
  }

  static async selectFilesInFolder(folder: string) {
    const qry = gql`
      query Files($where: FileWhereInput) {
        files(where: $where) {
          url
          s3key
          id
          name
          folder
        }
      }
    `;
    const res = await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          folder: {
            startsWith: folder,
          },
          AND: [
            {
              deleted: {
                equals: null,
              },
            },
          ],
        },
      },
    });
    return res.data?.files;
  }
  static async getOfferFiles(campaign_id: string) {
    try {
      const qry = gql`
        query Files($where: FileWhereInput) {
          files(where: $where) {
            url
            s3key
            id
            name
            folder
            tags
          }
        }
      `;
      const result = await query<{ files: File[] }>({
        query: qry,
        variables: {
          where: {
            campaign_id: { equals: campaign_id },
            AND: [
              {
                deleted: {
                  equals: null,
                },
                tags: {
                  hasSome: ['offer', 'offer_file', 'offer_video'],
                },
              },
            ],
          },
        },
      });
      return result.data?.files;
    } catch (error) {
      console.error('Error fetching files by campaign ID:', error);
      throw error;
    }
  }

  static async trackDownload(fileId: string) {
    try {
      await mutate({
        mutation: gql`
          mutation TrackDownload($fileId: String!) {
            trackDownload(fileId: $fileId) {
              id
            }
          }
        `,
        variables: { fileId },
      });
      console.log(`Download tracked for fileId: ${fileId}`);
    } catch (error) {
      console.error('Error tracking download:', error);
      throw error;
    }
  }

  static async selectInquiry(contact_id: string) {
    const qry = gql`
      query Files($where: FileWhereInput, $orderBy: [FileOrderByWithRelationInput!]) {
        files(where: $where, orderBy: $orderBy) {
          url
          s3key
          id
          name
          tags
          mime
          sub_files {
            url
            id
            s3key
            name
            tags
            mime
          }
        }
      }
    `;
    return await query<{ files: File[] }>({
      query: qry,
      variables: {
        where: {
          AND: [
            { contact_id: { equals: contact_id } },
            { project_id: { equals: null } },
            { main_file_id: null },
            {
              deleted: {
                equals: null,
              },
              tags: {
                hasSome: ['inquiry'],
              },
            },
          ],
        },
        orderBy: [
          {
            created: 'desc',
          },
        ],
      },
    });
  }

  static async linkToProject(file_id: string, project_id: string) {
    const qry = gql`
      mutation LinkFilesToProject($input: LinkFilesToProjectInput!) {
        linkFilesToProject(input: $input)
      }
    `;
    return await mutate<Boolean>({
      mutation: qry,
      variables: {
        input: {
          project_id,
          file_id,
        },
      },
    });
  }

  static async deleteInquiry(id: string) {
    const qry = gql`
      mutation UpdateManyFile($data: FileUpdateManyMutationInput!, $where: FileWhereInput) {
        updateManyFile(data: $data, where: $where) {
          count
        }
      }
    `;
    return await mutate<Boolean>({
      mutation: qry,
      variables: {
        data: {
          deleted: {
            set: new Date().toISOString(),
          },
        },
        where: {
          OR: [{ id: { equals: id } }, { main_file_id: { equals: id } }],
        },
      },
    });
  }

  static async createMultipartUpload(input: StartMultipartUploadInput) {
    const query = gql`
      mutation CreateMultipartUpload($input: StartMultipartUploadInput!) {
        createMultipartUpload(input: $input) {
          uploadId
        }
      }
    `;
    const res = await mutate<{ createMultipartUpload: { uploadId: string } }>({
      mutation: query,
      variables: {
        input,
      },
    });
    return res.data?.createMultipartUpload.uploadId;
  }

  static async uploadPart(input: GetFilePartInput) {
    const query = gql`
      mutation SignedUrlFilePartGet($input: GetFilePartInput!) {
        part: signedUrlFilePartGet(input: $input) {
          mime
          url
        }
      }
    `;
    return await mutate<{ part: GetFileOutput }>({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async completeMultipartUpload(input: CompleteMultipartUploadInput) {
    const query = gql`
      mutation CompleteMultipartUpload($input: CompleteMultipartUploadInput!) {
        completeMultipartUpload(input: $input)
      }
    `;
    await mutate({
      mutation: query,
      variables: {
        input,
      },
    });
  }

  static async abortMultipartUpload(input: AbortMultipartUploadInput) {
    const query = gql`
      mutation AbortMultipartUpload($input: AbortMultipartUploadInput!) {
        abortMultipartUpload(input: $input)
      }
    `;
    await mutate({
      mutation: query,
      variables: {
        input,
      },
    });
  }
}
